import * as React from 'react';
import { l10n } from '../../../l10n';
import { useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import IvyIcon from '../IvyIcon';
import CompanyContext from '../../../context/CompanyContext';

const AdminItem: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();
  const company = React.useContext(CompanyContext);

  return (
    <div className={'e2e-go-to-admin-page'}>
      <SidebarItem
        Icon={IvyIcon as any}
        title={l10n.naviAdmin + ' ' + (company?.name || '')}
        route="/admin"
        selected={path.startsWith('/admin')}
      />
    </div>
  );
};

export default AdminItem;
