import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import SiteHeader from '../../components/SiteHeader';
import CompanyContext from '../../context/CompanyContext';
import TenantsContext, { TenantEntry, TenantFilters } from '../../context/TenantsContext';
import { getLanguagePreferenceName } from '../../util/util';
import { visuallyHidden } from '@mui/utils';
import ParksContext from '../../context/ParksContext';
import PaginatedTableLoading from '../../components/PaginatedTableLoading';
import { TenantsTableRow } from './TenantsTableRow';
import { useForm } from 'react-hook-form';
import ReactHookFormInput, { ReactHookFormInputType } from '../../components/form/ReactHookFormInput';
import { colors } from '../../theming/colors';

const headCells: { id: keyof TenantEntry; label: string }[] = [
  {
    id: 'name',
    label: 'Name',
  }, {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'rentals',
    label: 'Rentals',
  },
  {
    id: 'parks',
    label: 'Parks',
  },
  {
    id: 'units',
    label: 'Units',
  },
  {
    id: 'language',
    label: 'Language',
  },
  {
    id: 'created',
    label: 'Created',
  },
  {
    id: 'updated',
    label: 'Updated',
  },
  
];

const Tenants: React.FC = () => {
  const navigate = useNavigate();
  const {
    tenants,
    loading,
    page,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    tenantCount,
    order,
    orderBy,
    handleOrderChange,
    filters,
    handleFilterChange,
  } = React.useContext(TenantsContext);
  const { parks } = React.useContext(ParksContext);
  const company = React.useContext(CompanyContext);
  const features = company?.flags;
  const [parsedTenants, setParsedTenants] = React.useState<TenantEntry[]>([]);

  const parseTenantRows = React.useCallback(() => {
    if (tenants) {
      const parsedTenants = tenants.map(n => {
        return {
          id: n.id,
          email: n.email,
          name: `${n.firstName} ${n.lastName}`,
          rentals: n.rentalCount,
          parks: n.parkIds?.map(parkId => parks?.find(p => p.id === parkId)?.name ?? '') ?? [],
          units: n.storageUnitKeyCount,
          language: getLanguagePreferenceName(n.languagePreference),
          created: n.createdAt ? new Date(n.createdAt).getTime() : '',
          updated: n.updatedAt ? new Date(n.updatedAt).getTime() : '',
          trust: 'HIGH',
        };
      });
      setParsedTenants(parsedTenants);
    }
    return [];
  }, [tenants, parks]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: filters.firstName ?? '',
      lastName: filters.lastName ?? '',
      parkId: filters.parkId ?? '',
      email: filters.email ?? '',
    },
  });

  const onSubmit = React.useCallback(
    async (values: TenantFilters) => {
      handleFilterChange(values);
    },
    [handleFilterChange],
  );

  const clearFilters = React.useCallback(() => {
    handleFilterChange({
      firstName: '',
      lastName: '',
      parkId: '',
      email: '',
    });
    control._reset();
  }, [control, handleFilterChange]);

  const onSortChange = React.useCallback(
    (property: keyof TenantEntry) => {
      const isAsc = orderBy === property && order === 'asc';
      handleOrderChange(property, isAsc ? 'desc' : 'asc');
    },
    [handleOrderChange, order, orderBy],
  );

  React.useEffect(() => {
    parseTenantRows();
  }, [parseTenantRows, company?.flags?.betaFeatures]);

  return (
    <>
      <SiteHeader title="Tenants">
        <Button variant="outlined" size="medium" onClick={() => navigate('/tenants/add')}>
          <span className="e2e-add-tenant">Add Tenant</span>
        </Button>
      </SiteHeader>
      <Box sx={{ paddingBottom: '60px' }}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            background: '#FFF',
            padding: 2,
            borderBottom: `1px solid ${colors.grey05}`,
            zIndex: 799,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex' }}>
            <Box sx={{ width: 200, marginRight: 2 }}>
              <ReactHookFormInput
                control={control}
                name="firstName"
                label="First Name"
                type={ReactHookFormInputType.text}
              />
            </Box>
            <Box sx={{ width: 200, marginRight: 2 }}>
              <ReactHookFormInput
                control={control}
                name="lastName"
                label="Last Name"
                type={ReactHookFormInputType.text}
              />
            </Box>
            <Box sx={{ width: 300, marginRight: 2 }}>
              <ReactHookFormInput
                control={control}
                name="parkId"
                label="Park"
                type={ReactHookFormInputType.select}
                options={[
                  { name: 'All', value: '' },
                  ...(parks?.map(park => ({ name: park.name, value: park.id })) ?? []),
                ]}
              />
            </Box>
            <Box sx={{ width: 200, marginRight: 2 }}>
              <ReactHookFormInput
                control={control}
                name="email"
                label="Email"
                type={ReactHookFormInputType.text}
              />
            </Box>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="outlined"
              sx={{ height: '34px', alignSelf: 'flex-end', marginRight: 2 }}
            >
              Apply Filters
            </Button>
            <Button
              onClick={clearFilters}
              variant="outlined"
              color="secondary"
              sx={{ height: '34px', alignSelf: 'flex-end' }}
            >
              Clear Filters
            </Button>
          </form>
        </Box>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headCells.map(headCell => {
                if (headCell.id === 'trust' && !features?.betaFeatures) return null;
                return (
                  <TableCell key={headCell.id}>
                    {headCell.id === 'rentals' || headCell.id === 'units' || headCell.id === 'parks' ? (
                      <>{headCell.label}</>
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => onSortChange(headCell.id)}
                      >
                        {headCell.label}

                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                );
              })}
              {company?.flags?.betaFeatures ? <TableCell>Trust</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants && tenants.length ? (
              parsedTenants.map(v => <TenantsTableRow key={v.id} tenant={v} company={company} loadingParks={!parks} />)
            ) : (
              <PaginatedTableLoading isLoading={loading} />
            )}
          </TableBody>
        </Table>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            background: '#FFF',
            width: 'calc(100% - 240px)',
            borderTop: `1px solid ${colors.grey05}`,
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={tenantCount!}
            rowsPerPage={itemsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeItemsPerPage}
            showFirstButton={true}
            showLastButton={true}
            sx={{
              float: 'right',
              paddingRight: '32px!important',
            }}
          />
        </Box>
      </Box>
      <Outlet />
    </>
  );
};

export default Tenants;
