import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import ReactHookFormRadioGroup from './ReactHookFormRadioGroup';
import ReactHookFormTextField from './ReactHookFormTextField';
import ReactHookFormCheckboxField from './ReactHookFormCheckboxField';
import { Autocomplete, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
export const reEmail = new RegExp(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
);

export enum ReactHookFormInputType {
  number = 'number',
  radio = 'radio',
  select = 'select',
  text = 'text',
  checkbox = 'checkbox',
  email = 'email',
  autocomplete = 'autocomplete',
  date = 'date',
}

export interface ReactHookFormInputOptions {
  name: string;
  value: string;
}

export type ReactHookFormInputRules = {
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp | { value: RegExp; message: string };
  validate?: (value: any) => boolean | string;
};

export interface ReactHookFormInputProps {
  control: any;
  name: string;
  label?: string;
  options?: ReactHookFormInputOptions[];
  rules?: ReactHookFormInputRules;
  disabled?: boolean;
  showDefault?: boolean;
  type: ReactHookFormInputType;
  renderOption?: (props: any, option: ReactHookFormInputOptions) => JSX.Element;
}

const ReactHookFormInput: FC<ReactHookFormInputProps> = ({
  control,
  name,
  label,
  rules,
  disabled,
  type,
  options,
  renderOption,
  showDefault,
}: ReactHookFormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        switch (type) {
          case ReactHookFormInputType.radio:
            return options && <ReactHookFormRadioGroup field={field} options={options} />;
          case ReactHookFormInputType.checkbox:
            return <ReactHookFormCheckboxField field={field} label={label} />;
          case ReactHookFormInputType.autocomplete:
            return (
              <Autocomplete
                disablePortal
                options={options ?? []}
                renderOption={renderOption}
                renderInput={params => <TextField {...params} label={label} required={rules?.required} />}
                onChange={(event, value) => {
                  if (value) field.onChange(value.value);
                }}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            );
          case ReactHookFormInputType.date:
            const value = field.value ? dayjs(field.value) : null;
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={label}
                  format="DD/MM/YYYY"
                  value={value}
                  onChange={value => {
                    if (value) field.onChange(value);
                  }}
                  inputRef={field.ref}
                  timezone="UTC"
                  slotProps={{
                    textField: {
                      required: rules?.required,
                    },
                  }}
                />
              </LocalizationProvider>
            );
          case ReactHookFormInputType.number:
          case ReactHookFormInputType.select:
          case ReactHookFormInputType.text:
          case ReactHookFormInputType.email:
          default:
            return (
              <ReactHookFormTextField
                field={field}
                label={label}
                options={options}
                rules={rules}
                showDefault={showDefault}
                type={type}
                disabled={disabled}
              />
            );
        }
      }}
    />
  );
};

export default ReactHookFormInput;
