import { Box, Grid, InputLabel, Typography } from '@mui/material';
import { Check } from 'phosphor-react';
import * as React from 'react';
import { colors } from '../../theming/colors';
import { Row } from '../../components/Layout';
import { StorageUnit, currencyString } from '../../model';
import UnitEventsSection from './Events/UnitEventsSection';

interface Props {
  parkId: string;
  storageUnit: StorageUnit;
}

const UnitInfoSection: React.FC<Props> = ({ parkId, storageUnit }) => {
  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={2}>
        {/* UNIT */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Unit
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>Status</InputLabel>
              <Row>
                <Check color={colors.signalGreen} />
                <Typography sx={{ ml: 1 }}>OK</Typography>
              </Row>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Description</InputLabel>
              <Typography>{storageUnit.description || '-'}</Typography>
            </Grid>
            {storageUnit.rentalRate && (
              <Grid item xs={6}>
                <InputLabel>Current Rental Rate</InputLabel>
                <Typography>{currencyString(storageUnit.rentalRate)}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <UnitEventsSection {...{ storageUnitId: storageUnit.id, parkId }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnitInfoSection;
