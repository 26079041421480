import { TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import LoadingIcon from './LoadingIcon';

type PaginatedTableLoadingProps = {
  isLoading: boolean;
}
const PaginatedTableLoading: React.FC<PaginatedTableLoadingProps> = ({
  isLoading
}: PaginatedTableLoadingProps) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={100} sx={{ height: 'calc(100vh - 300px)' }}>
        {
          isLoading ? <LoadingIcon /> : 'No results'
        }
      </TableCell>
    </TableRow>
  );
};

export default PaginatedTableLoading;
