import { axiosDelete } from '../requestUtils';
import { getAuthToken } from '../../auth/auth';

export const deleteStorageUnit = async (storageUnitId: string) => {
  const token = await getAuthToken();
  if (token) {
    const response = await axiosDelete('/units/' + storageUnitId, token);
    return response;
  }
  return;
};
