import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Value = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
`;

export default Value;
