import { Address, LanguagePreference, Park, Rental } from '../model';
import { components } from '../../gen/backoffice-service';

type ParkListPark = components['schemas']['ParkListPark'];

export const isRentalActive = (rental: Rental): boolean => {
  return rental.tenantId != null;
};

export const getUniqueActiveRentalCount = (park: Park | ParkListPark): number => {
  return new Set(park.rentals.filter(rental => rental.tenantId != null && rental.storageUnitId && rental.isActive))
    .size;
};

export const parseISOString = (s: string) => {
  const b = s.split(/\D+/);
  return new Date(Date.UTC(+b[0], +b[1], +b[2], +b[3], +b[4], +b[5], +b[6]));
};

export const formatDate = (date: Date): string => {
  if (!date) return '';
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const dateFromString = (value: string): Date | null => {
  const parts = value.split('-');
  if (value.length !== 10 || parts.length !== 3) {
    return null;
  }
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]);
  const day = parseInt(parts[2]);

  return new Date(year, month - 1, day, 0, 0, 0, 0);
};

//copied from https://gist.github.com/cloudmaker97/e5e8d79ad62816d817e04ec1808d215e
export const weekNumber = (date: Date): number => {
  const currentThursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);
  const yearOfThursday = currentThursday.getFullYear();
  const firstThursday = new Date(
    new Date(yearOfThursday, 0, 4).getTime() + (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000,
  );
  const weekNumber = Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7);
  return weekNumber;
};

export const addressString = (address: Address): string => {
  return `${address.line1} ${address.countryCode.toUpperCase()}-${address.postalCode} ${address.city}`;
};

export function getLanguagePreferenceName(l: LanguagePreference): string {
  switch (l) {
    case 'LANGUAGE_PREFERENCE_DE':
      return 'German';
    case 'LANGUAGE_PREFERENCE_FR':
      return 'French';
    default:
      return 'Unknown';
  }
}

export function isValidEmail(v: string): boolean {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/.test(v);
}

export type Order = 'asc' | 'desc';

export const sortByEntry = <T>(array: T[], entry: keyof T, direction: Order = 'asc'): T[] => {
  return array.sort((a, b) => {
    if (a[entry] < b[entry]) {
      return direction === 'asc' ? -1 : 1;
    }
    if (a[entry] > b[entry]) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const nameEmailDivider = ' → ';
