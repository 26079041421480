import { FormControlLabel, Checkbox } from '@mui/material';
import React, { FC } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

type ReactHookFormCheckboxFieldProps = {
  field: ControllerRenderProps<FieldValues, string>;
  label?: string;
};

const ReactHookFormCheckboxField: FC<ReactHookFormCheckboxFieldProps> = ({ field, label }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={field.value} />}
      label={label}
      value={field.value}
      onChange={e => {
        return field.onChange(e);
      }}
    />
  );
};

export default ReactHookFormCheckboxField;
