import { AxiosError } from 'axios';

import { useEffect, useState } from 'react';
import { components } from '../../gen/backoffice-service';
import { TenantFilters } from '../context/TenantsContext';
import { Tenant } from '../model';
import { get, post, put } from './requestUtils';
import { withBearerAuthorization } from './withBearerAuthorization';
import { getAuthToken } from '../auth/auth';

export type TenantListPayload = components['schemas']['TenantListPayload'];
export type TenantListPayloadTenant = components['schemas']['TenantPayload'];
type TenantPayload = components['schemas']['TenantPayload'];
type PostTenantBody = components['schemas']['PostTenantBody'];
type PutTenantBody = components['schemas']['PutTenantBody'];
export type TenantListItem = components['schemas']['TenantListItem'];

export const useTenants = (): TenantListItem[] | undefined => {
  const [data, setData] = useState<TenantListItem[] | undefined>(undefined);
  useEffect(() => {
    // TODO HANDLE THIS PART - getTenants on park page
    getTenants({}).then(data => {
      setData(data.customers);
    });
  }, []);
  return data;
};

export const getTenants = async (request: {
  page?: number,
  itemsPerPage?: number,
  sortBy?: string,
  sortDirection?: string,
  filters?: TenantFilters,
  includeRentalInfo?: boolean,
}): Promise<TenantListPayload> => {
  const { page, itemsPerPage, sortBy, sortDirection, filters, includeRentalInfo } = request;
  const token = await getAuthToken();
  let query =
    page !== undefined && itemsPerPage !== undefined
      ? `?page=${page}&itemsPerPage=${itemsPerPage}&sortBy=${sortBy ?? ''}&sortDirection=${sortDirection ?? ''}${includeRentalInfo ? '&includeRentalInfo=true' : ''}`
      : includeRentalInfo ? '?includeRentalInfo=true' : '';
  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      query += `&${key}=${value}`;
    }
  }
  const response = await get(`/tenants${query}`, {
    ...withBearerAuthorization(token),
  });
  return response.data;
};

export const useTenant = (id: string | undefined): { data: Tenant | undefined; isLoading: boolean; error?: string } => {
  const [data, setData] = useState<Tenant | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    getTenant(id)
      .then(data => {
        setData(data);
      })
      .catch((e: AxiosError) => {
        setError(e.message);
      })
      .finally(() => setIsLoading(false));
  }, [id]);
  return { data, isLoading, error };
};

export const getTenant = async (id: string): Promise<TenantPayload> => {
  const token = await getAuthToken();
  const response = await get(`/tenant/${id}`, token);
  return response.data;
};

export const putTenant = async (tenant: PutTenantBody & { id: string }): Promise<Tenant> => {
  const { id, firstName, lastName, email, languagePreference } = tenant;
  const token = await getAuthToken();
  const response = await put(
    `/tenant/${id}`,
    {
      firstName,
      lastName,
      email,
      languagePreference,
    },
    token,
  );
  return response.data;
};

export const createTenant = async (body: PostTenantBody): Promise<string> => {
  const token = await getAuthToken();
  const response = await post(`/company/tenant`, body, token);
  return response.data.id;
};

type TenantListRentalPayloadRental = components['schemas']['TenantListRentalPayloadRental'];

export const useTenantRentals = (tenantId: string) => {
  const [data, setData] = useState<TenantListRentalPayloadRental[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    getTenantRentals(tenantId)
      .then(rentals => {
        if (!mounted) return;
        setData(rentals);
      })
      .finally(() => setIsLoading(false));
    return () => {
      mounted = false;
    };
  }, [tenantId]);
  return { data: data, isLoading: isLoading };
};

export const getTenantRentals = async (tenantId: string): Promise<TenantListRentalPayloadRental[]> => {
  const token = await getAuthToken();
  const response = await get(`/tenant/${tenantId}/rentals`, token);
  return response.data.rentals;
};
