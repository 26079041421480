import { Box, Typography } from '@mui/material';
import { Check, MapPin, User, Barricade } from 'phosphor-react';
import * as React from 'react';
import { colors } from '../../../theming/colors';
import { Row, Wrap } from '../../../components/Layout';
import { Park } from '../../../model';
import { addressString, getUniqueActiveRentalCount } from '../../../util/util';

interface Props {
  park: Park;
}

const ParkDetailSubHeader: React.FC<Props> = ({ park }) => {
  const address = park.address;
  if (address === undefined) {
    // FIXME
    console.log('wtf, how can this be undefined?', { address });
    return null;
  }
  return (
    <Wrap sx={{ pb: 1, px: 4, background: colors.grey02 }}>
      {/* ADDRESS */}
      <Row>
        <MapPin size={20} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          {addressString(address)}
        </Typography>
      </Row>
      <Box m={2} />

      {park.underConstruction && (
        <>
          <Row>
            <Barricade size={20} color={colors.signalWarning} />
            <Typography variant="body2" sx={{ ml: 1 }}>
              under construction
            </Typography>
          </Row>
          <Box m={2} />
        </>
      )}

      {/* RENTED */}
      <Row>
        <Typography sx={{ color: colors.grey05, mr: 1 }}>Rented:</Typography>
        <Typography>{`${getUniqueActiveRentalCount(park)}/${park.storageUnits.length}`}</Typography>
      </Row>
      <Box m={2} />

      {/* STATUS */}
      <Row>
        <Typography sx={{ color: colors.grey05, mr: 1 }}>Status:</Typography>
        <Check size={20} color={colors.signalGreen} />
        <Typography sx={{ ml: 1 }}>OK</Typography>
      </Row>
      <Box m={2} />

      {/* MANAGER */}
      <Row>
        <Typography
          variant="body2"
          sx={{
            mr: 1,
            color: colors.grey05,
          }}
        >
          Facility Manager:
        </Typography>
        <User size={20} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          {park.manager?.name}
        </Typography>
      </Row>
    </Wrap>
  );
};

export default ParkDetailSubHeader;
