import { InputLabel, Stack } from '@mui/material';
import { Flame, Lightning, LinkSimple, LinkSimpleBreak, LockOpen, Scroll } from 'phosphor-react';
import * as React from 'react';
import { StorageUnitEvent } from '../../../gateway/eventsGateway';

interface IEventRowProps {
  event: StorageUnitEvent;
}

const EventRow: React.FunctionComponent<IEventRowProps> = ({ event }) => {
  const timestamp = new Date(Date.parse(event.timestamp));
  const date = timestamp.toLocaleString('de-DE', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
  const time = timestamp.toLocaleString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const eventTypeHandler = () => {
    if (event.action === 'USER_EVENT_ACTION_ASSIGN_DEVICE') {
      return (
        <>
          <LinkSimple size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono' }}>System - Device Assigned</InputLabel>
        </>
      );
    } else if (event.action === 'USER_EVENT_ACTION_UNASSIGN_DEVICE') {
      return (
        <>
          <LinkSimpleBreak size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono' }}>System - Device Unassigned</InputLabel>
        </>
      );
    } else if (event.action === 'USER_EVENT_ACTION_FIRE_ALERT') {
      return (
        <>
          <Flame size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono' }}>System - Possible Fire Detected</InputLabel>
        </>
      );
    } else if (event.action.includes('USER_EVENT_ACTION_DEVICE_ERROR')) {
      return (
        <>
          <Lightning size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono', textTransform: 'capitalize' }}>
            System - Smart Meter {event.action.replace('USER_EVENT_ACTION_DEVICE_ERROR_', '').toLowerCase()}
          </InputLabel>
        </>
      );
    } else if (event.action.includes('USER_EVENT_ACTION_RENTAL_CREATED')) {
      return (
        <>
          <Scroll size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono' }}>
            {event.userEmail} - Rental Created
          </InputLabel>
        </>
      );
    } else if (event.action.includes('USER_EVENT_ACTION_RENTAL_ENDED')) {
      return (
        <>
          <Scroll size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono' }}>
            {event.userEmail} - Rental Ended
          </InputLabel>
        </>
      );
    } else if (event.action.includes('USER_EVENT_ACTION_RENTAL_MODIFIED')) {
      return (
        <>
          <Scroll size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono' }}>
            {event.userEmail} - Rental Modified
          </InputLabel>
        </>
      );
    } else if (event.action.includes('USER_EVENT_ACTION_UNLOCK')) {
      return (
        <>
          <LockOpen size={16} fontWeight="bold" />
          <InputLabel sx={{ ml: 1, color: 'black', fontFamily: 'DM Mono' }}>by {event.userEmail}</InputLabel>
        </>
      );
    } else {
      // Ingore unknown events
      return undefined;
    }
  };

  const eventInfo = eventTypeHandler();
  if (eventInfo === undefined) {
    return <></>;
  }
  return (
    <Stack direction="row" sx={{ mt: 0.5, mb: 0.5 }}>
      <InputLabel sx={{ mr: 1, color: 'black', fontFamily: 'DM Mono' }}>{`${date} - ${time}`}</InputLabel>
      {eventInfo}
    </Stack>
  );
};

export default EventRow;
