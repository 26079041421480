import styled from '@emotion/styled';

export const ValueContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 52px;
  display: flex;
  align-items: flex-end;
`;
export default ValueContainer;
