import { Button, InputLabel, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEvents } from '../../../gateway/eventsGateway';
import EventRow from './EventRow';

interface IUnitEventsSectionProps {
  parkId: string;
  storageUnitId: string;
}

const UnitEventsSection: React.FunctionComponent<IUnitEventsSectionProps> = ({ parkId, storageUnitId }) => {
  const events = useEvents(storageUnitId);
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Event History
      </Typography>
      <InputLabel sx={{ mb: 0.5 }}>Last 5 Events</InputLabel>
      {events?.slice(0, 5).map((event, index) => (
        <EventRow key={index} {...{ event }} />
      ))}
      <Stack direction="row-reverse">
        <Button
          onClick={() => navigate(`/park/${parkId}/unit/${storageUnitId}/events`)}
          variant="outlined"
          size="small"
          sx={{ mt: 1 }}
        >
          View all
        </Button>
      </Stack>
    </>
  );
};

export default UnitEventsSection;
