import { useEffect, useState } from 'react';
import { components } from '../../../gen/backoffice-service';
import { StorageUnit } from '../../model';
import { get } from '../requestUtils';
import { getAuthToken } from '../../auth/auth';

type CompanyStorageUnitListPayload = components['schemas']['StorageUnitListPayload'];
export type CompanyStorageUnitListPayloadStorageUnit = components['schemas']['StorageUnitListPayloadStorageUnit'];
type GetStorageUnitPayload = components['schemas']['GetStorageUnitPayload'];

export const useStorageUnits = (): CompanyStorageUnitListPayloadStorageUnit[] | undefined => {
  const [data, setData] = useState<CompanyStorageUnitListPayloadStorageUnit[] | undefined>(undefined);
  useEffect(() => {
    setData(undefined);
    getStorageUnits().then(data => {
      setData(data.storageUnits);
    });
  }, []);
  return data;
};

export const getStorageUnits = async (): Promise<CompanyStorageUnitListPayload> => {
  const token = await getAuthToken();
  const response = await get(`/units`, token);
  return response.data;
};

export const useStorageUnit = (storageUnitId: string): StorageUnit | undefined => {
  const [data, setData] = useState<StorageUnit | undefined>(undefined);
  useEffect(() => {
    setData(undefined);
    getStorageUnit(storageUnitId).then(data => {
      setData({
        ...data,
        description: data.description ?? '',
        thingsboardId: data.thingsboardId!,
      });
    });
  }, [storageUnitId]);
  return data;
};

export const getStorageUnit = async (storageUnitId: string): Promise<GetStorageUnitPayload> => {
  const token = await getAuthToken();
  const response = await get(`/units/${storageUnitId}`, token);
  return response.data;
};
