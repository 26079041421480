import { useEffect, useState } from 'react';
import { components } from '../../gen/backoffice-service';
import { get, post, put } from './requestUtils';
import { getAuthToken } from '../auth/auth';

export type Rental = components['schemas']['TenantListRentalPayloadRental'];
export type CreateRentalBody = components['schemas']['CreateRentalBody'];
export type PutRentalBody = components['schemas']['PutRentalBody'];
type EntityIdPayload = components['schemas']['EntityIdPayload'];

export const createRental = async (rental: CreateRentalBody): Promise<EntityIdPayload> => {
  const token = await getAuthToken();

  const response = await post(`/units/${rental.storageUnitId}/rentals`, rental, token);
  if (response.status !== 200) {
    console.error(response);
  }
  return response.data;
};

export const getRental = async (storageUnitId: string, id: string): Promise<Rental | undefined> => {
  const token = await getAuthToken();
  if (token) {
    try {
      const response = await get(`/units/${storageUnitId}/rentals/${id}`, token);
      return response.data;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

export const useRental = (
  storageUnitId: string | undefined,
  id: string | undefined,
): { rental: Rental | undefined; isLoading: boolean } => {
  const [rental, setRental] = useState<Rental | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (storageUnitId && id) {
      setIsLoading(true);
      getRental(storageUnitId, id)
        .then(rental => {
          setRental(rental);
        })
        .finally(() => setIsLoading(false));
    }
  }, [storageUnitId, id]);
  return { rental, isLoading };
};

export const updateRental = async (rental: Partial<Rental>): Promise<EntityIdPayload> => {
  const token = await getAuthToken();

  const data: PutRentalBody = {
    accessLogsActive: rental.accessLogsActive ?? false,
    sharedAccessActive: rental.shareAccessActive ?? true,
    endDate: rental.expires,
  };

  const response = await put(`/units/${rental.storageUnitId}/rentals/${rental.id}`, data, token);
  if (response.status !== 200) {
    console.error(response);
  }
  return response.data;
};
