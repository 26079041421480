import * as React from 'react';
import { Chart } from 'react-chartjs-2';
import { makeOptions, makeScale } from './charts';

import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartType,
  ChartTypeRegistry,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { colors } from '../../../../theming/colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Tooltip,
  Filler,
);

/**
 * TODO/NOTES:
 * - Removed "isDaily" - was always set to true but could be nice to have oher time periods (should probably wait for a request for it though)
 * - Removed "energyExtrapolation" - was always just an empty array
 * - Removed export for all of the functions, they weren't (and shouldn't be) used anywhere else
 * - Made component telemetry-agnostic
 */

type DataSetOptions = {
  label?: string;
  color?: string;
};
interface TelemetrySeriesChartData {
  values: number[];
  total: number;
  options?: DataSetOptions;
}

/**
 * Format a date to a string in the format DD-MM-YYYY
 * @param date
 * @returns date string in the format DD-MM-YYYY
 */
const formatKeyToDDMMYYYY = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Adding 1 because months are zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

/**
 * Create a list of dates for each day between the start and end date to be used as the x-axis for the chart
 * @param startDate
 * @param endDate
 * @returns a list of dates, one per day for each day between the start and end date
 */
function getDayKeys(startDate: Date, endDate: Date): Date[] {
  const days: Date[] = [];
  const start = new Date(startDate.setHours(0, 0, 0, 0));

  while (start <= endDate) {
    days.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }
  return days;
}

/**
 * Format the telemetry data to make it easy to use in the chart
 * @param data
 * @param startDate
 * @param endDate
 * @returns the formatted telemetry data
 */
const formatTelemetryData = (
  data: TelemetryData[],
  xAxis: string[],
): Pick<TelemetrySeriesChartData, 'values' | 'total'> => {
  const values: number[] = [];

  // For each mark in the x axis, add the value for that day to the values array, if one can be found
  xAxis.forEach(day => {
    const dayData = data.find(d => formatKeyToDDMMYYYY(d.timestamp) === day);
    if (dayData) {
      values.push(dayData.value);
    } else {
      values.push(0);
    }
  });

  const total = values.reduce((acc, curr) => acc + curr, 0);

  return { values, total };
};

/**
 * Format the data so that it can be consumed by ChartJS
 * @param datasets
 * @param id
 */
const assembleChartData = (
  datasets: TelemetrySeriesChartData[],
  xAxis: string[],
  yId: string,
  xId: string,
): ChartData<ChartType, number[], string> => {
  const res = {
    labels: xAxis,
    // xLabels: xAxis.map(d => d, id), // TODO: What is this ID doing? Was in the previous code
    datasets: datasets.map(set => ({
      data: set.values,
      label: set.options?.label || '',
      backgroundColor: set.options?.color || colors.primary,
      borderColor: set.options?.color || colors.primary,
      yAxisID: yId,
      xAxisID: xId,
    })),
  };
  return res;
};

// TODO: This is a parsed version of the proto DeviceTelemetry type to prevent needing to convert to number lots of times
export type TelemetryData = { value: number; timestamp: Date };

type TelemetrySeries = {
  data: TelemetryData[];
  options?: DataSetOptions;
};

interface TelemetryChartProps {
  title: string;
  datasets: TelemetrySeries[];
  startDateOverride?: Date;
  endDateOverride?: Date;
  maxYAxis?: number;
  chartType?: keyof ChartTypeRegistry;
}

/**
 * This component takes a list of telemetry data and displays it in a chart.
 *
 * It also takes an optional set of options for styling the chart.
 */
const TelemetryChart: React.FunctionComponent<TelemetryChartProps> = ({
  datasets,
  title,
  startDateOverride,
  endDateOverride,
  maxYAxis,
  chartType,
}) => {
  let overallStartDate = new Date('3000-01-01'); // Start high because we want to find the earliest date
  let overallEndDate = new Date('1000-01-01'); // Start low because we want to find the latest date

  // Calculate the overall start and end date for all of the datasets so they can be displayed on the same chart
  datasets.forEach(({ data }) => {
    if (!data.length) return;
    const sortedData = data.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
    const startDate = sortedData[0].timestamp;
    const endDate = sortedData[sortedData.length - 1].timestamp;

    if (startDate < overallStartDate) {
      overallStartDate = startDate;
    }
    if (endDate > overallEndDate) {
      overallEndDate = endDate;
    }
  });

  const xAxis = getDayKeys(startDateOverride || overallStartDate, endDateOverride || overallEndDate).map(
    formatKeyToDDMMYYYY,
  );

  const formattedTelemetryData: TelemetrySeriesChartData[] = [];
  datasets.forEach(({ data, options }) => {
    // For each dataset, map the data points onto the whole x-axis
    formattedTelemetryData.push({ ...formatTelemetryData(data, xAxis), options });
  });

  const yAxisId = 'telemetryValues';
  const xAxisId = 'days';
  const chart = assembleChartData(formattedTelemetryData, xAxis, yAxisId, xAxisId);
  const options = makeOptions({
    [yAxisId]: makeScale(undefined, 'left', true, maxYAxis, 0, title),
  });

  return <Chart type={chartType || 'line'} options={options} data={chart} />;
};

export default TelemetryChart;
