import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { Button } from '@mui/material';
import * as React from 'react';
import { unassignDeviceFromStorageUnit } from '../gateway/deviceGateway';
import StorageUnitDetailContext from '../context/StorageUnitDetailContext';

const UnassignDeviceButton = ({
  storageUnitId,
  deviceId,
  setParentLoadingState,
  onComplete,
}: {
  storageUnitId: string;
  deviceId: string;
  setParentLoadingState?: (s: boolean) => void;
  onComplete?: () => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const { toaster } = useToaster();
  const { forceRefreshDevices } = React.useContext(StorageUnitDetailContext);

  const unassignDevice = async () => {
    if (!deviceId) {
      toaster('Action Failed', 'Unable to unassign device because no device ID was found', ToastMessageTypes.ERROR);
      return;
    }

    if (!storageUnitId) {
      toaster(
        'Action Failed',
        'Unable to unassign device because no storage unit ID was found',
        ToastMessageTypes.ERROR,
      );
      return;
    }

    try {
      setLoading(true);
      setParentLoadingState?.(true);
      await unassignDeviceFromStorageUnit(deviceId, storageUnitId);
      await forceRefreshDevices();
    } catch (error) {
      console.error(error);
      toaster('Action Failed', 'Device could not be unassigned', ToastMessageTypes.ERROR);
    } finally {
      setLoading(false);
      setParentLoadingState?.(false);
      onComplete?.();
    }
  };

  return (
    <Button
      disabled={loading}
      onClick={() =>
        window.confirm("Are you sure that you want to unassign this device from it's current unit?") && unassignDevice()
      }
    >
      Unassign from unit
    </Button>
  );
};

export default UnassignDeviceButton;
