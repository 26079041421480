import * as React from 'react';
import Tile from './card/Tile';
import ValueContainer from './card/ValueContainer';
import Unit from './card/Unit';
import Label from './card/Label';
import Value from './card/Value';
import IconContainer from './card/IconContainer';
import { ActiveStateIndicator } from './ActiveStateIndicator';
import { Box } from '@mui/material';

interface Props {
  value: string;
  unit?: string;
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
  className?: string;
  active: boolean;
}

export const SensorComponent: React.FunctionComponent<Props> = ({
  value,
  unit,
  label,
  icon,
  onClick,
  className,
  active,
}) => {
  return (
    <Tile className={className} onClick={() => onClick()}>
      <Box position="absolute" sx={{ right: 10, top: '4px' }}>
        <ActiveStateIndicator active={active} />
      </Box>
      <IconContainer>{icon}</IconContainer>
      <ValueContainer>
        <Value>{value}</Value>
        <Unit>{unit}</Unit>
      </ValueContainer>
      <Label>{label}</Label>
    </Tile>
  );
};
