import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { FC } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ReactHookFormInputOptions, ReactHookFormInputRules } from './ReactHookFormInput';

type ReactHookFormRadioGroupProps = {
  field: ControllerRenderProps<FieldValues, string>;
  options: ReactHookFormInputOptions[];
  rules?: ReactHookFormInputRules;
};

const ReactHookFormRadioGroup: FC<ReactHookFormRadioGroupProps> = ({ field, options, rules }) => {
  return (
    <RadioGroup
      row
      {...field}
      sx={{
        height: '100%',
      }}
    >
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option.value}
          control={<Radio required={rules?.required} />}
          label={option.name}
        />
      ))}
    </RadioGroup>
  );
};

export default ReactHookFormRadioGroup;
