import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { X } from 'phosphor-react';
import * as React from 'react';
import { FormEvent } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StorageUnitDetailContext from '../../context/StorageUnitDetailContext';
import { deleteStorageUnit } from '../../gateway/storageUnit/delete';
import { useStorageUnitCurrentRental } from '../../gateway/storageUnit/rentals';
import { useStorageUnitKeysFor } from '../../gateway/storageUnitKeys/storageUnitKeysGateway';

const UnitDeleteDialog: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const rental = useStorageUnitCurrentRental(params.storageUnitId!);
  const storageUnitKeys = useStorageUnitKeysFor(params.storageUnitId!);
  const { toaster } = useToaster();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await deleteStorageUnit(params.storageUnitId!);
      toaster('Action Successful', 'Storage unit was deleted.', ToastMessageTypes.SUCCESS);
      navigate(`/park/${params.parkId}`, { replace: true });
    } catch (e) {
      toaster('Error Occurred', 'Unable to delete storage unit.', ToastMessageTypes.ERROR);
    }
    return false;
  };

  const onCancel = () => {
    navigate(location.pathname.slice(0, -7), {
      replace: true,
    });
  };

  const hasActiveKeys = storageUnitKeys?.some(key => key.status);

  const { storageUnitDevices: components } = React.useContext(StorageUnitDetailContext);

  const hasAssignedDevices = React.useMemo(() => components && components.length > 0, [components]);

  return (
    <>
      <Dialog open={true}>
        <form onSubmit={onSubmit}>
          <DialogTitle variant="h2">
            Delete Storage Unit
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 12,
                top: 14,
              }}
            >
              {<X />}
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4}>
              <Grid item xs={1}>
                <WarningAmberIcon color="error" fontSize="large" />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{ fontWeight: 700 }}>
                  Deleting a storage unit will remove all data permanently and cannot be undone.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  This includes historical rental data, keys, devices, access history, and any other data associated
                  with this storage unit.
                </Typography>
                <Typography>
                  It is recomended to instead update this storage unit to the desired state instead of deleting it.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {!rental.isLoading && rental.data !== undefined && (
                  <Typography color="error" sx={{ fontWeight: 700 }}>
                    Cannot delete a storage unit that has active or future rentals.
                  </Typography>
                )}
                {hasActiveKeys && (
                  <Typography color="error" sx={{ fontWeight: 700 }}>
                    Cannot delete a storage unit that has active keys.
                  </Typography>
                )}
                {hasAssignedDevices && (
                  <Typography color="error" sx={{ fontWeight: 700 }}>
                    Cannot delete a storage unit that has assigned devices
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              color="error"
              type="submit"
              disabled={rental.isLoading || rental.data !== undefined || hasActiveKeys || hasAssignedDevices}
            >
              DELETE
            </Button>
            <Button variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UnitDeleteDialog;
