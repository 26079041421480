import { FormControl, InputLabel, Select, SelectProps } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const FormikSelect: React.FC<SelectProps> = props => {
  const [field, meta] = useField(props.name!);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{props.label + (props.required ? ' *' : '')}</InputLabel>
      <Select {...props} {...field} error={meta.touched && Boolean(meta.error)} />
    </FormControl>
  );
};

export default FormikSelect;
