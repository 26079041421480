import React, { useEffect, useState } from 'react';
import { getDevicesByStorageUnit } from '../gateway/deviceGateway';
import { StorageUnit } from '../model';
import { Device } from '@ivy/proto/dist/devices/v2/device';

type ForceRefreshFunction = () => void;

type StorageUnitDetailContextApi = {
  storageUnitDevices: Device[] | undefined;
  forceRefreshDevices: ForceRefreshFunction;
  storageUnit: StorageUnit;
  selectedDevice: Device | undefined;
  setSelectedDevice: (device: Device | undefined) => void;
};
export const StorageUnitDetailContext = React.createContext<StorageUnitDetailContextApi>(
  {} as StorageUnitDetailContextApi,
);

type StorageUnitDetailProps = {
  children?: React.ReactNode;
  storageUnit: StorageUnit;
};

const StorageUnitDetailProvider: React.FC<StorageUnitDetailProps> = ({ children, storageUnit }) => {
  const [storageUnitDevices, setStorageUnitDevices] = useState<Device[] | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>();
  const devicesExist = !!storageUnitDevices;

  const forceRefreshDevices = () => {
    setStorageUnitDevices(undefined);
  };

  useEffect(() => {
    if (!storageUnit || !storageUnit.thingsboardId) return;
    getDevicesByStorageUnit(storageUnit.id)
      .then(devices => setStorageUnitDevices(devices))
      .catch(error => {
        // Where do we want to log these? Do we want to show the user?
        console.error(`${error.response?.status} Error: ${error.response?.data?.message}`);
        setStorageUnitDevices(undefined);
      });
  }, [devicesExist, storageUnit]);

  return (
    <StorageUnitDetailContext.Provider
      value={{
        forceRefreshDevices,
        storageUnitDevices,
        storageUnit,
        selectedDevice,
        setSelectedDevice,
      }}
    >
      {children}
    </StorageUnitDetailContext.Provider>
  );
};

export default StorageUnitDetailContext;
export { StorageUnitDetailProvider };
