import { Device, DeviceType, SmartMeterPowerState } from '@ivy/proto/dist/devices/v2/device';
import { CancelTokenSource } from 'axios';

import { useEffect, useState } from 'react';
import { components } from '../../gen/backoffice-service';
import { get, post } from './requestUtils';
import { withBearerAuthorization } from './withBearerAuthorization';
import { getAuthToken } from '../auth/auth';

/**
 *
 * @param parkId
 * @param type
 * @param page for pagination, starts at 1
 * @param itemsPerPage for pagination
 * @returns
 */
export const getDevicesByType = async (
  parkId: string,
  type: DeviceType,
  page: number,
  itemsPerPage: number,
): Promise<Device[]> => {
  const token = await getAuthToken();
  const response = await get<Device[]>(
    `/devices/type?parkId=${parkId}&deviceType=${type}&page=${page - 1}&itemsPerPage=${itemsPerPage}`,
    token,
  );
  return response.data;
};

export const getDevicesByStorageUnit = async (storageUnitId: string): Promise<Device[]> => {
  const token = await getAuthToken();
  const response = await get<Device[]>(`/devices/storage-unit/${storageUnitId}`, token);
  return response.data;
};

export const updateSmartMeterPowerStatus = async (deviceId: string, value: SmartMeterPowerState): Promise<void> => {
  const token = await getAuthToken();
  const response = await post(`/devices/${deviceId}/switch`, { status: value }, token);
  return response.data;
};

export const assignDeviceToStorageUnit = async (deviceId: string, storageUnitId: string) => {
  const token = await getAuthToken();
  const response = await post<Device[]>(`/devices/storage-unit/assign`, { deviceId: deviceId, storageUnitId }, token);
  return response.data;
};

export const unassignDeviceFromStorageUnit = async (deviceId: string, storageUnitId: string) => {
  const token = await getAuthToken();
  const response = await post<Device[]>(`/devices/storage-unit/unassign`, { deviceId: deviceId, storageUnitId }, token);
  return response.data;
};

export const getDevice = async (deviceId: string) => {
  const token = await getAuthToken();
  const response = await get<Device>(`/devices/id/${deviceId}`, token);
  return response.data;
};

export const useDevice = (deviceId: string): Device | null | undefined => {
  const [device, setDevice] = useState<Device | null | undefined>();

  useEffect(() => {
    getDevice(deviceId).then(d => setDevice(d));
  }, [deviceId]);
  return device;
};

export const searchDevice = async (
  name: string,
  page: number,
  itemsPerPage: number,
  cancelToken: CancelTokenSource,
): Promise<Device[]> => {
  const token = await getAuthToken();

  const response = await get<Device[]>(
    `/devices/search?searchQuery=${name}&page=${page - 1}&itemsPerPage=${itemsPerPage}`,
    { ...withBearerAuthorization(token), cancelToken: cancelToken.token },
  );

  return response.data;
};

export const getDeviceTelemetry = async (
  deviceId: string,
  body: components['schemas']['GetTelemetryBody'],
): Promise<components['schemas']['DeviceTelemetryResponse'][]> => {
  const token = await getAuthToken();

  const response = await post<components['schemas']['DeviceTelemetryResponse'][]>(
    `/devices/${deviceId}/telemetry`,
    body,
    token,
  );

  return response.data;
};

export const getLatestDeviceTelemetry = async (
  deviceId: string,
  body: components['schemas']['GetLatestTelemetryBody'],
): Promise<components['schemas']['SingleDeviceTelemetryResponse'][]> => {
  const token = await getAuthToken();

  const response = await post<components['schemas']['SingleDeviceTelemetryResponse'][]>(
    `/devices/${deviceId}/latest-telemetry`,
    body,
    token,
  );

  return response.data;
};
