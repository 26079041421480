// --- external imports
import Box from '@mui/material/Box';
import * as React from 'react';
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

// --- components
import { DeviceTelemetryType } from '@ivy/proto/dist/devices/v2/telemetry';
import AdminSidebar from './components/sidebar/AdminSidebar';
import OperatorSidebar from './components/sidebar/OperatorSidebar';
import TenantSidebar from './components/sidebar/TenantSidebar';
import Admin from './pages/Admin/Admin';
import Login from './pages/Authentication/Login';
import PasswordReset from './pages/Authentication/PasswordReset';
import Dashboard from './pages/Dashboard/Dashboard';
import Emails from './pages/Emails/Emails';
import Notifications from './pages/Notifications/Notifications';
import ParkDetail from './pages/ParkDetail/ParkDetail';
import ParkEdit from './pages/ParkDetail/ParkEdit';
import ParkAdd from './pages/Parks/ParkAdd';
import Parks from './pages/Parks/Parks';
import Profile from './pages/Profile/Profile';
import Tasks from './pages/Tasks/Tasks';
import TenantAdd from './pages/Tenants/TenantAdd';
import TenantEdit from './pages/Tenants/TenantEdit';
import Tenants from './pages/Tenants/Tenants';
import UnitDeleteDialog from './pages/UnitDetail/UnitDeleteDialog';
import UnitDetail from './pages/UnitDetail/UnitDetail';
import UnitEventsDialog from './pages/UnitDetail/UnitEventsDialog';
import EnergyUsageDialog from './pages/UnitDetail/devices/DataView/EnergyUsageDialog/EnergyUsageDialog';
import SensorDataDialog from './pages/UnitDetail/devices/DataView/SensorDataDialog/SensorDataDialog';
import EditDevicesDialog from './pages/UnitDetail/devices/EditDevicesDialog/EditDevicesDialog';
import UnlockDialog from './pages/UnitDetail/devices/UnlockDialog';

// --- helpers
import AuthContext from './context/AuthContext';

// --- styles
import { TenantsProvider } from './context/TenantsContext';
import { colors } from './theming/colors';
export const drawerWidth = 240;

export default function App() {
  const auth = useContext(AuthContext);

  if (auth.user === undefined) {
    return null;
  }

  if (auth.user === null) {
    return (
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/passwordReset" element={<PasswordReset hideLogo={true} />} />
      </Routes>
    );
  }

  const sideBarElement = auth.user?.isAdmin ? (
    <AdminSidebar />
  ) : auth.user?.isOperator ? (
    <OperatorSidebar />
  ) : (
    <TenantSidebar />
  );

  return (
    <>
      <Box sx={{ display: 'flex', height: '100%' }}>
        {sideBarElement}
        <Box component="main" sx={{ flexGrow: 1, bgcolor: colors.white, height: '100%' }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />

            <Route path="/passwordReset" element={<PasswordReset hideBackground={true} />} />

            <Route path="/park/:id" element={<ParkDetail />}>
              <Route path="edit" element={<ParkEdit />} />
            </Route>

            <Route path="/park/:parkId/unit/:storageUnitId" element={<UnitDetail />}>
              <Route path="devices/edit" element={<EditDevicesDialog />} />
              <Route path="power/:deviceId" element={<EnergyUsageDialog />} />
              <Route
                path="temperature/:deviceId"
                element={<SensorDataDialog type={DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TEMPERATURE} />}
              />
              <Route
                path="humidity/:deviceId"
                element={<SensorDataDialog type={DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_HUMIDITY} />}
              />
              <Route path="lock/:deviceId" element={<UnlockDialog />} />
              <Route path="events" element={<UnitEventsDialog />} />
              <Route path="delete" element={<UnitDeleteDialog />} />
            </Route>

            <Route path="/parks" element={<Parks />}>
              <Route path="add" element={<ParkAdd />} />
            </Route>

            <Route
              path="/tenants"
              element={
                <TenantsProvider>
                  <Tenants />
                </TenantsProvider>
              }
            >
              <Route path="add" element={<TenantAdd />} />
              <Route path=":id" element={<TenantEdit />} />
            </Route>

            <Route path="/tasks" element={<Tasks />} />

            <Route path="/notifications" element={<Notifications />} />

            <Route path="/profile" element={<Profile />} />

            <Route path="/admin" element={<Admin />} />
            <Route path="/emails" element={<Emails />} />
          </Routes>
        </Box>
      </Box>
    </>
  );
}
