import * as React from 'react';
import { colors } from '../../../theming/colors';
import Brightness1Icon from '@mui/icons-material/Brightness1';

interface ActiveStateIndicatorProps {
  active: boolean;
}

export const ActiveStateIndicator: React.FunctionComponent<ActiveStateIndicatorProps> = ({ active }) => (
  <Brightness1Icon
    sx={{
      fontSize: 12,
      color: active ? colors.signalGreen : colors.signalAlert,
      position: 'absolute',
      right: 10,
      top: 10,
    }}
    titleAccess={active ? 'Active' : 'Inactive'}
  />
);
