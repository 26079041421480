export const l10n = {
  naviBeta: 'Backoffice Beta',
  naviDashboard: 'Dashboard',
  naviParks: 'Parks and Units',
  naviTenants: 'Tenants',
  naviTasks: 'Tasks',
  naviNotifications: 'Notifications',
  naviAdmin: 'Admin in',
  naviProfile: 'Profile',
  naviEmailTemplate: 'Emails',
};

export const l10nFirebaseErrors: { [index: string]: string } = {
  'auth/email-already-exists':
    'The provided email is already in use by an existing user. Each user must have a unique email.',
  'auth/wrong-password': 'The provided password is incorrect.',
  'auth/invalid-email': 'The provided value for the email user property is invalid. It must be a string email address.',
  'auth/invalid-password':
    'The provided value for the password user property is invalid. It must be a string with at least six characters.',
  'auth/user-not-found': 'There is no existing user record corresponding to the provided identifier.',

  else: 'Server error.',
};
