import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { IconProps } from 'phosphor-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../theming/colors';

interface Props {
  title: string;
  route: string;
  selected?: boolean;
  Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
}

const SidebarItem: React.FunctionComponent<Props> = ({ title, route, selected, Icon }) => {
  const navigate = useNavigate();
  const color = selected ? colors.primary : colors.white;

  return (
    <ListItem
      button
      sx={{
        marginY: 1.5,
        color: color,
        borderRight: selected ? `solid ${color} 4px` : 'none',
      }}
      onClick={() => navigate(route)}
    >
      <ListItemIcon sx={{ justifyContent: 'center' }}>
        <Icon color={color} size={20} />
      </ListItemIcon>
      <ListItemText primary={<b>{title}</b>} />
    </ListItem>
  );
};

export default SidebarItem;
