import { ToastProvider } from '@ivy/toaster';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import App from './App';
import { config } from './config';
import { AuthProvider } from './context/AuthContext';
import { CompanyContextProvider } from './context/CompanyContext';
import { ParksProvider } from './context/ParksContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './theming/theme';
import ScrollToTop from './util/ScrollToTop';

if (config.sentryEnabled) {
  Sentry.init({
    dsn: 'https://cdc79de26d624dfa7945c345abc8ee4c@o4506375849639936.ingest.us.sentry.io/4506375854030848',
    release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    environment: process.env.SENTRY_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/backoffice-service.+ivy-solutions\.de/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ToastProvider>
            <BrowserRouter>
              <CompanyContextProvider>
                <ParksProvider>
                  <CssBaseline />
                  <ScrollToTop />
                  <App />
                </ParksProvider>
              </CompanyContextProvider>
            </BrowserRouter>
          </ToastProvider>
        </AuthProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

reportWebVitals();
