import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useCreatePark from '../../gateway/park/post';
import { Park } from '../../model';
import ParkForm from '../ParkDetail/ParkForm';
import ParksContext from '../../context/ParksContext';

const ParkAdd: React.FC = () => {
  const navigate = useNavigate();
  const createPark = useCreatePark();
  const { forceRefreshParks } = React.useContext(ParksContext);

  const onCancel = () => {
    navigate('/parks', { replace: true });
  };
  const onSave = async (values: Park) => {
    const park = await createPark(values);
    forceRefreshParks();
    navigate('/park/' + park.id, { replace: true });
  };
  return <ParkForm {...{ onCancel, onSave }} />;
};

export default ParkAdd;
