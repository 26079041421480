import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { X } from 'phosphor-react';
import * as React from 'react';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { FormEvent, useState } from 'react';
import LoadingIcon from '../../../components/LoadingIcon';
import { RentalEntry } from '../../../context/RentalManagementContext';
import { updateRental } from '../../../gateway/rentalGateway';
import ShareIcon from '@mui/icons-material/Share';

const UnitRentalManagementSharedAccessDialog: React.FC<{
  closeDialog: () => void;
  rental: RentalEntry;
}> = ({ closeDialog, rental }) => {
  const { toaster } = useToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await updateRental({
        ...rental,
        shareAccessActive: true,
      });
      closeDialog();
      toaster('Action Successful', 'Shared access enabled.', ToastMessageTypes.SUCCESS);
    } catch (e) {
      setLoading(false);
      toaster('Error Occurred', 'Unable to enable shared access.', ToastMessageTypes.ERROR);
    }
    return false;
  };

  const onCancel = () => closeDialog();

  return (
    <>
      <Dialog open={true} className="e2e-rental-shared-access-dialog">
        <form onSubmit={onSubmit}>
          <DialogTitle variant="h2">
            Shared Access
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 12,
                top: 14,
              }}
            >
              {<X />}
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4}>
              <Grid item xs={1} mt={0.5}>
                <ShareIcon color="primary" fontSize="large" />
              </Grid>
              <Grid item xs={11}>
                <Typography>
                  Before activating shared access, make sure the tenant has approved & is being charged.
                  <br />
                  <br />
                  After clicking approve, shared access will be enabled, and you will be able to add additional keys to
                  this rental.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="success"
              type="submit"
              disabled={loading}
              style={{
                width: 150,
              }}
            >
              {loading ? <LoadingIcon /> : 'Approve'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UnitRentalManagementSharedAccessDialog;
