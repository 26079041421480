"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               unknown
// source: users/v1/auth.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifyApiKeyResponse = exports.VerifyApiKeyRequest = exports.AuthUser = exports.authErrorToNumber = exports.authErrorToJSON = exports.authErrorFromJSON = exports.AuthError = exports.userRoleToNumber = exports.userRoleToJSON = exports.userRoleFromJSON = exports.UserRole = exports.protobufPackage = void 0;
/* eslint-disable */
const wire_1 = require("@bufbuild/protobuf/wire");
exports.protobufPackage = "users.v1";
var UserRole;
(function (UserRole) {
    UserRole["USER_ROLE_ADMIN"] = "USER_ROLE_ADMIN";
    UserRole["USER_ROLE_OPERATOR"] = "USER_ROLE_OPERATOR";
    UserRole["USER_ROLE_TENANT"] = "USER_ROLE_TENANT";
    UserRole["UNRECOGNIZED"] = "UNRECOGNIZED";
})(UserRole || (exports.UserRole = UserRole = {}));
function userRoleFromJSON(object) {
    switch (object) {
        case 0:
        case "USER_ROLE_ADMIN":
            return UserRole.USER_ROLE_ADMIN;
        case 1:
        case "USER_ROLE_OPERATOR":
            return UserRole.USER_ROLE_OPERATOR;
        case 2:
        case "USER_ROLE_TENANT":
            return UserRole.USER_ROLE_TENANT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return UserRole.UNRECOGNIZED;
    }
}
exports.userRoleFromJSON = userRoleFromJSON;
function userRoleToJSON(object) {
    switch (object) {
        case UserRole.USER_ROLE_ADMIN:
            return "USER_ROLE_ADMIN";
        case UserRole.USER_ROLE_OPERATOR:
            return "USER_ROLE_OPERATOR";
        case UserRole.USER_ROLE_TENANT:
            return "USER_ROLE_TENANT";
        case UserRole.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.userRoleToJSON = userRoleToJSON;
function userRoleToNumber(object) {
    switch (object) {
        case UserRole.USER_ROLE_ADMIN:
            return 0;
        case UserRole.USER_ROLE_OPERATOR:
            return 1;
        case UserRole.USER_ROLE_TENANT:
            return 2;
        case UserRole.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.userRoleToNumber = userRoleToNumber;
var AuthError;
(function (AuthError) {
    AuthError["AUTH_ERROR_UNSPECIFIED"] = "AUTH_ERROR_UNSPECIFIED";
    AuthError["AUTH_ERROR_EMAIL_NOT_FOUND"] = "AUTH_ERROR_EMAIL_NOT_FOUND";
    AuthError["AUTH_ERROR_INVALID_PASSWORD"] = "AUTH_ERROR_INVALID_PASSWORD";
    AuthError["AUTH_ERROR_INVALID_EMAIL"] = "AUTH_ERROR_INVALID_EMAIL";
    AuthError["AUTH_ERROR_USER_DISABLED"] = "AUTH_ERROR_USER_DISABLED";
    AuthError["AUTH_ERROR_TOO_MANY_ATTEMPTS"] = "AUTH_ERROR_TOO_MANY_ATTEMPTS";
    AuthError["AUTH_ERROR_TOKEN_EXPIRED"] = "AUTH_ERROR_TOKEN_EXPIRED";
    AuthError["AUTH_ERROR_USER_NOT_FOUND"] = "AUTH_ERROR_USER_NOT_FOUND";
    AuthError["AUTH_ERROR_INVALID_REFRESH_TOKEN"] = "AUTH_ERROR_INVALID_REFRESH_TOKEN";
    AuthError["AUTH_ERROR_MISSING_REFRESH_TOKEN"] = "AUTH_ERROR_MISSING_REFRESH_TOKEN";
    AuthError["AUTH_ERROR_WEAK_PASSWORD"] = "AUTH_ERROR_WEAK_PASSWORD";
    AuthError["AUTH_ERROR_INVALID_ID_TOKEN"] = "AUTH_ERROR_INVALID_ID_TOKEN";
    AuthError["UNRECOGNIZED"] = "UNRECOGNIZED";
})(AuthError || (exports.AuthError = AuthError = {}));
function authErrorFromJSON(object) {
    switch (object) {
        case 0:
        case "AUTH_ERROR_UNSPECIFIED":
            return AuthError.AUTH_ERROR_UNSPECIFIED;
        case 1:
        case "AUTH_ERROR_EMAIL_NOT_FOUND":
            return AuthError.AUTH_ERROR_EMAIL_NOT_FOUND;
        case 2:
        case "AUTH_ERROR_INVALID_PASSWORD":
            return AuthError.AUTH_ERROR_INVALID_PASSWORD;
        case 3:
        case "AUTH_ERROR_INVALID_EMAIL":
            return AuthError.AUTH_ERROR_INVALID_EMAIL;
        case 4:
        case "AUTH_ERROR_USER_DISABLED":
            return AuthError.AUTH_ERROR_USER_DISABLED;
        case 5:
        case "AUTH_ERROR_TOO_MANY_ATTEMPTS":
            return AuthError.AUTH_ERROR_TOO_MANY_ATTEMPTS;
        case 6:
        case "AUTH_ERROR_TOKEN_EXPIRED":
            return AuthError.AUTH_ERROR_TOKEN_EXPIRED;
        case 7:
        case "AUTH_ERROR_USER_NOT_FOUND":
            return AuthError.AUTH_ERROR_USER_NOT_FOUND;
        case 8:
        case "AUTH_ERROR_INVALID_REFRESH_TOKEN":
            return AuthError.AUTH_ERROR_INVALID_REFRESH_TOKEN;
        case 9:
        case "AUTH_ERROR_MISSING_REFRESH_TOKEN":
            return AuthError.AUTH_ERROR_MISSING_REFRESH_TOKEN;
        case 10:
        case "AUTH_ERROR_WEAK_PASSWORD":
            return AuthError.AUTH_ERROR_WEAK_PASSWORD;
        case 11:
        case "AUTH_ERROR_INVALID_ID_TOKEN":
            return AuthError.AUTH_ERROR_INVALID_ID_TOKEN;
        case -1:
        case "UNRECOGNIZED":
        default:
            return AuthError.UNRECOGNIZED;
    }
}
exports.authErrorFromJSON = authErrorFromJSON;
function authErrorToJSON(object) {
    switch (object) {
        case AuthError.AUTH_ERROR_UNSPECIFIED:
            return "AUTH_ERROR_UNSPECIFIED";
        case AuthError.AUTH_ERROR_EMAIL_NOT_FOUND:
            return "AUTH_ERROR_EMAIL_NOT_FOUND";
        case AuthError.AUTH_ERROR_INVALID_PASSWORD:
            return "AUTH_ERROR_INVALID_PASSWORD";
        case AuthError.AUTH_ERROR_INVALID_EMAIL:
            return "AUTH_ERROR_INVALID_EMAIL";
        case AuthError.AUTH_ERROR_USER_DISABLED:
            return "AUTH_ERROR_USER_DISABLED";
        case AuthError.AUTH_ERROR_TOO_MANY_ATTEMPTS:
            return "AUTH_ERROR_TOO_MANY_ATTEMPTS";
        case AuthError.AUTH_ERROR_TOKEN_EXPIRED:
            return "AUTH_ERROR_TOKEN_EXPIRED";
        case AuthError.AUTH_ERROR_USER_NOT_FOUND:
            return "AUTH_ERROR_USER_NOT_FOUND";
        case AuthError.AUTH_ERROR_INVALID_REFRESH_TOKEN:
            return "AUTH_ERROR_INVALID_REFRESH_TOKEN";
        case AuthError.AUTH_ERROR_MISSING_REFRESH_TOKEN:
            return "AUTH_ERROR_MISSING_REFRESH_TOKEN";
        case AuthError.AUTH_ERROR_WEAK_PASSWORD:
            return "AUTH_ERROR_WEAK_PASSWORD";
        case AuthError.AUTH_ERROR_INVALID_ID_TOKEN:
            return "AUTH_ERROR_INVALID_ID_TOKEN";
        case AuthError.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.authErrorToJSON = authErrorToJSON;
function authErrorToNumber(object) {
    switch (object) {
        case AuthError.AUTH_ERROR_UNSPECIFIED:
            return 0;
        case AuthError.AUTH_ERROR_EMAIL_NOT_FOUND:
            return 1;
        case AuthError.AUTH_ERROR_INVALID_PASSWORD:
            return 2;
        case AuthError.AUTH_ERROR_INVALID_EMAIL:
            return 3;
        case AuthError.AUTH_ERROR_USER_DISABLED:
            return 4;
        case AuthError.AUTH_ERROR_TOO_MANY_ATTEMPTS:
            return 5;
        case AuthError.AUTH_ERROR_TOKEN_EXPIRED:
            return 6;
        case AuthError.AUTH_ERROR_USER_NOT_FOUND:
            return 7;
        case AuthError.AUTH_ERROR_INVALID_REFRESH_TOKEN:
            return 8;
        case AuthError.AUTH_ERROR_MISSING_REFRESH_TOKEN:
            return 9;
        case AuthError.AUTH_ERROR_WEAK_PASSWORD:
            return 10;
        case AuthError.AUTH_ERROR_INVALID_ID_TOKEN:
            return 11;
        case AuthError.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.authErrorToNumber = authErrorToNumber;
function createBaseAuthUser() {
    return { id: "", companyId: "", email: undefined, role: UserRole.USER_ROLE_ADMIN };
}
exports.AuthUser = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.companyId !== "") {
            writer.uint32(18).string(message.companyId);
        }
        if (message.email !== undefined) {
            writer.uint32(26).string(message.email);
        }
        if (message.role !== UserRole.USER_ROLE_ADMIN) {
            writer.uint32(32).int32(userRoleToNumber(message.role));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAuthUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.companyId = reader.string();
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.email = reader.string();
                    continue;
                }
                case 4: {
                    if (tag !== 32) {
                        break;
                    }
                    message.role = userRoleFromJSON(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            companyId: isSet(object.companyId) ? globalThis.String(object.companyId) : "",
            email: isSet(object.email) ? globalThis.String(object.email) : undefined,
            role: isSet(object.role) ? userRoleFromJSON(object.role) : UserRole.USER_ROLE_ADMIN,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.companyId !== "") {
            obj.companyId = message.companyId;
        }
        if (message.email !== undefined) {
            obj.email = message.email;
        }
        if (message.role !== UserRole.USER_ROLE_ADMIN) {
            obj.role = userRoleToJSON(message.role);
        }
        return obj;
    },
    create(base) {
        return exports.AuthUser.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAuthUser();
        message.id = object.id ?? "";
        message.companyId = object.companyId ?? "";
        message.email = object.email ?? undefined;
        message.role = object.role ?? UserRole.USER_ROLE_ADMIN;
        return message;
    },
};
function createBaseVerifyApiKeyRequest() {
    return { key: "", companyId: undefined, userId: undefined };
}
exports.VerifyApiKeyRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.companyId !== undefined) {
            writer.uint32(18).string(message.companyId);
        }
        if (message.userId !== undefined) {
            writer.uint32(26).string(message.userId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyApiKeyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.key = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.companyId = reader.string();
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.userId = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? globalThis.String(object.key) : "",
            companyId: isSet(object.companyId) ? globalThis.String(object.companyId) : undefined,
            userId: isSet(object.userId) ? globalThis.String(object.userId) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.key !== "") {
            obj.key = message.key;
        }
        if (message.companyId !== undefined) {
            obj.companyId = message.companyId;
        }
        if (message.userId !== undefined) {
            obj.userId = message.userId;
        }
        return obj;
    },
    create(base) {
        return exports.VerifyApiKeyRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVerifyApiKeyRequest();
        message.key = object.key ?? "";
        message.companyId = object.companyId ?? undefined;
        message.userId = object.userId ?? undefined;
        return message;
    },
};
function createBaseVerifyApiKeyResponse() {
    return { companyId: "", userId: "" };
}
exports.VerifyApiKeyResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.companyId !== "") {
            writer.uint32(10).string(message.companyId);
        }
        if (message.userId !== "") {
            writer.uint32(18).string(message.userId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyApiKeyResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.companyId = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.userId = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            companyId: isSet(object.companyId) ? globalThis.String(object.companyId) : "",
            userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.companyId !== "") {
            obj.companyId = message.companyId;
        }
        if (message.userId !== "") {
            obj.userId = message.userId;
        }
        return obj;
    },
    create(base) {
        return exports.VerifyApiKeyResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVerifyApiKeyResponse();
        message.companyId = object.companyId ?? "";
        message.userId = object.userId ?? "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
