import { components } from '../../../gen/backoffice-service';
import { Park } from '../../model';
import { post } from '../requestUtils';
import { getAuthToken } from '../../auth/auth';

type EntityIdPayload = components['schemas']['EntityIdPayload'];
type PostParkBody = components['schemas']['ParkBody'];

const useCreatePark = (): ((park: Park) => Promise<EntityIdPayload>) => {
  return (park: Park) =>
    savePark({
      name: park.name,
      underConstruction: park.underConstruction,
      address: {
        line1: park.address.line1 || '',
        city: park.address.city,
        postalCode: park.address.postalCode,
        state: park.address.state || '',
        countryCode: park.address.countryCode,
        location:
          park.address.location && park.address.location.lat && park.address.location.long
            ? {
                lat: park.address.location.lat,
                long: park.address.location.long,
              }
            : undefined,
      },
      manager: park.manager
        ? {
            name: park.manager.name,
            contactChannels: park.manager.contactChannels.map(it => {
              return { type: it.type, value: it.value };
            }),
          }
        : undefined,
      storageUnits: park.storageUnits.map(it => {
        return {
          visualId: it.visualId,
          description: it.description,
          thingsboardId: it.thingsboardId,
        };
      }),
      iotRegistry: park.iotRegistry || undefined,
      draft: park.draft ?? false,
    });
};

const savePark = async (body: PostParkBody) => {
  const token = await getAuthToken();
  const response = await post(`/parks`, body, token);
  if (response.status !== 200) {
    console.error(response);
  }
  return response.data;
};

export default useCreatePark;
