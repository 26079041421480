import styled from '@emotion/styled';
import { colors } from '../../../../theming/colors';

export const IconContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: flex-end;
  color: ${colors.grey05};
`;
export default IconContainer;
