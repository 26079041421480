// --- external imports
import * as React from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { Box, Typography, Divider } from '@mui/material';

// --- components
import EmailTemplatesList from './EmailTemplatesList';
import SiteHeader from '../../components/SiteHeader';

// --- providers
import { EmailTemplatesProvider } from '../../context/EmailTemplateContext';

// --- styles
import { colors } from '../../theming/colors';

const Container = styled.div`
  height: 100%;
  background: ${colors.grey02};
  display: grid;
  grid-template-area: 'a' 'b';
  grid-template-rows: max-content auto;
`;

const Emails: React.FC = () => {
  return (
    <Container>
      <SiteHeader title="Emails" />
      <Box sx={{ px: 4, py: 3, backgroundColor: 'white', height: '100%' }}>
        <Typography>
          This is an overview of all the known email templates and their Hubspot IDs.
          <br />
          The Hubspot IDs can be modified or removed.
        </Typography>
        <Divider sx={{ my: 5 }} />
        <EmailTemplatesProvider>
          <EmailTemplatesList />
        </EmailTemplatesProvider>
      </Box>
      <Outlet />
    </Container>
  );
};

export default Emails;
