import styled from '@emotion/styled';
import { Box, Breadcrumbs, Hidden, Link, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../theming/colors';
import { weekNumber } from '../util/util';
import { Expanded, Row } from './Layout';

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Breadcrump {
  title: string;
  path: string;
}

interface Props {
  title: string;
  breadcrumps?: Breadcrump[];
}

//Breadcrump, Datum
const timeString = (): string => {
  const date = new Date();
  return `Week ${weekNumber(date)} - ${date.toLocaleDateString([], {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })} - ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}
  `;
};

const SiteHeader: React.FC<Props> = ({ title, breadcrumps, children }) => {
  const [time, setTime] = React.useState(timeString());
  const navigate = useNavigate();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(timeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ py: 2, px: 4, background: colors.grey02 }}>
      <Breadcrumbs separator="›"></Breadcrumbs>

      <Row>
        <Expanded>
          {breadcrumps && (
            <Breadcrumbs separator="›">
              {breadcrumps.map(b => (
                <Link
                  underline="hover"
                  color="inherit"
                  key={b.path}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(b.path)}
                >
                  {b.title}
                </Link>
              ))}
              <Typography color="text.primary">{title} </Typography>
            </Breadcrumbs>
          )}
        </Expanded>

        <Hidden mdDown>
          <Typography variant="body1" style={{ color: colors.grey05 }}>
            {time}
          </Typography>
        </Hidden>
      </Row>
      <Box m={4} />
      <Header>
        <Typography variant="h3">{title}</Typography>
        <Box sx={{ flex: 1 }} />
        {children}
      </Header>
    </Box>
  );
};

export default SiteHeader;
