import { TextField, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ReactHookFormInputOptions, ReactHookFormInputRules, ReactHookFormInputType } from './ReactHookFormInput';

type ReactHookFormTextInputProps = {
  field: ControllerRenderProps<FieldValues, string>;
  label?: string;
  options?: ReactHookFormInputOptions[];
  rules?: ReactHookFormInputRules;
  showDefault?: boolean;
  type: ReactHookFormInputType;
  disabled?: boolean;
};

const ReactHookFormTextField: FC<ReactHookFormTextInputProps> = ({
  field,
  label,
  options,
  rules,
  showDefault,
  type,
  disabled,
}) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      type={type === ReactHookFormInputType.number ? 'number' : undefined}
      required={rules?.required}
      select={type === ReactHookFormInputType.select}
      variant="standard"
      fullWidth
      SelectProps={
        type === ReactHookFormInputType.select && showDefault
          ? {
              displayEmpty: true,
              value: field.value === undefined ? '' : field.value,
            }
          : undefined
      }
      InputLabelProps={
        showDefault
          ? {
              shrink: true,
            }
          : undefined
      }
      {...field}
    >
      {options?.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ReactHookFormTextField;
