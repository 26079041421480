// --- external imports
import * as React from 'react';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';

// --- api
import { getCompanyEmailTemplates } from '../gateway/companyGateway';

// --- helpers
import AuthContext from './AuthContext';
import { components } from '../../gen/backoffice-service';

type EmailTemplate = components['schemas']['CompanyEmailTemplate'];
type ForceRefreshFunction = () => void;

type EmailTemplatesContextApi = {
  emailTemplates: EmailTemplate[] | undefined;
  loading: boolean;
  forceRefreshEmailTemplates: ForceRefreshFunction;
};

export const EmailTemplatesContext = React.createContext<EmailTemplatesContextApi>({
  emailTemplates: undefined,
  loading: false,
  forceRefreshEmailTemplates: () => {},
});

const EmailTemplatesProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const auth = React.useContext(AuthContext);
  const company = auth.user?.company;

  const [emailTemplates, setEmailTemplates] = React.useState<EmailTemplate[] | undefined>();
  const [loading, setLoading] = React.useState(false);
  const { toaster } = useToaster();

  const emailTemplatesExist = !!emailTemplates;

  const forceRefresh = () => {
    setEmailTemplates(undefined);
  };

  React.useEffect(() => {
    if (!auth) return;
    if (!auth.user?.company) return;
    if (emailTemplatesExist) return;

    setLoading(true);
    getCompanyEmailTemplates()
      .then(r => setEmailTemplates(r))
      .catch(() => {
        toaster('Sorry!', 'There is something wrong, unable to load email templates.', ToastMessageTypes.ERROR);
      })
      .finally(() => setLoading(false));
  }, [auth, company, emailTemplatesExist, toaster]);

  return (
    <EmailTemplatesContext.Provider value={{ emailTemplates, loading, forceRefreshEmailTemplates: forceRefresh }}>
      {children}
    </EmailTemplatesContext.Provider>
  );
};

export default EmailTemplatesContext;
export { EmailTemplatesProvider };
