import * as React from 'react';
import { l10n } from '../../../l10n';
import { useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import { Warning } from 'phosphor-react';

const NotificationsItem: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();
  return (
    <SidebarItem
      Icon={Warning}
      title={l10n.naviNotifications}
      route="/notifications"
      selected={path.startsWith('/notification')}
    />
  );
};

export default NotificationsItem;
