// --- external imports
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Envelope } from 'phosphor-react';

// --- components
import SidebarItem from './SidebarItem';

// --- helpers
import { l10n } from '../../../l10n';

const EmailsItem: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();
  return (
    <div className={'e2e-go-to-park-page'}>
      <SidebarItem
        Icon={Envelope}
        title={l10n.naviEmailTemplate}
        route="/emails"
        selected={path.startsWith('/emails')}
      />
    </div>
  );
};

export default EmailsItem;
