import * as React from 'react';
import { l10n } from '../../../l10n';
import { useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import { ChartPie } from 'phosphor-react';

const DashboardItem: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();
  return <SidebarItem Icon={ChartPie} title={l10n.naviDashboard} route="/" selected={path === '/'} />;
};

export default DashboardItem;
