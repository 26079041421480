import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Unit = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
`;

export default Unit;
