import * as React from 'react';
import { l10n } from '../../../l10n';
import { useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import { ListBullets } from 'phosphor-react';

const TasksItem: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();
  return <SidebarItem Icon={ListBullets} title={l10n.naviTasks} route="/tasks" selected={path.startsWith('/task')} />;
};

export default TasksItem;
