"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               unknown
// source: devices/v2/device.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.BorderRouterData_HealthState = exports.BorderRouterData = exports.SensorData_Humidity = exports.SensorData_Temperature = exports.SensorData_MotionSensor = exports.SensorData_SmokeAlarm = exports.SensorData = exports.SmartMeterData_Errors = exports.SmartMeterData_EnergyConsumption = exports.SmartMeterData_PowerConsumption = exports.SmartMeterData_PowerState = exports.SmartMeterData = exports.LockData_State = exports.LockData = exports.Device = exports.borderRouterHealthStateToNumber = exports.borderRouterHealthStateToJSON = exports.borderRouterHealthStateFromJSON = exports.BorderRouterHealthState = exports.smokeAlarmStateToNumber = exports.smokeAlarmStateToJSON = exports.smokeAlarmStateFromJSON = exports.SmokeAlarmState = exports.smartMeterErrorToNumber = exports.smartMeterErrorToJSON = exports.smartMeterErrorFromJSON = exports.SmartMeterError = exports.smartMeterPowerStateToNumber = exports.smartMeterPowerStateToJSON = exports.smartMeterPowerStateFromJSON = exports.SmartMeterPowerState = exports.lockStateToNumber = exports.lockStateToJSON = exports.lockStateFromJSON = exports.LockState = exports.deviceTypeToNumber = exports.deviceTypeToJSON = exports.deviceTypeFromJSON = exports.DeviceType = exports.protobufPackage = void 0;
/* eslint-disable */
const wire_1 = require("@bufbuild/protobuf/wire");
const timestamp_1 = require("../../google/protobuf/timestamp");
exports.protobufPackage = "devices.v2";
var DeviceType;
(function (DeviceType) {
    DeviceType["DEVICE_TYPE_UNSPECIFIED"] = "DEVICE_TYPE_UNSPECIFIED";
    DeviceType["DEVICE_TYPE_LOCK"] = "DEVICE_TYPE_LOCK";
    DeviceType["DEVICE_TYPE_SENSOR"] = "DEVICE_TYPE_SENSOR";
    DeviceType["DEVICE_TYPE_SMART_METER"] = "DEVICE_TYPE_SMART_METER";
    DeviceType["DEVICE_TYPE_LEGACY_LOCK"] = "DEVICE_TYPE_LEGACY_LOCK";
    DeviceType["DEVICE_TYPE_LEGACY_SENSOR"] = "DEVICE_TYPE_LEGACY_SENSOR";
    DeviceType["DEVICE_TYPE_AXIS_SPEAKER"] = "DEVICE_TYPE_AXIS_SPEAKER";
    DeviceType["DEVICE_TYPE_LOCK_V3"] = "DEVICE_TYPE_LOCK_V3";
    DeviceType["DEVICE_TYPE_SENSOR_V3"] = "DEVICE_TYPE_SENSOR_V3";
    DeviceType["DEVICE_TYPE_BORDER_ROUTER"] = "DEVICE_TYPE_BORDER_ROUTER";
    DeviceType["UNRECOGNIZED"] = "UNRECOGNIZED";
})(DeviceType || (exports.DeviceType = DeviceType = {}));
function deviceTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "DEVICE_TYPE_UNSPECIFIED":
            return DeviceType.DEVICE_TYPE_UNSPECIFIED;
        case 1:
        case "DEVICE_TYPE_LOCK":
            return DeviceType.DEVICE_TYPE_LOCK;
        case 2:
        case "DEVICE_TYPE_SENSOR":
            return DeviceType.DEVICE_TYPE_SENSOR;
        case 3:
        case "DEVICE_TYPE_SMART_METER":
            return DeviceType.DEVICE_TYPE_SMART_METER;
        case 4:
        case "DEVICE_TYPE_LEGACY_LOCK":
            return DeviceType.DEVICE_TYPE_LEGACY_LOCK;
        case 5:
        case "DEVICE_TYPE_LEGACY_SENSOR":
            return DeviceType.DEVICE_TYPE_LEGACY_SENSOR;
        case 6:
        case "DEVICE_TYPE_AXIS_SPEAKER":
            return DeviceType.DEVICE_TYPE_AXIS_SPEAKER;
        case 7:
        case "DEVICE_TYPE_LOCK_V3":
            return DeviceType.DEVICE_TYPE_LOCK_V3;
        case 8:
        case "DEVICE_TYPE_SENSOR_V3":
            return DeviceType.DEVICE_TYPE_SENSOR_V3;
        case 9:
        case "DEVICE_TYPE_BORDER_ROUTER":
            return DeviceType.DEVICE_TYPE_BORDER_ROUTER;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DeviceType.UNRECOGNIZED;
    }
}
exports.deviceTypeFromJSON = deviceTypeFromJSON;
function deviceTypeToJSON(object) {
    switch (object) {
        case DeviceType.DEVICE_TYPE_UNSPECIFIED:
            return "DEVICE_TYPE_UNSPECIFIED";
        case DeviceType.DEVICE_TYPE_LOCK:
            return "DEVICE_TYPE_LOCK";
        case DeviceType.DEVICE_TYPE_SENSOR:
            return "DEVICE_TYPE_SENSOR";
        case DeviceType.DEVICE_TYPE_SMART_METER:
            return "DEVICE_TYPE_SMART_METER";
        case DeviceType.DEVICE_TYPE_LEGACY_LOCK:
            return "DEVICE_TYPE_LEGACY_LOCK";
        case DeviceType.DEVICE_TYPE_LEGACY_SENSOR:
            return "DEVICE_TYPE_LEGACY_SENSOR";
        case DeviceType.DEVICE_TYPE_AXIS_SPEAKER:
            return "DEVICE_TYPE_AXIS_SPEAKER";
        case DeviceType.DEVICE_TYPE_LOCK_V3:
            return "DEVICE_TYPE_LOCK_V3";
        case DeviceType.DEVICE_TYPE_SENSOR_V3:
            return "DEVICE_TYPE_SENSOR_V3";
        case DeviceType.DEVICE_TYPE_BORDER_ROUTER:
            return "DEVICE_TYPE_BORDER_ROUTER";
        case DeviceType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.deviceTypeToJSON = deviceTypeToJSON;
function deviceTypeToNumber(object) {
    switch (object) {
        case DeviceType.DEVICE_TYPE_UNSPECIFIED:
            return 0;
        case DeviceType.DEVICE_TYPE_LOCK:
            return 1;
        case DeviceType.DEVICE_TYPE_SENSOR:
            return 2;
        case DeviceType.DEVICE_TYPE_SMART_METER:
            return 3;
        case DeviceType.DEVICE_TYPE_LEGACY_LOCK:
            return 4;
        case DeviceType.DEVICE_TYPE_LEGACY_SENSOR:
            return 5;
        case DeviceType.DEVICE_TYPE_AXIS_SPEAKER:
            return 6;
        case DeviceType.DEVICE_TYPE_LOCK_V3:
            return 7;
        case DeviceType.DEVICE_TYPE_SENSOR_V3:
            return 8;
        case DeviceType.DEVICE_TYPE_BORDER_ROUTER:
            return 9;
        case DeviceType.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.deviceTypeToNumber = deviceTypeToNumber;
var LockState;
(function (LockState) {
    LockState["LOCK_STATE_UNSPECIFIED"] = "LOCK_STATE_UNSPECIFIED";
    LockState["LOCK_STATE_LOCKED"] = "LOCK_STATE_LOCKED";
    LockState["LOCK_STATE_UNLOCKED"] = "LOCK_STATE_UNLOCKED";
    LockState["LOCK_STATE_OPENING_STALLED"] = "LOCK_STATE_OPENING_STALLED";
    LockState["LOCK_STATE_CLOSING_STALLED"] = "LOCK_STATE_CLOSING_STALLED";
    LockState["LOCK_STATE_NO_SERVO"] = "LOCK_STATE_NO_SERVO";
    LockState["UNRECOGNIZED"] = "UNRECOGNIZED";
})(LockState || (exports.LockState = LockState = {}));
function lockStateFromJSON(object) {
    switch (object) {
        case 0:
        case "LOCK_STATE_UNSPECIFIED":
            return LockState.LOCK_STATE_UNSPECIFIED;
        case 1:
        case "LOCK_STATE_LOCKED":
            return LockState.LOCK_STATE_LOCKED;
        case 2:
        case "LOCK_STATE_UNLOCKED":
            return LockState.LOCK_STATE_UNLOCKED;
        case 3:
        case "LOCK_STATE_OPENING_STALLED":
            return LockState.LOCK_STATE_OPENING_STALLED;
        case 4:
        case "LOCK_STATE_CLOSING_STALLED":
            return LockState.LOCK_STATE_CLOSING_STALLED;
        case 5:
        case "LOCK_STATE_NO_SERVO":
            return LockState.LOCK_STATE_NO_SERVO;
        case -1:
        case "UNRECOGNIZED":
        default:
            return LockState.UNRECOGNIZED;
    }
}
exports.lockStateFromJSON = lockStateFromJSON;
function lockStateToJSON(object) {
    switch (object) {
        case LockState.LOCK_STATE_UNSPECIFIED:
            return "LOCK_STATE_UNSPECIFIED";
        case LockState.LOCK_STATE_LOCKED:
            return "LOCK_STATE_LOCKED";
        case LockState.LOCK_STATE_UNLOCKED:
            return "LOCK_STATE_UNLOCKED";
        case LockState.LOCK_STATE_OPENING_STALLED:
            return "LOCK_STATE_OPENING_STALLED";
        case LockState.LOCK_STATE_CLOSING_STALLED:
            return "LOCK_STATE_CLOSING_STALLED";
        case LockState.LOCK_STATE_NO_SERVO:
            return "LOCK_STATE_NO_SERVO";
        case LockState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.lockStateToJSON = lockStateToJSON;
function lockStateToNumber(object) {
    switch (object) {
        case LockState.LOCK_STATE_UNSPECIFIED:
            return 0;
        case LockState.LOCK_STATE_LOCKED:
            return 1;
        case LockState.LOCK_STATE_UNLOCKED:
            return 2;
        case LockState.LOCK_STATE_OPENING_STALLED:
            return 3;
        case LockState.LOCK_STATE_CLOSING_STALLED:
            return 4;
        case LockState.LOCK_STATE_NO_SERVO:
            return 5;
        case LockState.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.lockStateToNumber = lockStateToNumber;
var SmartMeterPowerState;
(function (SmartMeterPowerState) {
    SmartMeterPowerState["SMART_METER_POWER_STATE_UNSPECIFIED"] = "SMART_METER_POWER_STATE_UNSPECIFIED";
    SmartMeterPowerState["SMART_METER_POWER_STATE_ON"] = "SMART_METER_POWER_STATE_ON";
    SmartMeterPowerState["SMART_METER_POWER_STATE_OFF"] = "SMART_METER_POWER_STATE_OFF";
    SmartMeterPowerState["UNRECOGNIZED"] = "UNRECOGNIZED";
})(SmartMeterPowerState || (exports.SmartMeterPowerState = SmartMeterPowerState = {}));
function smartMeterPowerStateFromJSON(object) {
    switch (object) {
        case 0:
        case "SMART_METER_POWER_STATE_UNSPECIFIED":
            return SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED;
        case 1:
        case "SMART_METER_POWER_STATE_ON":
            return SmartMeterPowerState.SMART_METER_POWER_STATE_ON;
        case 2:
        case "SMART_METER_POWER_STATE_OFF":
            return SmartMeterPowerState.SMART_METER_POWER_STATE_OFF;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SmartMeterPowerState.UNRECOGNIZED;
    }
}
exports.smartMeterPowerStateFromJSON = smartMeterPowerStateFromJSON;
function smartMeterPowerStateToJSON(object) {
    switch (object) {
        case SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED:
            return "SMART_METER_POWER_STATE_UNSPECIFIED";
        case SmartMeterPowerState.SMART_METER_POWER_STATE_ON:
            return "SMART_METER_POWER_STATE_ON";
        case SmartMeterPowerState.SMART_METER_POWER_STATE_OFF:
            return "SMART_METER_POWER_STATE_OFF";
        case SmartMeterPowerState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.smartMeterPowerStateToJSON = smartMeterPowerStateToJSON;
function smartMeterPowerStateToNumber(object) {
    switch (object) {
        case SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED:
            return 0;
        case SmartMeterPowerState.SMART_METER_POWER_STATE_ON:
            return 1;
        case SmartMeterPowerState.SMART_METER_POWER_STATE_OFF:
            return 2;
        case SmartMeterPowerState.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.smartMeterPowerStateToNumber = smartMeterPowerStateToNumber;
var SmartMeterError;
(function (SmartMeterError) {
    SmartMeterError["SMART_METER_ERROR_UNSPECIFIED"] = "SMART_METER_ERROR_UNSPECIFIED";
    SmartMeterError["SMART_METER_ERROR_OVERPOWER"] = "SMART_METER_ERROR_OVERPOWER";
    SmartMeterError["SMART_METER_ERROR_OVERVOLTAGE"] = "SMART_METER_ERROR_OVERVOLTAGE";
    SmartMeterError["SMART_METER_ERROR_UNDERVOLTAGE"] = "SMART_METER_ERROR_UNDERVOLTAGE";
    SmartMeterError["SMART_METER_ERROR_OVERCURRENT"] = "SMART_METER_ERROR_OVERCURRENT";
    SmartMeterError["SMART_METER_ERROR_OVERTEMP"] = "SMART_METER_ERROR_OVERTEMP";
    SmartMeterError["UNRECOGNIZED"] = "UNRECOGNIZED";
})(SmartMeterError || (exports.SmartMeterError = SmartMeterError = {}));
function smartMeterErrorFromJSON(object) {
    switch (object) {
        case 0:
        case "SMART_METER_ERROR_UNSPECIFIED":
            return SmartMeterError.SMART_METER_ERROR_UNSPECIFIED;
        case 1:
        case "SMART_METER_ERROR_OVERPOWER":
            return SmartMeterError.SMART_METER_ERROR_OVERPOWER;
        case 2:
        case "SMART_METER_ERROR_OVERVOLTAGE":
            return SmartMeterError.SMART_METER_ERROR_OVERVOLTAGE;
        case 3:
        case "SMART_METER_ERROR_UNDERVOLTAGE":
            return SmartMeterError.SMART_METER_ERROR_UNDERVOLTAGE;
        case 4:
        case "SMART_METER_ERROR_OVERCURRENT":
            return SmartMeterError.SMART_METER_ERROR_OVERCURRENT;
        case 5:
        case "SMART_METER_ERROR_OVERTEMP":
            return SmartMeterError.SMART_METER_ERROR_OVERTEMP;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SmartMeterError.UNRECOGNIZED;
    }
}
exports.smartMeterErrorFromJSON = smartMeterErrorFromJSON;
function smartMeterErrorToJSON(object) {
    switch (object) {
        case SmartMeterError.SMART_METER_ERROR_UNSPECIFIED:
            return "SMART_METER_ERROR_UNSPECIFIED";
        case SmartMeterError.SMART_METER_ERROR_OVERPOWER:
            return "SMART_METER_ERROR_OVERPOWER";
        case SmartMeterError.SMART_METER_ERROR_OVERVOLTAGE:
            return "SMART_METER_ERROR_OVERVOLTAGE";
        case SmartMeterError.SMART_METER_ERROR_UNDERVOLTAGE:
            return "SMART_METER_ERROR_UNDERVOLTAGE";
        case SmartMeterError.SMART_METER_ERROR_OVERCURRENT:
            return "SMART_METER_ERROR_OVERCURRENT";
        case SmartMeterError.SMART_METER_ERROR_OVERTEMP:
            return "SMART_METER_ERROR_OVERTEMP";
        case SmartMeterError.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.smartMeterErrorToJSON = smartMeterErrorToJSON;
function smartMeterErrorToNumber(object) {
    switch (object) {
        case SmartMeterError.SMART_METER_ERROR_UNSPECIFIED:
            return 0;
        case SmartMeterError.SMART_METER_ERROR_OVERPOWER:
            return 1;
        case SmartMeterError.SMART_METER_ERROR_OVERVOLTAGE:
            return 2;
        case SmartMeterError.SMART_METER_ERROR_UNDERVOLTAGE:
            return 3;
        case SmartMeterError.SMART_METER_ERROR_OVERCURRENT:
            return 4;
        case SmartMeterError.SMART_METER_ERROR_OVERTEMP:
            return 5;
        case SmartMeterError.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.smartMeterErrorToNumber = smartMeterErrorToNumber;
var SmokeAlarmState;
(function (SmokeAlarmState) {
    SmokeAlarmState["SENSOR_STATUS_UNSPECIFIED"] = "SENSOR_STATUS_UNSPECIFIED";
    SmokeAlarmState["SENSOR_STATUS_MUTED"] = "SENSOR_STATUS_MUTED";
    SmokeAlarmState["SENSOR_STATUS_UNMUTED"] = "SENSOR_STATUS_UNMUTED";
    SmokeAlarmState["UNRECOGNIZED"] = "UNRECOGNIZED";
})(SmokeAlarmState || (exports.SmokeAlarmState = SmokeAlarmState = {}));
function smokeAlarmStateFromJSON(object) {
    switch (object) {
        case 0:
        case "SENSOR_STATUS_UNSPECIFIED":
            return SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED;
        case 1:
        case "SENSOR_STATUS_MUTED":
            return SmokeAlarmState.SENSOR_STATUS_MUTED;
        case 2:
        case "SENSOR_STATUS_UNMUTED":
            return SmokeAlarmState.SENSOR_STATUS_UNMUTED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SmokeAlarmState.UNRECOGNIZED;
    }
}
exports.smokeAlarmStateFromJSON = smokeAlarmStateFromJSON;
function smokeAlarmStateToJSON(object) {
    switch (object) {
        case SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED:
            return "SENSOR_STATUS_UNSPECIFIED";
        case SmokeAlarmState.SENSOR_STATUS_MUTED:
            return "SENSOR_STATUS_MUTED";
        case SmokeAlarmState.SENSOR_STATUS_UNMUTED:
            return "SENSOR_STATUS_UNMUTED";
        case SmokeAlarmState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.smokeAlarmStateToJSON = smokeAlarmStateToJSON;
function smokeAlarmStateToNumber(object) {
    switch (object) {
        case SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED:
            return 0;
        case SmokeAlarmState.SENSOR_STATUS_MUTED:
            return 1;
        case SmokeAlarmState.SENSOR_STATUS_UNMUTED:
            return 2;
        case SmokeAlarmState.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.smokeAlarmStateToNumber = smokeAlarmStateToNumber;
var BorderRouterHealthState;
(function (BorderRouterHealthState) {
    BorderRouterHealthState["BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED"] = "BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED";
    BorderRouterHealthState["BORDER_ROUTER_HEALTH_STATE_HEALTHY"] = "BORDER_ROUTER_HEALTH_STATE_HEALTHY";
    BorderRouterHealthState["BORDER_ROUTER_HEALTH_STATE_UNHEALTHY"] = "BORDER_ROUTER_HEALTH_STATE_UNHEALTHY";
    BorderRouterHealthState["BORDER_ROUTER_HEALTH_STATE_GRACE"] = "BORDER_ROUTER_HEALTH_STATE_GRACE";
    BorderRouterHealthState["UNRECOGNIZED"] = "UNRECOGNIZED";
})(BorderRouterHealthState || (exports.BorderRouterHealthState = BorderRouterHealthState = {}));
function borderRouterHealthStateFromJSON(object) {
    switch (object) {
        case 0:
        case "BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED":
            return BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED;
        case 1:
        case "BORDER_ROUTER_HEALTH_STATE_HEALTHY":
            return BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_HEALTHY;
        case 2:
        case "BORDER_ROUTER_HEALTH_STATE_UNHEALTHY":
            return BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNHEALTHY;
        case 3:
        case "BORDER_ROUTER_HEALTH_STATE_GRACE":
            return BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_GRACE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return BorderRouterHealthState.UNRECOGNIZED;
    }
}
exports.borderRouterHealthStateFromJSON = borderRouterHealthStateFromJSON;
function borderRouterHealthStateToJSON(object) {
    switch (object) {
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED:
            return "BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED";
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_HEALTHY:
            return "BORDER_ROUTER_HEALTH_STATE_HEALTHY";
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNHEALTHY:
            return "BORDER_ROUTER_HEALTH_STATE_UNHEALTHY";
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_GRACE:
            return "BORDER_ROUTER_HEALTH_STATE_GRACE";
        case BorderRouterHealthState.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.borderRouterHealthStateToJSON = borderRouterHealthStateToJSON;
function borderRouterHealthStateToNumber(object) {
    switch (object) {
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED:
            return 0;
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_HEALTHY:
            return 1;
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNHEALTHY:
            return 2;
        case BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_GRACE:
            return 3;
        case BorderRouterHealthState.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.borderRouterHealthStateToNumber = borderRouterHealthStateToNumber;
function createBaseDevice() {
    return {
        deviceId: "",
        type: DeviceType.DEVICE_TYPE_UNSPECIFIED,
        name: "",
        storageUnitId: undefined,
        isActive: false,
        createdTime: undefined,
        lockData: undefined,
        smartMeterData: undefined,
        sensorData: undefined,
        borderRouterData: undefined,
        parkId: undefined,
    };
}
exports.Device = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.deviceId !== "") {
            writer.uint32(10).string(message.deviceId);
        }
        if (message.type !== DeviceType.DEVICE_TYPE_UNSPECIFIED) {
            writer.uint32(16).int32(deviceTypeToNumber(message.type));
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.storageUnitId !== undefined) {
            writer.uint32(34).string(message.storageUnitId);
        }
        if (message.isActive !== false) {
            writer.uint32(40).bool(message.isActive);
        }
        if (message.createdTime !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.createdTime), writer.uint32(50).fork()).join();
        }
        if (message.lockData !== undefined) {
            exports.LockData.encode(message.lockData, writer.uint32(58).fork()).join();
        }
        if (message.smartMeterData !== undefined) {
            exports.SmartMeterData.encode(message.smartMeterData, writer.uint32(66).fork()).join();
        }
        if (message.sensorData !== undefined) {
            exports.SensorData.encode(message.sensorData, writer.uint32(74).fork()).join();
        }
        if (message.borderRouterData !== undefined) {
            exports.BorderRouterData.encode(message.borderRouterData, writer.uint32(82).fork()).join();
        }
        if (message.parkId !== undefined) {
            writer.uint32(90).string(message.parkId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDevice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.deviceId = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.type = deviceTypeFromJSON(reader.int32());
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                }
                case 4: {
                    if (tag !== 34) {
                        break;
                    }
                    message.storageUnitId = reader.string();
                    continue;
                }
                case 5: {
                    if (tag !== 40) {
                        break;
                    }
                    message.isActive = reader.bool();
                    continue;
                }
                case 6: {
                    if (tag !== 50) {
                        break;
                    }
                    message.createdTime = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 7: {
                    if (tag !== 58) {
                        break;
                    }
                    message.lockData = exports.LockData.decode(reader, reader.uint32());
                    continue;
                }
                case 8: {
                    if (tag !== 66) {
                        break;
                    }
                    message.smartMeterData = exports.SmartMeterData.decode(reader, reader.uint32());
                    continue;
                }
                case 9: {
                    if (tag !== 74) {
                        break;
                    }
                    message.sensorData = exports.SensorData.decode(reader, reader.uint32());
                    continue;
                }
                case 10: {
                    if (tag !== 82) {
                        break;
                    }
                    message.borderRouterData = exports.BorderRouterData.decode(reader, reader.uint32());
                    continue;
                }
                case 11: {
                    if (tag !== 90) {
                        break;
                    }
                    message.parkId = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
            type: isSet(object.type) ? deviceTypeFromJSON(object.type) : DeviceType.DEVICE_TYPE_UNSPECIFIED,
            name: isSet(object.name) ? globalThis.String(object.name) : "",
            storageUnitId: isSet(object.storageUnitId) ? globalThis.String(object.storageUnitId) : undefined,
            isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
            createdTime: isSet(object.createdTime) ? fromJsonTimestamp(object.createdTime) : undefined,
            lockData: isSet(object.lockData) ? exports.LockData.fromJSON(object.lockData) : undefined,
            smartMeterData: isSet(object.smartMeterData) ? exports.SmartMeterData.fromJSON(object.smartMeterData) : undefined,
            sensorData: isSet(object.sensorData) ? exports.SensorData.fromJSON(object.sensorData) : undefined,
            borderRouterData: isSet(object.borderRouterData) ? exports.BorderRouterData.fromJSON(object.borderRouterData) : undefined,
            parkId: isSet(object.parkId) ? globalThis.String(object.parkId) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.deviceId !== "") {
            obj.deviceId = message.deviceId;
        }
        if (message.type !== DeviceType.DEVICE_TYPE_UNSPECIFIED) {
            obj.type = deviceTypeToJSON(message.type);
        }
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.storageUnitId !== undefined) {
            obj.storageUnitId = message.storageUnitId;
        }
        if (message.isActive !== false) {
            obj.isActive = message.isActive;
        }
        if (message.createdTime !== undefined) {
            obj.createdTime = message.createdTime.toISOString();
        }
        if (message.lockData !== undefined) {
            obj.lockData = exports.LockData.toJSON(message.lockData);
        }
        if (message.smartMeterData !== undefined) {
            obj.smartMeterData = exports.SmartMeterData.toJSON(message.smartMeterData);
        }
        if (message.sensorData !== undefined) {
            obj.sensorData = exports.SensorData.toJSON(message.sensorData);
        }
        if (message.borderRouterData !== undefined) {
            obj.borderRouterData = exports.BorderRouterData.toJSON(message.borderRouterData);
        }
        if (message.parkId !== undefined) {
            obj.parkId = message.parkId;
        }
        return obj;
    },
    create(base) {
        return exports.Device.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDevice();
        message.deviceId = object.deviceId ?? "";
        message.type = object.type ?? DeviceType.DEVICE_TYPE_UNSPECIFIED;
        message.name = object.name ?? "";
        message.storageUnitId = object.storageUnitId ?? undefined;
        message.isActive = object.isActive ?? false;
        message.createdTime = object.createdTime ?? undefined;
        message.lockData = (object.lockData !== undefined && object.lockData !== null)
            ? exports.LockData.fromPartial(object.lockData)
            : undefined;
        message.smartMeterData = (object.smartMeterData !== undefined && object.smartMeterData !== null)
            ? exports.SmartMeterData.fromPartial(object.smartMeterData)
            : undefined;
        message.sensorData = (object.sensorData !== undefined && object.sensorData !== null)
            ? exports.SensorData.fromPartial(object.sensorData)
            : undefined;
        message.borderRouterData = (object.borderRouterData !== undefined && object.borderRouterData !== null)
            ? exports.BorderRouterData.fromPartial(object.borderRouterData)
            : undefined;
        message.parkId = object.parkId ?? undefined;
        return message;
    },
};
function createBaseLockData() {
    return { state: undefined };
}
exports.LockData = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.state !== undefined) {
            exports.LockData_State.encode(message.state, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLockData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.state = exports.LockData_State.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { state: isSet(object.state) ? exports.LockData_State.fromJSON(object.state) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.state !== undefined) {
            obj.state = exports.LockData_State.toJSON(message.state);
        }
        return obj;
    },
    create(base) {
        return exports.LockData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLockData();
        message.state = (object.state !== undefined && object.state !== null)
            ? exports.LockData_State.fromPartial(object.state)
            : undefined;
        return message;
    },
};
function createBaseLockData_State() {
    return { timestamp: undefined, value: LockState.LOCK_STATE_UNSPECIFIED };
}
exports.LockData_State = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== LockState.LOCK_STATE_UNSPECIFIED) {
            writer.uint32(16).int32(lockStateToNumber(message.value));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLockData_State();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.value = lockStateFromJSON(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? lockStateFromJSON(object.value) : LockState.LOCK_STATE_UNSPECIFIED,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== LockState.LOCK_STATE_UNSPECIFIED) {
            obj.value = lockStateToJSON(message.value);
        }
        return obj;
    },
    create(base) {
        return exports.LockData_State.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLockData_State();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? LockState.LOCK_STATE_UNSPECIFIED;
        return message;
    },
};
function createBaseSmartMeterData() {
    return { powerState: undefined, powerConsumption: undefined, errors: undefined, energyConsumption: undefined };
}
exports.SmartMeterData = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.powerState !== undefined) {
            exports.SmartMeterData_PowerState.encode(message.powerState, writer.uint32(10).fork()).join();
        }
        if (message.powerConsumption !== undefined) {
            exports.SmartMeterData_PowerConsumption.encode(message.powerConsumption, writer.uint32(18).fork()).join();
        }
        if (message.errors !== undefined) {
            exports.SmartMeterData_Errors.encode(message.errors, writer.uint32(26).fork()).join();
        }
        if (message.energyConsumption !== undefined) {
            exports.SmartMeterData_EnergyConsumption.encode(message.energyConsumption, writer.uint32(34).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSmartMeterData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.powerState = exports.SmartMeterData_PowerState.decode(reader, reader.uint32());
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.powerConsumption = exports.SmartMeterData_PowerConsumption.decode(reader, reader.uint32());
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.errors = exports.SmartMeterData_Errors.decode(reader, reader.uint32());
                    continue;
                }
                case 4: {
                    if (tag !== 34) {
                        break;
                    }
                    message.energyConsumption = exports.SmartMeterData_EnergyConsumption.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            powerState: isSet(object.powerState) ? exports.SmartMeterData_PowerState.fromJSON(object.powerState) : undefined,
            powerConsumption: isSet(object.powerConsumption)
                ? exports.SmartMeterData_PowerConsumption.fromJSON(object.powerConsumption)
                : undefined,
            errors: isSet(object.errors) ? exports.SmartMeterData_Errors.fromJSON(object.errors) : undefined,
            energyConsumption: isSet(object.energyConsumption)
                ? exports.SmartMeterData_EnergyConsumption.fromJSON(object.energyConsumption)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.powerState !== undefined) {
            obj.powerState = exports.SmartMeterData_PowerState.toJSON(message.powerState);
        }
        if (message.powerConsumption !== undefined) {
            obj.powerConsumption = exports.SmartMeterData_PowerConsumption.toJSON(message.powerConsumption);
        }
        if (message.errors !== undefined) {
            obj.errors = exports.SmartMeterData_Errors.toJSON(message.errors);
        }
        if (message.energyConsumption !== undefined) {
            obj.energyConsumption = exports.SmartMeterData_EnergyConsumption.toJSON(message.energyConsumption);
        }
        return obj;
    },
    create(base) {
        return exports.SmartMeterData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSmartMeterData();
        message.powerState = (object.powerState !== undefined && object.powerState !== null)
            ? exports.SmartMeterData_PowerState.fromPartial(object.powerState)
            : undefined;
        message.powerConsumption = (object.powerConsumption !== undefined && object.powerConsumption !== null)
            ? exports.SmartMeterData_PowerConsumption.fromPartial(object.powerConsumption)
            : undefined;
        message.errors = (object.errors !== undefined && object.errors !== null)
            ? exports.SmartMeterData_Errors.fromPartial(object.errors)
            : undefined;
        message.energyConsumption = (object.energyConsumption !== undefined && object.energyConsumption !== null)
            ? exports.SmartMeterData_EnergyConsumption.fromPartial(object.energyConsumption)
            : undefined;
        return message;
    },
};
function createBaseSmartMeterData_PowerState() {
    return { timestamp: undefined, value: SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED };
}
exports.SmartMeterData_PowerState = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED) {
            writer.uint32(16).int32(smartMeterPowerStateToNumber(message.value));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSmartMeterData_PowerState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.value = smartMeterPowerStateFromJSON(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value)
                ? smartMeterPowerStateFromJSON(object.value)
                : SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED) {
            obj.value = smartMeterPowerStateToJSON(message.value);
        }
        return obj;
    },
    create(base) {
        return exports.SmartMeterData_PowerState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSmartMeterData_PowerState();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? SmartMeterPowerState.SMART_METER_POWER_STATE_UNSPECIFIED;
        return message;
    },
};
function createBaseSmartMeterData_PowerConsumption() {
    return { timestamp: undefined, value: 0 };
}
exports.SmartMeterData_PowerConsumption = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== 0) {
            writer.uint32(17).double(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSmartMeterData_PowerConsumption();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 17) {
                        break;
                    }
                    message.value = reader.double();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? globalThis.Number(object.value) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== 0) {
            obj.value = message.value;
        }
        return obj;
    },
    create(base) {
        return exports.SmartMeterData_PowerConsumption.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSmartMeterData_PowerConsumption();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseSmartMeterData_EnergyConsumption() {
    return { timestamp: undefined, value: 0 };
}
exports.SmartMeterData_EnergyConsumption = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== 0) {
            writer.uint32(17).double(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSmartMeterData_EnergyConsumption();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 17) {
                        break;
                    }
                    message.value = reader.double();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? globalThis.Number(object.value) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== 0) {
            obj.value = message.value;
        }
        return obj;
    },
    create(base) {
        return exports.SmartMeterData_EnergyConsumption.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSmartMeterData_EnergyConsumption();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseSmartMeterData_Errors() {
    return { timestamp: undefined, value: [] };
}
exports.SmartMeterData_Errors = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        writer.uint32(18).fork();
        for (const v of message.value) {
            writer.int32(smartMeterErrorToNumber(v));
        }
        writer.join();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSmartMeterData_Errors();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag === 16) {
                        message.value.push(smartMeterErrorFromJSON(reader.int32()));
                        continue;
                    }
                    if (tag === 18) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.value.push(smartMeterErrorFromJSON(reader.int32()));
                        }
                        continue;
                    }
                    break;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: globalThis.Array.isArray(object?.value) ? object.value.map((e) => smartMeterErrorFromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value?.length) {
            obj.value = message.value.map((e) => smartMeterErrorToJSON(e));
        }
        return obj;
    },
    create(base) {
        return exports.SmartMeterData_Errors.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSmartMeterData_Errors();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value?.map((e) => e) || [];
        return message;
    },
};
function createBaseSensorData() {
    return { smokeAlarm: undefined, motionSensor: undefined, temperature: undefined, humidity: undefined };
}
exports.SensorData = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.smokeAlarm !== undefined) {
            exports.SensorData_SmokeAlarm.encode(message.smokeAlarm, writer.uint32(10).fork()).join();
        }
        if (message.motionSensor !== undefined) {
            exports.SensorData_MotionSensor.encode(message.motionSensor, writer.uint32(18).fork()).join();
        }
        if (message.temperature !== undefined) {
            exports.SensorData_Temperature.encode(message.temperature, writer.uint32(26).fork()).join();
        }
        if (message.humidity !== undefined) {
            exports.SensorData_Humidity.encode(message.humidity, writer.uint32(34).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSensorData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.smokeAlarm = exports.SensorData_SmokeAlarm.decode(reader, reader.uint32());
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.motionSensor = exports.SensorData_MotionSensor.decode(reader, reader.uint32());
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.temperature = exports.SensorData_Temperature.decode(reader, reader.uint32());
                    continue;
                }
                case 4: {
                    if (tag !== 34) {
                        break;
                    }
                    message.humidity = exports.SensorData_Humidity.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            smokeAlarm: isSet(object.smokeAlarm) ? exports.SensorData_SmokeAlarm.fromJSON(object.smokeAlarm) : undefined,
            motionSensor: isSet(object.motionSensor) ? exports.SensorData_MotionSensor.fromJSON(object.motionSensor) : undefined,
            temperature: isSet(object.temperature) ? exports.SensorData_Temperature.fromJSON(object.temperature) : undefined,
            humidity: isSet(object.humidity) ? exports.SensorData_Humidity.fromJSON(object.humidity) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.smokeAlarm !== undefined) {
            obj.smokeAlarm = exports.SensorData_SmokeAlarm.toJSON(message.smokeAlarm);
        }
        if (message.motionSensor !== undefined) {
            obj.motionSensor = exports.SensorData_MotionSensor.toJSON(message.motionSensor);
        }
        if (message.temperature !== undefined) {
            obj.temperature = exports.SensorData_Temperature.toJSON(message.temperature);
        }
        if (message.humidity !== undefined) {
            obj.humidity = exports.SensorData_Humidity.toJSON(message.humidity);
        }
        return obj;
    },
    create(base) {
        return exports.SensorData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSensorData();
        message.smokeAlarm = (object.smokeAlarm !== undefined && object.smokeAlarm !== null)
            ? exports.SensorData_SmokeAlarm.fromPartial(object.smokeAlarm)
            : undefined;
        message.motionSensor = (object.motionSensor !== undefined && object.motionSensor !== null)
            ? exports.SensorData_MotionSensor.fromPartial(object.motionSensor)
            : undefined;
        message.temperature = (object.temperature !== undefined && object.temperature !== null)
            ? exports.SensorData_Temperature.fromPartial(object.temperature)
            : undefined;
        message.humidity = (object.humidity !== undefined && object.humidity !== null)
            ? exports.SensorData_Humidity.fromPartial(object.humidity)
            : undefined;
        return message;
    },
};
function createBaseSensorData_SmokeAlarm() {
    return { timestamp: undefined, value: SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED };
}
exports.SensorData_SmokeAlarm = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED) {
            writer.uint32(16).int32(smokeAlarmStateToNumber(message.value));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSensorData_SmokeAlarm();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.value = smokeAlarmStateFromJSON(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? smokeAlarmStateFromJSON(object.value) : SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED) {
            obj.value = smokeAlarmStateToJSON(message.value);
        }
        return obj;
    },
    create(base) {
        return exports.SensorData_SmokeAlarm.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSensorData_SmokeAlarm();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? SmokeAlarmState.SENSOR_STATUS_UNSPECIFIED;
        return message;
    },
};
function createBaseSensorData_MotionSensor() {
    return { timestamp: undefined, value: false };
}
exports.SensorData_MotionSensor = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== false) {
            writer.uint32(16).bool(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSensorData_MotionSensor();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.value = reader.bool();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? globalThis.Boolean(object.value) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== false) {
            obj.value = message.value;
        }
        return obj;
    },
    create(base) {
        return exports.SensorData_MotionSensor.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSensorData_MotionSensor();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? false;
        return message;
    },
};
function createBaseSensorData_Temperature() {
    return { timestamp: undefined, value: 0 };
}
exports.SensorData_Temperature = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== 0) {
            writer.uint32(17).double(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSensorData_Temperature();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 17) {
                        break;
                    }
                    message.value = reader.double();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? globalThis.Number(object.value) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== 0) {
            obj.value = message.value;
        }
        return obj;
    },
    create(base) {
        return exports.SensorData_Temperature.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSensorData_Temperature();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseSensorData_Humidity() {
    return { timestamp: undefined, value: 0 };
}
exports.SensorData_Humidity = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== 0) {
            writer.uint32(17).double(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSensorData_Humidity();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 17) {
                        break;
                    }
                    message.value = reader.double();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? globalThis.Number(object.value) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== 0) {
            obj.value = message.value;
        }
        return obj;
    },
    create(base) {
        return exports.SensorData_Humidity.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSensorData_Humidity();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseBorderRouterData() {
    return { healthState: undefined };
}
exports.BorderRouterData = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.healthState !== undefined) {
            exports.BorderRouterData_HealthState.encode(message.healthState, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBorderRouterData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.healthState = exports.BorderRouterData_HealthState.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            healthState: isSet(object.healthState) ? exports.BorderRouterData_HealthState.fromJSON(object.healthState) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.healthState !== undefined) {
            obj.healthState = exports.BorderRouterData_HealthState.toJSON(message.healthState);
        }
        return obj;
    },
    create(base) {
        return exports.BorderRouterData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBorderRouterData();
        message.healthState = (object.healthState !== undefined && object.healthState !== null)
            ? exports.BorderRouterData_HealthState.fromPartial(object.healthState)
            : undefined;
        return message;
    },
};
function createBaseBorderRouterData_HealthState() {
    return { timestamp: undefined, value: BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED };
}
exports.BorderRouterData_HealthState = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED) {
            writer.uint32(16).int32(borderRouterHealthStateToNumber(message.value));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBorderRouterData_HealthState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.value = borderRouterHealthStateFromJSON(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value)
                ? borderRouterHealthStateFromJSON(object.value)
                : BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED) {
            obj.value = borderRouterHealthStateToJSON(message.value);
        }
        return obj;
    },
    create(base) {
        return exports.BorderRouterData_HealthState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBorderRouterData_HealthState();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? BorderRouterHealthState.BORDER_ROUTER_HEALTH_STATE_UNSPECIFIED;
        return message;
    },
};
function toTimestamp(date) {
    const seconds = Math.trunc(date.getTime() / 1000);
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
