import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { X } from 'phosphor-react';
import * as React from 'react';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { FormEvent, useState } from 'react';
import LoadingIcon from '../../../components/LoadingIcon';
import { revokeKey } from '../../../gateway/storageUnitKeys/storageUnitKeysGateway';
import { KeyAndTenant, RentalEntry } from '../../../context/RentalManagementContext';

const UnitRentalManagementKeyRevokeDialog: React.FC<{
  closeDialog: () => void;
  keyAndTenant: KeyAndTenant;
  rental: RentalEntry;
  storageUnitId: string;
}> = ({ closeDialog, keyAndTenant, rental, storageUnitId }) => {
  const { toaster } = useToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await revokeKey(storageUnitId, keyAndTenant.id);
      toaster('Action Successful', 'Key has been revoked.', ToastMessageTypes.SUCCESS);
      closeDialog();
    } catch (e) {
      setLoading(false);
      toaster('Error Occurred', 'Unable to revoke key.', ToastMessageTypes.ERROR);
    }
    return false;
  };

  const onCancel = () => closeDialog();

  return (
    <>
      <Dialog open={true}>
        <form onSubmit={onSubmit}>
          <DialogTitle variant="h2">
            Revoke Key
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 12,
                top: 14,
              }}
            >
              {<X />}
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4}>
              <Grid item xs={1}>
                <WarningAmberIcon color="error" fontSize="large" />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{ fontWeight: 700 }}>
                  Are you sure you want to revoke the key for {keyAndTenant.tenant?.firstName}{' '}
                  {keyAndTenant.tenant?.lastName}?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {keyAndTenant.tenantId === rental.tenantId ? (
                  <Typography color="error" sx={{ fontWeight: 700 }}>
                    This tenant is the rental owner.
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="error"
              type="submit"
              disabled={loading}
              style={{
                width: 150,
              }}
            >
              {loading ? <LoadingIcon /> : 'Revoke key'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UnitRentalManagementKeyRevokeDialog;
