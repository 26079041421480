import { SvgIcon } from '@mui/material';
import * as React from 'react';

const IvyIcon: React.FC = props => {
  return (
    <SvgIcon {...props} width="49" height="53" viewBox="0 0 49 53" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.316 13.3272L27.5003 5.35072C25.6439 4.27893 23.3567 4.27893 21.5003 5.35072L7.68457 13.3272C5.82816 14.399 4.68457 16.3798 4.68457 18.5234V34.4764C4.68457 36.62 5.82816 38.6007 7.68457 39.6725L21.5003 47.649C23.3567 48.7208 25.6439 48.7208 27.5003 47.649L41.316 39.6725C43.1724 38.6007 44.316 36.62 44.316 34.4764V18.5234C44.316 16.3798 43.1724 14.399 41.316 13.3272ZM29.5003 1.88662C26.4063 0.100293 22.5943 0.100293 19.5003 1.88662L5.68457 9.86312C2.59056 11.6494 0.68457 14.9507 0.68457 18.5234V34.4764C0.68457 38.049 2.59056 41.3503 5.68457 43.1366L19.5003 51.1131C22.5943 52.8994 26.4063 52.8994 29.5003 51.1131L43.316 43.1366C46.41 41.3503 48.316 38.049 48.316 34.4764V18.5234C48.316 14.9507 46.41 11.6494 43.316 9.86312L29.5003 1.88662Z"
        fill="#3576F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5003 11.7499C25.4668 11.7499 26.2503 12.5334 26.2503 13.4999V23.4331L34.61 18.4932C35.4421 18.0016 36.5152 18.2775 37.0069 19.1096C37.4986 19.9417 37.2226 21.0148 36.3905 21.5065L27.9402 26.4999L36.3905 31.4932C37.2226 31.9849 37.4986 33.0581 37.0069 33.8901C36.5152 34.7222 35.4421 34.9982 34.61 34.5065L24.5003 28.5326L14.3905 34.5065C13.5585 34.9982 12.4853 34.7222 11.9936 33.8901C11.502 33.0581 11.7779 31.9849 12.61 31.4932L21.0603 26.4999L12.61 21.5065C11.7779 21.0148 11.502 19.9417 11.9936 19.1096C12.4853 18.2775 13.5585 18.0016 14.3905 18.4932L22.7503 23.4331V13.4999C22.7503 12.5334 23.5338 11.7499 24.5003 11.7499Z"
        fill="#3576F4"
      />
    </SvgIcon>
  );
};

export default IvyIcon;
