import { Box, CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LockComponent } from './devices/LockDevice';
import AuthContext from '../../context/AuthContext';
import Tile from './devices/card/Tile';
import IconContainer from './devices/card/IconContainer';
import Value from './devices/card/Value';
import { Drop, ListPlus, Thermometer } from 'phosphor-react';
import { colors } from '../../theming/colors';
import Label from './devices/card/Label';
import { SensorComponent } from './devices/SensorDevice';
import StorageUnitDetailContext from '../../context/StorageUnitDetailContext';
import { SmartMeterComponent } from './devices/SmartMeterDevice';
import { Device, DeviceType, LockState } from '@ivy/proto/dist/devices/v2/device';

interface Props {
  parkId: string;
  storageUnitId: string;
  role?: string;
}

const UnitModulesSection: React.FC<Props> = ({ parkId, storageUnitId, role }) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { storageUnitDevices: components, setSelectedDevice, storageUnit } = useContext(StorageUnitDetailContext);
  const [componentsLoading, setComponentsLoading] = useState(storageUnit.thingsboardId ? true : false);

  useEffect(() => {
    if (components) {
      setComponentsLoading(false);
    }
  }, [components]);

  const openPowerDialog = (component: Device) => {
    setSelectedDevice(component);
    navigate(`/park/${parkId}/unit/${storageUnitId}/power/${component.deviceId}`);
  };
  const openUnlockDialog = (component: string) => {
    if (role && role === 'tenant') {
      navigate(`/units/${storageUnitId}/${component}`);
    } else {
      navigate(`/park/${parkId}/unit/${storageUnitId}/lock/${component}`);
    }
  };
  const openTemperatureDialog = (component: Device) => {
    setSelectedDevice(component);
    navigate(`/park/${parkId}/unit/${storageUnitId}/temperature/${component.deviceId}`);
  };
  const openHumidityDialog = (component: Device) => {
    setSelectedDevice(component);
    navigate(`/park/${parkId}/unit/${storageUnitId}/humidity/${component.deviceId}`);
  };
  const openAddDeviceDialog = () => {
    navigate(`/park/${parkId}/unit/${storageUnitId}/devices/edit`);
  };

  return (
    <Box
      sx={{
        py: 2,
        ...(!storageUnit.thingsboardId
          ? {
              opacity: 0.3,
              pointerEvents: 'none',
            }
          : {}),
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Devices {componentsLoading && <CircularProgress size={15} sx={{ marginLeft: 1 }} />}
      </Typography>
      <>
        {typeof components === 'string' ? (
          <Typography color={colors.signalAlert}>{components}</Typography>
        ) : (
          components?.flatMap((component, i) => {
            if (component.type === DeviceType.DEVICE_TYPE_SMART_METER) {
              return (
                <SmartMeterComponent
                  className={'e2e-shows-power'}
                  key={component.deviceId}
                  unit="W"
                  label="Power consumption"
                  onClick={() => openPowerDialog(component)}
                  active={component.isActive}
                  telemetry={component.smartMeterData}
                />
              );
            } else if (
              component.type === DeviceType.DEVICE_TYPE_LOCK ||
              component.type === DeviceType.DEVICE_TYPE_LOCK_V3 ||
              component.type === DeviceType.DEVICE_TYPE_LEGACY_LOCK
            ) {
              const isUnlocked = component.lockData?.state?.value === LockState.LOCK_STATE_UNLOCKED;
              return (
                <LockComponent
                  key={i}
                  status={isUnlocked}
                  label={isUnlocked ? 'Unlocked' : 'Locked'}
                  onClick={() => openUnlockDialog(component.deviceId)}
                  active={component.isActive}
                />
              );
            } else if (
              component.type === DeviceType.DEVICE_TYPE_SENSOR ||
              component.type === DeviceType.DEVICE_TYPE_SENSOR_V3 ||
              component.type === DeviceType.DEVICE_TYPE_LEGACY_SENSOR
            ) {
              let temperature = component.sensorData?.temperature;
              let humidity = component.sensorData?.humidity;
              return [
                <SensorComponent
                  className={'e2e-shows-temperature'}
                  key={component.deviceId}
                  icon={<Thermometer size={20} />}
                  value={`${temperature?.value.toFixed(1) ?? '-'}`}
                  unit="°C"
                  label="Temperature"
                  onClick={() => openTemperatureDialog(component)}
                  active={component.isActive}
                />,
                <SensorComponent
                  className={'e2e-shows-humidity'}
                  key={component.deviceId + '1'}
                  icon={<Drop size={20} />}
                  value={`${humidity?.value.toFixed(1) ?? '-'}`}
                  unit="%"
                  label="Humidity"
                  onClick={() => openHumidityDialog(component)}
                  active={component.isActive}
                />,
              ];
            }
            return null;
          })
        )}
      </>
      {auth.user?.isAdmin && (
        <Tile className={'e2e-edit-components'} onClick={() => openAddDeviceDialog()}>
          <IconContainer>
            <Value>
              <ListPlus size={80} color={colors.grey05} />
            </Value>
          </IconContainer>
          <Label>edit</Label>
        </Tile>
      )}
    </Box>
  );
};

export default UnitModulesSection;
