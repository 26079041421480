import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
import { CircleNotch, Info, X } from 'phosphor-react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ReactHookFormInput, { ReactHookFormInputType } from '../../../components/form/ReactHookFormInput';
import { Rotating } from '../../../components/Rotating';
import { Rental, createRental, updateRental, useRental } from '../../../gateway/rentalGateway';
import { TenantListItem, useTenant } from '../../../gateway/tenantGateway';
import { colors } from '../../../theming/colors';
import { nameEmailDivider } from '../../../util/util';

const UnitRentalManagementEditDialog: React.FC<{
  closeDialog: () => void;
  rentalId?: string;
  storageUnitId: string;
  tenants?: TenantListItem[];
}> = ({ closeDialog, rentalId, storageUnitId, tenants }) => {
  const { rental, isLoading } = useRental(storageUnitId, rentalId);
  const tenant = useTenant(rental?.tenantId);
  const { toaster } = useToaster();

  const { control, handleSubmit, reset, formState, getValues } = useForm({
    defaultValues: {
      ...rental,
      accessLogsActive: rental?.accessLogsActive ?? false,
      shareAccessActive: rental?.shareAccessActive ?? false,
    },
  });

  // Check if we attempted to get the rental (in the case that we are updating an existing rental)
  const [triedToLoad, setTriedToLoad] = React.useState(false);
  useEffect(() => setTriedToLoad(true), [isLoading]);

  const onSubmit = async (data: Partial<Rental>) => {
    try {
      if (data.id) {
        await updateRental(data);
      } else {
        await createRental({
          tenantId: data.tenantId!,
          storageUnitId,
          accessLogsActive: data.accessLogsActive,
          sharedAccessActive: data.shareAccessActive,
          startDate: data.starts!,
          endDate: data.expires,
        });
      }
      closeDialog();
      toaster(
        'Action Successful',
        `Rental was ${data.id ? 'updated' : 'created'} successfully.`,
        ToastMessageTypes.SUCCESS,
      );
    } catch (e) {
      toaster(`Unable to ${data.id ? 'update' : 'create'} rental.`, e.response.data.message, ToastMessageTypes.ERROR);
    }
  };
  const onCancel = () => closeDialog();

  useEffect(() => {
    if (isLoading) return;
    if (rentalId && rental) {
      reset(rental);
    }
    // if it tried to load, !isLoading, rentalId is defined, but no rental found
    if (triedToLoad && !isLoading && rentalId && !rental) {
      toaster('Error Occurred', 'Unable to load rental.', ToastMessageTypes.ERROR);
      closeDialog();
    }
  }, [rental, isLoading, storageUnitId, reset, toaster, closeDialog, rentalId, triedToLoad]);

  const formIsSubmittable = useMemo(
    () => !isLoading && formState.isDirty && formState.isValid,
    [formState.isDirty, formState.isValid, isLoading],
  );

  return (
    <>
      <Backdrop open={true} />
      <Dialog
        open={true}
        sx={{
          '& .MuiPaper-root': { width: '100%', maxWidth: 800 },
          '& .MuiDialogActions-root': { padding: 2 },
        }}
      >
        <form className="e2e-rental-create-edit-modal" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h2">
            {rentalId ? 'Edit' : 'Create'} Rental
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 12,
                top: 14,
              }}
            >
              {<X />}
            </IconButton>
          </DialogTitle>
          {rentalId && isLoading ? (
            <Grid container alignItems="center" justifyContent={'center'} py={8}>
              <Rotating>
                <CircleNotch size={48} color={colors.grey05} />
              </Rotating>
            </Grid>
          ) : (
            <>
              <DialogContent dividers>
                <Typography variant="h5" pb={rentalId ? 2 : 0}>
                  Rental Owner
                </Typography>
                {rentalId ? (
                  <>
                    {tenant.isLoading ? (
                      <Rotating>
                        <CircleNotch size={24} color={colors.grey05} />
                      </Rotating>
                    ) : (
                      <Grid container spacing={1}>
                        <Grid item xs={6} md={3} paddingBottom={1}>
                          <InputLabel>Last name:</InputLabel>
                          <Typography>{tenant.data?.lastName}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} paddingBottom={1}>
                          <InputLabel>First name:</InputLabel>
                          <Typography>{tenant.data?.firstName}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} paddingBottom={1}>
                          <InputLabel>E-Mail:</InputLabel>
                          <Typography>{tenant.data?.email}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    <Typography fontSize={12}>A key will be created for the owner of the rental.</Typography>
                    <ReactHookFormInput
                      control={control}
                      name="tenantId"
                      type={ReactHookFormInputType.autocomplete}
                      rules={{
                        required: true,
                      }}
                      options={
                        tenants?.map(tenant => ({
                          name: `${tenant.firstName} ${tenant.lastName}${nameEmailDivider}${tenant.email}`,
                          value: tenant.id,
                        })) || []
                      }
                      renderOption={(props, option) => {
                        const [name, email] = option.name.split(nameEmailDivider);
                        return (
                          <li {...props} key={option.value}>
                            <span style={{ textAlign: 'left', wordBreak: 'break-word' }}>{name + ' '}</span>
                            <span style={{ flexGrow: 1 }}></span>
                            <span style={{ opacity: 0.5, textAlign: 'right', wordBreak: 'break-word' }}>{email}</span>
                          </li>
                        );
                      }}
                    />
                  </>
                )}
              </DialogContent>
              <DialogContent>
                <Typography variant="h5" pb={2}>
                  Rental
                </Typography>
                <Grid container spacing={1}>
                  {rentalId ? (
                    <>
                      <Grid item xs={6} md={3} paddingBottom={1}>
                        <InputLabel>Park:</InputLabel>
                        <Typography>{rental?.parkName}</Typography>
                      </Grid>
                      <Grid item xs={6} md={3} paddingBottom={1}>
                        <InputLabel>Unit:</InputLabel>
                        <Typography>{rental?.storageUnitVisualId}</Typography>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                  <Grid item xs={6} md={3} paddingBottom={1}>
                    <InputLabel>Start Date:</InputLabel>
                    {rentalId ? (
                      <Typography>{rental?.starts ? new Date(rental?.starts).toLocaleDateString() : ''}</Typography>
                    ) : (
                      <ReactHookFormInput
                        control={control}
                        name="starts"
                        type={ReactHookFormInputType.date}
                        rules={{
                          required: true,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6} md={3} paddingBottom={1}>
                    <InputLabel>End Date:</InputLabel>
                    <ReactHookFormInput control={control} name="expires" type={ReactHookFormInputType.date} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogContent dividers>
                <Typography variant="h5" pb={2}>
                  Extra Features
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ReactHookFormInput
                      control={control}
                      name="accessLogsActive"
                      label="Access History"
                      type={ReactHookFormInputType.checkbox}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ReactHookFormInput
                      control={control}
                      name="shareAccessActive"
                      label="Share Access"
                      type={ReactHookFormInputType.checkbox}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          )}
          <Box p={2}>
            {!getValues().expires && (
              <Typography variant="body2">
                <Info color={colors.grey06} style={{ verticalAlign: 'top' }} /> Without an end date, it will not be
                possible to schedule another rental for this unit which will start in the future.
              </Typography>
            )}
          </Box>
          <DialogActions>
            <Button variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" size="medium" type="submit" disabled={!formIsSubmittable}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UnitRentalManagementEditDialog;
