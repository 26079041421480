import React from 'react';
import { StorageUnit } from '../model';
import { TenantListItem } from '../gateway/tenantGateway';
import { components } from '../../gen/backoffice-service';
import { UseStorageUnitRentalsResponse, useStorageUnitRentals } from '../gateway/storageUnit/rentals';

export type RentalManagementModals = {
  setEditRentalModal: React.Dispatch<React.SetStateAction<{ rentalId: string } | undefined>>;
  setEndRentalModal: React.Dispatch<React.SetStateAction<{ rentalId: string } | undefined>>;
  setRevokeKeyModal: React.Dispatch<React.SetStateAction<{ key: KeyAndTenant; rental: RentalEntry } | undefined>>;
};

export type KeyAndTenant = components['schemas']['Key'] & {
  tenant?: TenantListItem;
};

export interface RentalEntry {
  id: string;
  tenantId: string;
  tenant: string;
  starts: string;
  expires?: string;
  isActive: boolean;
  keys: KeyAndTenant[];
  accessLogsActive: boolean;
  shareAccessActive: boolean;
  storageUnitId: string;
}

type RentalManagementContextApi = {
  editRentalModal: { rentalId?: string } | undefined;
  setEditRentalModal: React.Dispatch<React.SetStateAction<{ rentalId?: string } | undefined>>;
  endRentalModal: { rentalId: string } | undefined;
  setEndRentalModal: React.Dispatch<React.SetStateAction<{ rentalId: string } | undefined>>;
  revokeKeyModal: { key: KeyAndTenant; rental: RentalEntry } | undefined;
  setRevokeKeyModal: React.Dispatch<React.SetStateAction<{ key: KeyAndTenant; rental: RentalEntry } | undefined>>;
  setSharedAccessModal: React.Dispatch<React.SetStateAction<{ rental: RentalEntry } | undefined>>;
  sharedAccessModal: { rental: RentalEntry } | undefined;
  rentals: UseStorageUnitRentalsResponse;
};
export const RentalManagementContext = React.createContext<RentalManagementContextApi>(
  {} as RentalManagementContextApi,
);

type RentalManagementProps = {
  children?: React.ReactNode;
  storageUnit: StorageUnit;
};

const RentalManagementContextProvider: React.FC<RentalManagementProps> = ({ children, storageUnit }) => {
  const [editRentalModal, setEditRentalModal] = React.useState<{ rentalId?: string } | undefined>();
  const [endRentalModal, setEndRentalModal] = React.useState<{ rentalId: string } | undefined>();
  const [revokeKeyModal, setRevokeKeyModal] = React.useState<{ key: KeyAndTenant; rental: RentalEntry } | undefined>();
  const [sharedAccessModal, setSharedAccessModal] = React.useState<{ rental: RentalEntry } | undefined>();
  const rentals = useStorageUnitRentals(storageUnit.id);

  return (
    <RentalManagementContext.Provider
      value={{
        editRentalModal,
        setEditRentalModal,
        endRentalModal,
        setEndRentalModal,
        revokeKeyModal,
        rentals,
        setRevokeKeyModal,
        sharedAccessModal,
        setSharedAccessModal,
      }}
    >
      {children}
    </RentalManagementContext.Provider>
  );
};

export default RentalManagementContext;
export { RentalManagementContextProvider };
