import { useEffect, useState } from 'react';
import { components } from '../../gen/backoffice-service';
import { get } from './requestUtils';
import { getAuthToken } from '../auth/auth';

type EventListPayload = components['schemas']['EventListPayload'];
export type StorageUnitEvent = components['schemas']['EventListEvent'];

export const getStorageUnitEvents = async (storageUnitId: string): Promise<EventListPayload> => {
  const token = await getAuthToken();
  const response = await get(`/units/${storageUnitId}/events`, token);
  return response.data;
};

export const useEvents = (storageUnitId: string): StorageUnitEvent[] | undefined => {
  const [events, setEvents] = useState<StorageUnitEvent[] | undefined>();

  useEffect(() => {
    let mounted = true;
    getStorageUnitEvents(storageUnitId).then(data => {
      if (!mounted) return;
      setEvents(data.events); // TODO: mapper
    });
    return () => {
      mounted = false;
    };
  }, [storageUnitId]);

  return events;
};
