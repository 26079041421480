import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { X } from 'phosphor-react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StorageUnitEvent, useEvents } from '../../gateway/eventsGateway';
import EventRow from './Events/EventRow';

const UnitEventsDialog: React.FunctionComponent = () => {
  const params = useParams();
  const { storageUnitId, parkId } = params;
  const events = useEvents(storageUnitId!);
  const navigate = useNavigate();

  const onCancel = () => navigate(`/park/${parkId}/unit/${storageUnitId}`);

  if (!events)
    return (
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  const groupedEvents = events?.reduce<Map<number, StorageUnitEvent[]>>((grouped, event) => {
    const timestamp = new Date(Date.parse(event.timestamp));
    const key = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate()).getTime();
    if (grouped.get(key)) grouped.get(key)!.push(event);
    else grouped.set(key, [event]);
    return grouped;
  }, new Map<number, StorageUnitEvent[]>());

  return (
    <Dialog open={true}>
      <DialogTitle variant="h2" sx={{ minWidth: 300 }}>
        Access logs
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 12,
            top: 14,
          }}
        >
          {<X />}
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {[...groupedEvents.keys()]
          .sort()
          .reverse()
          .map(day => (
            <Box key={day} sx={{ mb: 2 }}>
              <Typography sx={{ mb: 1 }}>{new Date(day).toDateString()}</Typography>
              {groupedEvents.get(day)?.map((event, index) => (
                <EventRow {...{ key: index, event }} key={index} />
              ))}
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="medium" onClick={onCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnitEventsDialog;
