import React, { ReactNode, useContext, useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import { getParks } from '../gateway/park/get';
import { components } from '../../gen/backoffice-service';

type ParkListPark = components['schemas']['ParkListPark'];

type ForceRefreshFunction = () => void;
type ParksContextApi = {
  draftParksVisible: boolean;
  parks: ParkListPark[] | undefined;
  forceRefreshParks: ForceRefreshFunction;
  setDraftParksVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ParksContext = React.createContext<ParksContextApi>({
  draftParksVisible: false,
  parks: undefined,
  forceRefreshParks: () => {},
  setDraftParksVisible: () => {},
});

const ParksProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const auth = useContext(AuthContext);
  const company = auth.user?.company;
  const [parks, setParks] = useState<ParkListPark[] | undefined>();
  const [draftParksVisible, setDraftParksVisible] = useState<boolean>(false);
  const parksExist = !!parks;

  const forceRefresh = () => {
    setParks(undefined);
  };

  useEffect(() => {
    if (!auth) return;
    if (!auth.user?.company) return;
    if (parksExist) return;
    getParks(draftParksVisible).then(r => setParks(r.parks));
  }, [auth, company, parksExist, draftParksVisible]);

  return (
    <ParksContext.Provider value={{ parks, forceRefreshParks: forceRefresh, draftParksVisible, setDraftParksVisible }}>
      {children}
    </ParksContext.Provider>
  );
};

export default ParksContext;
export { ParksProvider };
