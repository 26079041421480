import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import FormikTextField from '../../components/FormikTextField';
import { addOperator } from '../../gateway/companyGateway';
import AuthContext from '../../context/AuthContext';
import { isAxiosError } from 'axios';

interface Data {
  name: string;
  email: string;
  company: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddOperatorForm: React.FC<Props> = props => {
  const auth = useContext(AuthContext);
  const initialValues = { name: '', email: '', company: auth.user?.company || '' };
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const onSubmit = async (values: Data) => {
    setLoading(true);
    try {
      setLoading(true);
      //TODO
      await addOperator(values);
      props.onClose();
    } catch (e) {
      if (isAxiosError(e) && (e.response?.data.message || e.response?.data)) {
        setError(e.response?.data.message ?? e.response?.data);
      } else {
        setError('Da ist etwas schiefgegangen');
      }
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    props.onClose();
  };

  const formik = useFormik<Data>({
    initialValues,
    onSubmit,
  });

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <FormikProvider value={formik}>
        <Dialog open={props.open}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Add Operator</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormikTextField name="name" label="Name" type="text" className="cy-operator-form-name" required />
                  <FormikTextField
                    name="email"
                    label="Email"
                    type="email"
                    className="cy-operator-form-email"
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            {error != null && (
              <>
                <Alert
                  severity="error"
                  onClose={() => setError(null)}
                  sx={{
                    marginBottom: '0.5em',
                  }}
                >
                  {error}
                </Alert>
              </>
            )}
            <DialogActions>
              <Button variant="outlined" size="medium" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="contained" size="medium" type="submit">
                Add User
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </FormikProvider>
    </>
  );
};

export default AddOperatorForm;
