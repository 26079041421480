import React, { useContext } from 'react';

import { ToastContext, ToastState } from '../context/ToastContext';
import { Alert, Snackbar, Typography } from '@mui/material';

export const ToastMessage = () => {
  const { toastMsg, showToast, setShowToast } = useContext(ToastContext) as ToastState;

  return (
    <Snackbar
      open={showToast}
      autoHideDuration={10000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => setShowToast(false)}
    >
      <Alert onClose={() => setShowToast(false)} severity={toastMsg?.type} sx={{ width: '100%' }}>
        <Typography textAlign={'left'} variant="h4" marginBottom="0.5em">
          {toastMsg?.title}
        </Typography>
        <Typography fontSize={'1rem'}>{toastMsg?.content}</Typography>
      </Alert>
    </Snackbar>
  );
};
