import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from '../config';
import { withBearerAuthorization } from './withBearerAuthorization';

const wrapError = (e: AxiosError) => {
  e.name = `Axios ${e.response?.status ? `${e.response.status} ` : ''}error: ${e.response?.statusText || 'unknown'}`;
  throw e;
};

const getAxiosConfig = (token: string | AxiosRequestConfig<any>) => {
  return typeof token === 'string' ? withBearerAuthorization(token) : token;
};

/**
 * Handles axios get requests, and throws an {@link HttpError} if the response is not successful
 * Useful to add some context to the error for when it is picked up by Sentry
 * @param path API path (excluding base url)
 * @param token auth token
 */
export const get = <D = any>(path: string, token: string | AxiosRequestConfig<any>) => {
  return axios.get<any, AxiosResponse<D>>(`${config.backendUrl}${path}`, getAxiosConfig(token)).catch(wrapError);
};

/**
 * Handles axios post requests, and throws an {@link HttpError} if the response is not successful
 * Useful to add some context to the error for when it is picked up by Sentry
 * @param path API path (excluding base url)
 * @param data
 * @param token auth token
 */
export const post = <D = any>(path: string, data: any, token: string | AxiosRequestConfig<any>) => {
  return axios.post<any, AxiosResponse<D>>(`${config.backendUrl}${path}`, data, getAxiosConfig(token)).catch(wrapError);
};

/**
 * Handles axios put requests, and throws an {@link HttpError} if the response is not successful
 * Useful to add some context to the error for when it is picked up by Sentry
 * @param pathAPI path (excluding base url)
 * @param data
 * @param token auth token
 */
export const put = <D = any>(path: string, data: any, token: string | AxiosRequestConfig<any>) => {
  return axios.put<any, AxiosResponse<D>>(`${config.backendUrl}${path}`, data, getAxiosConfig(token)).catch(wrapError);
};

// Unable to use keyword "delete" as function name
/**
 * Handles axios axiosDelete requests, and throws an {@link HttpError} if the response is not successful
 * Useful to add some context to the error for when it is picked up by Sentry
 * @param path API path (excluding base url)
 * @param token auth token
 */
export const axiosDelete = <D = any>(path: string, token: string | AxiosRequestConfig<any>) => {
  return axios.delete<D>(`${config.backendUrl}${path}`, getAxiosConfig(token)).catch(wrapError);
};
