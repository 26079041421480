// --- external imports
import * as React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Input } from '@mui/material';

// --- components
import TableLoading from '../../components/TableLoading';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';

// --- context
import { EmailTemplatesContext } from '../../context/EmailTemplateContext';

// --- api
import {
  CompanyEmailTemplateType,
  upsertCompanyEmailTemplates,
  CompanyEmailTemplate,
} from '../../gateway/companyGateway';

// --- helpers
import _ from 'lodash';

const EmailTemplatesList: React.FC = () => {
  const { emailTemplates, loading, forceRefreshEmailTemplates } = React.useContext(EmailTemplatesContext);
  const [emailTemplatesState, setEmailTemplatesState] = React.useState<CompanyEmailTemplate[] | undefined>(
    emailTemplates,
  );

  const [submitting, setSubmitting] = React.useState(false);
  const { toaster } = useToaster();

  React.useEffect(() => {
    setEmailTemplatesState(emailTemplates);
  }, [emailTemplates]);

  if (emailTemplatesState === undefined || loading) {
    return <TableLoading />;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto max-content',
      }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Hubspot ID - DE</TableCell>
            <TableCell>Hubspot ID - FR</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {emailTemplatesState.map((v, index) => {
            return (
              <TableRow
                hover
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>
                  <b>{convertTypeToText(v.type)}</b>
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={v.hubspotIdDe}
                    onChange={e => {
                      const tmp = structuredClone(emailTemplatesState);
                      tmp[index].hubspotIdDe = e.currentTarget.value;
                      setEmailTemplatesState(tmp);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={v.hubspotIdFr}
                    onChange={e => {
                      const tmp = structuredClone(emailTemplatesState);
                      tmp[index].hubspotIdFr = e.currentTarget.value;
                      setEmailTemplatesState(tmp);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Button
        style={{
          width: '100px',
          justifySelf: 'end',
          marginTop: '10px',
        }}
        variant="contained"
        size="medium"
        disabled={_.isEqual(emailTemplates, emailTemplatesState) || submitting}
        onClick={() => {
          setSubmitting(true);
          upsertCompanyEmailTemplates(extractUpserts(emailTemplates ?? [], emailTemplatesState))
            .then(() => {
              forceRefreshEmailTemplates();
              setSubmitting(false);
              toaster('Action Successful', 'Email templates were updated.', ToastMessageTypes.SUCCESS);
            })
            .catch(() => {
              toaster('Sorry!', 'There is something wrong, unable to load email templates.', ToastMessageTypes.ERROR);
            });
        }}
      >
        Save
      </Button>
    </div>
  );
};

const extractUpserts = (org: CompanyEmailTemplate[], current: CompanyEmailTemplate[]): CompanyEmailTemplate[] => {
  const result = structuredClone(current);
  for (let iTemplate = org.length - 1; iTemplate > -1; iTemplate--) {
    if (_.isEqual(org[iTemplate], current[iTemplate]) && org[iTemplate].id) result.splice(iTemplate, 1);
  }

  return result;
};

const convertTypeToText = (type: CompanyEmailTemplateType) => {
  switch (type) {
    case 'COMPANY_EMAIL_TEMPLATE_TYPE_EMAIL_CHANGED':
      return 'Email Changed';
    case 'COMPANY_EMAIL_TEMPLATE_TYPE_PASSWORD_RESET':
      return 'Password Reset';
    case 'COMPANY_EMAIL_TEMPLATE_TYPE_WELCOME':
      return 'Welcome';
    case 'UNRECOGNIZED':
      return '--';
  }
};

export default EmailTemplatesList;
