import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import LoadingIcon from './LoadingIcon';

const TableLoading: React.FC = () => {
  return (
    <Table sx={{ minWidth: 650, minHeight: 'calc(100vh - 130px)', width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell align="center">
            <LoadingIcon />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TableLoading;
