import * as React from 'react';
import { useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import { UserCircle } from 'phosphor-react';
import { l10n } from '../../../l10n';

const ProfileItem: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();
  return (
    <div className={'e2e-go-to-profile-page'}>
      <SidebarItem Icon={UserCircle} title={l10n.naviProfile} route="/profile" selected={path.startsWith('/profile')} />
    </div>
  );
};

export default ProfileItem;
