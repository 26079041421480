// --- external imports
import * as React from 'react';
import { useCallback, useState } from 'react';
import axios, { isAxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';

// --- components
import InitialLayout from './InitialLayout';
import { useToaster, ToastMessageTypes } from '@ivy/toaster';

// --- helpers
import { isValidEmail } from '../../util/util';
import { config } from '../../config';

interface FormData {
  email: string;
}

interface PasswordResetProps {
  hideBackground?: boolean;
  hideLogo?: boolean;
}

interface i18nObject {
  reqPassReset: string;
  succPassReset: string;
  email: string;
  buttonText: string;
  errTitle: string;
  errMsg: string;
}

interface i18n {
  en: i18nObject;
  de: i18nObject;
  fr: i18nObject;
}

const i18nMock: i18n = {
  en: {
    reqPassReset: 'Request password reset',
    succPassReset: 'Password reset link sent successfully!',
    email: 'Email Address',
    buttonText: 'Send',
    errTitle: 'Error Occurred',
    errMsg: 'Something went wrong.',
  },
  de: {
    reqPassReset: 'Passwort zurücksetzen anfordern',
    succPassReset: 'Link zum Zurücksetzen des Passworts erfolgreich gesendet!',
    email: 'E-Mail-Adresse',
    buttonText: 'Senden',
    errTitle: 'Ein Fehler ist aufgetreten',
    errMsg: 'Etwas ist schief gelaufen.',
  },
  fr: {
    reqPassReset: 'Demande de réinitialisation du mot de passe',
    succPassReset: 'Le lien de réinitialisation du mot de passe a été envoyé avec succès !',
    email: 'Adresse e-mail',
    buttonText: 'Envoyer',
    errTitle: 'Erreur est survenue',
    errMsg: 'Quelque chose s`est mal passé.',
  },
};

const PasswordReset: React.FunctionComponent<PasswordResetProps> = ({ hideBackground, hideLogo }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [i18n, setI18n] = useState<i18nObject>(i18nMock.en);
  const { toaster } = useToaster();

  const [searchParams] = useSearchParams();
  const paramEmail = searchParams.get('email');
  const paramLang = searchParams.get('lang');

  React.useEffect(() => {
    if (paramLang === 'fr') {
      setI18n(i18nMock.fr);
    } else if (paramLang === 'de') {
      setI18n(i18nMock.de);
    }
  }, [paramLang]);

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (loading) return;
      setLoading(true);

      try {
        await axios.post(`${config.backendUrl}/passwordReset`, { email: values.email });
        setSubmitted(true);
      } catch (e) {
        // report failure on server and connection error only
        if (isAxiosError(e) && ((e.response?.status && e.response?.status >= 500) || e.code === 'ERR_NETWORK')) {
          toaster(i18n.errTitle, i18n.errMsg, ToastMessageTypes.ERROR);
        } else {
          setSubmitted(true);
        }
      } finally {
        setLoading(false);
      }
    },
    [loading, toaster, i18n.errTitle, i18n.errMsg],
  );

  const formik = useFormik<FormData>({
    initialValues: { email: paramEmail ?? '' },
    onSubmit,
  });

  // submit immediately if the given email param is valid
  if (!mounted && isValidEmail(paramEmail ?? '')) {
    formik.submitForm();
    setMounted(true);
  }

  const buttonDisabled = loading || !formik.values.email.length;

  if (submitted) {
    return (
      <InitialLayout hideBackground={hideBackground} hideLogo={hideLogo}>
        <Box minWidth="320px">
          <Typography align="center">{i18n.succPassReset}</Typography>
        </Box>
      </InitialLayout>
    );
  }

  return (
    <InitialLayout hideBackground={hideBackground} hideLogo={hideLogo}>
      <Box minWidth="320px">
        <form onSubmit={formik.handleSubmit}>
          <Box
            component="div"
            sx={{
              '& .MuiTextField-root': { mt: 1, mb: 1 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h1">{i18n.reqPassReset}</Typography>
            <TextField
              id="email"
              label={i18n.email}
              variant="standard"
              type="email"
              autoComplete="username"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
            />
          </Box>
          <Box m={1} />

          <Button
            type="submit"
            variant="contained"
            sx={{ marginLeft: 'auto', minWidth: 120 }}
            disabled={buttonDisabled}
          >
            {loading ? <CircularProgress sx={{ color: 'white' }} size={24} /> : i18n.buttonText}
          </Button>
        </form>
      </Box>
    </InitialLayout>
  );
};

export default PasswordReset;
