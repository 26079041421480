import styled from '@emotion/styled';
import { Box } from '@mui/material';
import * as React from 'react';
import { colors } from '../../theming/colors';
import { PropsWithChildren, ReactNode } from 'react';

const Background = styled.div`
  background: linear-gradient(0.18deg, #000000 57.96%, #000000 57.96%, #1f2234 99.84%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 300;
  max-height: 300;
  background: ${colors.white};
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const InitialLayout: React.FunctionComponent<
  PropsWithChildren<ReactNode> & {
    hideBackground?: boolean;
    hideLogo?: boolean;
  }
> = ({ children, hideBackground, hideLogo }) => {
  return (
    <>
      <Background style={hideBackground ? { background: 'transparent' } : {}}>
        {!hideBackground && (
          <>
            {!hideLogo && <img src="images/ivy.svg" alt="logo" style={{ width: 200 }} />}
            <Box m={2} />
          </>
        )}
        <Container>{children}</Container>
      </Background>
    </>
  );
};

export default InitialLayout;
