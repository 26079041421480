import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { X } from 'phosphor-react';
import * as React from 'react';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { FormEvent, useState } from 'react';
import LoadingIcon from '../../components/LoadingIcon';
import { deleteOperator } from '../../gateway/companyGateway';
import { Operator } from '../../model';

const DeleteOperatorDialog: React.FC<{ closeDialog: () => void; operator: Operator }> = ({ closeDialog, operator }) => {
  const { toaster } = useToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await deleteOperator(operator.id);
      toaster('Operator deleted', '', ToastMessageTypes.SUCCESS);
      closeDialog();
    } catch (e) {
      setLoading(false);
      toaster('Error Occurred', 'Unable to delete operator.', ToastMessageTypes.ERROR);
    }
    return false;
  };

  const onCancel = () => closeDialog();

  return (
    <>
      <Dialog open={true}>
        <form onSubmit={onSubmit}>
          <DialogTitle variant="h2">
            Delete Operator
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 12,
                top: 14,
              }}
            >
              {<X />}
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4}>
              <Grid item xs={1}>
                <WarningAmberIcon color="error" fontSize="large" />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{ fontWeight: 700 }}>Are you sure you want to delete this operator?</Typography>
                <Typography>Email: {operator?.email}</Typography>
                <Typography>This action cannot be undone, the operator will be permanently deleted.</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="error"
              type="submit"
              disabled={loading}
              style={{
                width: 200,
              }}
            >
              {loading ? <LoadingIcon /> : 'Delete Operator'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DeleteOperatorDialog;
