import { createTheme, Theme } from '@mui/material';
import { colors } from './colors';

export const theme: Partial<Theme> = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    h1: {
      fontFamily: 'DM Sans',
      fontSize: 32,
      lineHeight: '42px',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'DM Sans',
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'DM Sans',
      fontSize: 24,
      lineHeight: '31px',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'DM Sans',
      fontSize: 20,
      lineHeight: '26px',
      fontWeight: 400,
    },
    h5: {
      fontFamily: 'DM Sans',
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'DM Sans',
      fontSize: 18,
      lineHeight: '23px',
    },

    body1: {
      // Body 1
      fontFamily: 'DM Sans',
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      // Bodytext-S
      fontFamily: 'DM Sans',
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 400,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: colors.grey05,
        },
      },
    },
  },
});
