import React, { useState, ReactNode, Dispatch, SetStateAction, useContext, useCallback } from 'react';
import { ToastMessage } from '../components/ToastMessage';

export enum ToastMessageTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

interface ToastInterface {
  title: string;
  content: string;
  type: ToastMessageTypes;
}

interface Props {
  children?: ReactNode;
}

export interface ToastState {
  toastMsg: ToastInterface | null;
  showToast: boolean;
  setShowToast: Dispatch<SetStateAction<boolean>>;
  toaster: (title: string, content: string, type: ToastMessageTypes) => void;
}

export const ToastContext = React.createContext<ToastState | null>(null);

export function useToaster() {
  return useContext(ToastContext) as ToastState;
}

export const ToastProvider = ({ children }: Props) => {
  const [toastMsg, setToastMsg] = useState({
    title: '',
    content: '',
    type: ToastMessageTypes.SUCCESS,
  });
  const [showToast, setShowToast] = useState(false);

  const toaster = useCallback((title: string, content: string, type: ToastMessageTypes) => {
    setToastMsg({
      title: title,
      content: content,
      type: type,
    });
    setShowToast(true);
  }, []);

  return (
    <ToastContext.Provider
      value={{
        toastMsg,
        showToast,
        setShowToast,
        toaster,
      }}
    >
      {children}
      {showToast && <ToastMessage />}
    </ToastContext.Provider>
  );
};
