import { Box, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { colors } from '../../../../../theming/colors';
import DataInfoCell from '../DataInfoCell';
import TelemetryChart, { TelemetryData } from '../TelemetryChart';

interface EnergyConsumptionProps {
  /**
   * Energy consumption *deltas*
   */
  deltas: TelemetryData[];
  startDate: Date;
  endDate: Date;
}

const EnergyConsumption: React.FC<EnergyConsumptionProps> = ({ deltas, startDate, endDate }) => {
  const [highestValue, setHighestValue] = React.useState<number>(0);

  useEffect(() => {
    setHighestValue(Math.max(...deltas.map(d => d.value)));
  }, [deltas]);

  const totalConsumption = useMemo(() => {
    return deltas.reduce((acc, curr) => acc + curr.value, 0);
  }, [deltas]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <DataInfoCell
          label="Total Energy Usage"
          value={totalConsumption ? totalConsumption.toFixed(2) : '-'}
          unit={'kWh'}
        />
      </Box>
      <Box m={4} />
      <Title variant="body2">Usage history</Title>
      <Box position="relative">
        <TelemetryChart
          title={'Energy consumption (kWh)'}
          datasets={[
            {
              data: deltas,
              options: {
                label: 'Energy consumption',
                color: colors.primary,
              },
            },
          ]}
          startDateOverride={startDate}
          endDateOverride={endDate}
          maxYAxis={highestValue < 1 ? 1 : undefined} // sets up the y axis with a minimum 0.1 kWh increment
          chartType="bar"
        />
      </Box>
    </>
  );
};

export default EnergyConsumption;

const Title = styled(Typography)`
  font-weight: 700;
  color: ${colors.grey05};
  margin-bottom: 8px;
`;
