import { useEffect, useState } from 'react';
import { StorageUnitKey } from '../../model';
import { get, post, put } from '../requestUtils';
import { getAuthToken } from '../../auth/auth';
import { KeyStatus } from '@ivy/proto/dist/storage_units/v2/key';

export const unlock = async (storageUnitId: String, parkId: String, companyId: String) => {
  const token = await getAuthToken();
  await post(
    `/units/${storageUnitId}/unlock`,
    {
      companyId: companyId,
      parkId: parkId,
      unitId: storageUnitId,
    },
    token,
  );
};

export const reactivateKey = async (storageUnitId: String, keyId: String) => {
  const token = await getAuthToken();
  await put(
    `/units/${storageUnitId}/key`,
    {
      id: keyId,
      active: true,
    },
    token,
  );
};

export const revokeKey = async (storageUnitId: String, keyId: String) => {
  const token = await getAuthToken();
  await put(
    `/units/${storageUnitId}/key`,
    {
      id: keyId,
      active: false,
    },
    token,
  );
};

export const grantAccess = async (storageUnitId: String, tenantId: String) => {
  const token = await getAuthToken();
  await put(
    `/units/${storageUnitId}/key`,
    {
      tenantId,
      active: true,
    },
    token,
  );
};

export const createKeyForRental = async (rentalId: string, tenantId: string, status: KeyStatus) => {
  const token = await getAuthToken();
  const response = await post(
    `/rentals/${rentalId}/key`,
    {
      tenantId,
      status,
    },
    token,
  );
  return response.data.id;
}

export const useStorageUnitKeysFor = (storageUnitId: String): StorageUnitKey[] => {
  const [data, setData] = useState<StorageUnitKey[]>([]);
  useEffect(() => {
    getStorageUnitKeysFor(storageUnitId).then(data => {
      setData(data);
    });
  }, [storageUnitId]);
  return data;
};

export const getStorageUnitKeysFor = async (storageUnitId: String): Promise<StorageUnitKey[]> => {
  const token = await getAuthToken();
  const response = await get(`/company/units/${storageUnitId}/keys`, token);
  return response.data.keys;
};

export const useStorageUnitKeys = (): StorageUnitKey[] => {
  const [data, setData] = useState<StorageUnitKey[]>([]);
  useEffect(() => {
    getStorageUnitKeys().then(data => {
      setData(data);
    });
  }, []);
  return data;
};

export const getStorageUnitKeys = async (): Promise<StorageUnitKey[]> => {
  const token = await getAuthToken();
  const response = await get(`/keys`, token);
  return response.data.keys;
};
