"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               unknown
// source: devices/v2/telemetry.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreDeviceTelemetryResponse = exports.StoreDeviceTelemetryRequest = exports.DeviceTelemetry = exports.SingleDeviceTelemetryResponse = exports.GetLatestDeviceTelemetryResponse = exports.GetLatestDeviceTelemetryRequest = exports.DeviceTelemetryResponse = exports.GetDeviceTelemetryResponse = exports.GetDeviceTelemetryRequest = exports.deviceTelemetryValueTypeToNumber = exports.deviceTelemetryValueTypeToJSON = exports.deviceTelemetryValueTypeFromJSON = exports.DeviceTelemetryValueType = exports.deviceTelemetryAggregateToNumber = exports.deviceTelemetryAggregateToJSON = exports.deviceTelemetryAggregateFromJSON = exports.DeviceTelemetryAggregate = exports.deviceTelemetryTypeToNumber = exports.deviceTelemetryTypeToJSON = exports.deviceTelemetryTypeFromJSON = exports.DeviceTelemetryType = exports.protobufPackage = void 0;
/* eslint-disable */
const wire_1 = require("@bufbuild/protobuf/wire");
const struct_1 = require("../../google/protobuf/struct");
const timestamp_1 = require("../../google/protobuf/timestamp");
exports.protobufPackage = "devices.v2";
var DeviceTelemetryType;
(function (DeviceTelemetryType) {
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_UNSPECIFIED"] = "DEVICE_TELEMETRY_TYPE_UNSPECIFIED";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_HUMIDITY"] = "DEVICE_TELEMETRY_TYPE_HUMIDITY";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_TEMPERATURE"] = "DEVICE_TELEMETRY_TYPE_TEMPERATURE";
    /** DEVICE_TELEMETRY_TYPE_ENERGY - [DEPRECTATED] Total energy consumed since the start of the last minute */
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_ENERGY"] = "DEVICE_TELEMETRY_TYPE_ENERGY";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_ERRORS"] = "DEVICE_TELEMETRY_TYPE_ERRORS";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY"] = "DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE"] = "DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_LOCK_STATE"] = "DEVICE_TELEMETRY_TYPE_LOCK_STATE";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_SWITCH_STATE"] = "DEVICE_TELEMETRY_TYPE_SWITCH_STATE";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_MOTION"] = "DEVICE_TELEMETRY_TYPE_MOTION";
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_SMOKE_ALARM_STATE"] = "DEVICE_TELEMETRY_TYPE_SMOKE_ALARM_STATE";
    /** DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY - Total energy consumed since device was active in Wh */
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY"] = "DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY";
    /** DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION - Instantaneous power consumption in Watts */
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION"] = "DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION";
    /** DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS - Used to determine if the legacy lock is sending telemetry */
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS"] = "DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS";
    /** DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE - Health state of the border router device */
    DeviceTelemetryType["DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE"] = "DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE";
    DeviceTelemetryType["UNRECOGNIZED"] = "UNRECOGNIZED";
})(DeviceTelemetryType || (exports.DeviceTelemetryType = DeviceTelemetryType = {}));
function deviceTelemetryTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "DEVICE_TELEMETRY_TYPE_UNSPECIFIED":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED;
        case 1:
        case "DEVICE_TELEMETRY_TYPE_HUMIDITY":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_HUMIDITY;
        case 2:
        case "DEVICE_TELEMETRY_TYPE_TEMPERATURE":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TEMPERATURE;
        case 3:
        case "DEVICE_TELEMETRY_TYPE_ENERGY":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_ENERGY;
        case 4:
        case "DEVICE_TELEMETRY_TYPE_ERRORS":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_ERRORS;
        case 5:
        case "DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY;
        case 6:
        case "DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE;
        case 7:
        case "DEVICE_TELEMETRY_TYPE_LOCK_STATE":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LOCK_STATE;
        case 8:
        case "DEVICE_TELEMETRY_TYPE_SWITCH_STATE":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SWITCH_STATE;
        case 9:
        case "DEVICE_TELEMETRY_TYPE_MOTION":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_MOTION;
        case 10:
        case "DEVICE_TELEMETRY_TYPE_SMOKE_ALARM_STATE":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SMOKE_ALARM_STATE;
        case 11:
        case "DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY;
        case 12:
        case "DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION;
        case 13:
        case "DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS;
        case 14:
        case "DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE":
            return DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DeviceTelemetryType.UNRECOGNIZED;
    }
}
exports.deviceTelemetryTypeFromJSON = deviceTelemetryTypeFromJSON;
function deviceTelemetryTypeToJSON(object) {
    switch (object) {
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED:
            return "DEVICE_TELEMETRY_TYPE_UNSPECIFIED";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_HUMIDITY:
            return "DEVICE_TELEMETRY_TYPE_HUMIDITY";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TEMPERATURE:
            return "DEVICE_TELEMETRY_TYPE_TEMPERATURE";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_ENERGY:
            return "DEVICE_TELEMETRY_TYPE_ENERGY";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_ERRORS:
            return "DEVICE_TELEMETRY_TYPE_ERRORS";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY:
            return "DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE:
            return "DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LOCK_STATE:
            return "DEVICE_TELEMETRY_TYPE_LOCK_STATE";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SWITCH_STATE:
            return "DEVICE_TELEMETRY_TYPE_SWITCH_STATE";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_MOTION:
            return "DEVICE_TELEMETRY_TYPE_MOTION";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SMOKE_ALARM_STATE:
            return "DEVICE_TELEMETRY_TYPE_SMOKE_ALARM_STATE";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY:
            return "DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION:
            return "DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS:
            return "DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS";
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE:
            return "DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE";
        case DeviceTelemetryType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.deviceTelemetryTypeToJSON = deviceTelemetryTypeToJSON;
function deviceTelemetryTypeToNumber(object) {
    switch (object) {
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED:
            return 0;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_HUMIDITY:
            return 1;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TEMPERATURE:
            return 2;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_ENERGY:
            return 3;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_ERRORS:
            return 4;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY:
            return 5;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE:
            return 6;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LOCK_STATE:
            return 7;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SWITCH_STATE:
            return 8;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_MOTION:
            return 9;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SMOKE_ALARM_STATE:
            return 10;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY:
            return 11;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_POWER_CONSUMPTION:
            return 12;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_LOCK_LIVENESS:
            return 13;
        case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_BORDER_ROUTER_HEALTH_STATE:
            return 14;
        case DeviceTelemetryType.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.deviceTelemetryTypeToNumber = deviceTelemetryTypeToNumber;
var DeviceTelemetryAggregate;
(function (DeviceTelemetryAggregate) {
    DeviceTelemetryAggregate["DEVICE_TELEMETRY_AGGREGATE_UNSPECIFIED"] = "DEVICE_TELEMETRY_AGGREGATE_UNSPECIFIED";
    DeviceTelemetryAggregate["DEVICE_TELEMETRY_AGGREGATE_MIN"] = "DEVICE_TELEMETRY_AGGREGATE_MIN";
    DeviceTelemetryAggregate["DEVICE_TELEMETRY_AGGREGATE_MAX"] = "DEVICE_TELEMETRY_AGGREGATE_MAX";
    DeviceTelemetryAggregate["DEVICE_TELEMETRY_AGGREGATE_AVG"] = "DEVICE_TELEMETRY_AGGREGATE_AVG";
    DeviceTelemetryAggregate["DEVICE_TELEMETRY_AGGREGATE_SUM"] = "DEVICE_TELEMETRY_AGGREGATE_SUM";
    DeviceTelemetryAggregate["UNRECOGNIZED"] = "UNRECOGNIZED";
})(DeviceTelemetryAggregate || (exports.DeviceTelemetryAggregate = DeviceTelemetryAggregate = {}));
function deviceTelemetryAggregateFromJSON(object) {
    switch (object) {
        case 0:
        case "DEVICE_TELEMETRY_AGGREGATE_UNSPECIFIED":
            return DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_UNSPECIFIED;
        case 1:
        case "DEVICE_TELEMETRY_AGGREGATE_MIN":
            return DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MIN;
        case 2:
        case "DEVICE_TELEMETRY_AGGREGATE_MAX":
            return DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MAX;
        case 3:
        case "DEVICE_TELEMETRY_AGGREGATE_AVG":
            return DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_AVG;
        case 4:
        case "DEVICE_TELEMETRY_AGGREGATE_SUM":
            return DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_SUM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DeviceTelemetryAggregate.UNRECOGNIZED;
    }
}
exports.deviceTelemetryAggregateFromJSON = deviceTelemetryAggregateFromJSON;
function deviceTelemetryAggregateToJSON(object) {
    switch (object) {
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_UNSPECIFIED:
            return "DEVICE_TELEMETRY_AGGREGATE_UNSPECIFIED";
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MIN:
            return "DEVICE_TELEMETRY_AGGREGATE_MIN";
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MAX:
            return "DEVICE_TELEMETRY_AGGREGATE_MAX";
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_AVG:
            return "DEVICE_TELEMETRY_AGGREGATE_AVG";
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_SUM:
            return "DEVICE_TELEMETRY_AGGREGATE_SUM";
        case DeviceTelemetryAggregate.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.deviceTelemetryAggregateToJSON = deviceTelemetryAggregateToJSON;
function deviceTelemetryAggregateToNumber(object) {
    switch (object) {
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_UNSPECIFIED:
            return 0;
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MIN:
            return 1;
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MAX:
            return 2;
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_AVG:
            return 3;
        case DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_SUM:
            return 4;
        case DeviceTelemetryAggregate.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.deviceTelemetryAggregateToNumber = deviceTelemetryAggregateToNumber;
var DeviceTelemetryValueType;
(function (DeviceTelemetryValueType) {
    DeviceTelemetryValueType["DEVICE_TELEMETRY_VALUE_TYPE_STRING"] = "DEVICE_TELEMETRY_VALUE_TYPE_STRING";
    DeviceTelemetryValueType["DEVICE_TELEMETRY_VALUE_TYPE_NUMBER"] = "DEVICE_TELEMETRY_VALUE_TYPE_NUMBER";
    DeviceTelemetryValueType["DEVICE_TELEMETRY_VALUE_TYPE_BOOLEAN"] = "DEVICE_TELEMETRY_VALUE_TYPE_BOOLEAN";
    DeviceTelemetryValueType["UNRECOGNIZED"] = "UNRECOGNIZED";
})(DeviceTelemetryValueType || (exports.DeviceTelemetryValueType = DeviceTelemetryValueType = {}));
function deviceTelemetryValueTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "DEVICE_TELEMETRY_VALUE_TYPE_STRING":
            return DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_STRING;
        case 1:
        case "DEVICE_TELEMETRY_VALUE_TYPE_NUMBER":
            return DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_NUMBER;
        case 2:
        case "DEVICE_TELEMETRY_VALUE_TYPE_BOOLEAN":
            return DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_BOOLEAN;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DeviceTelemetryValueType.UNRECOGNIZED;
    }
}
exports.deviceTelemetryValueTypeFromJSON = deviceTelemetryValueTypeFromJSON;
function deviceTelemetryValueTypeToJSON(object) {
    switch (object) {
        case DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_STRING:
            return "DEVICE_TELEMETRY_VALUE_TYPE_STRING";
        case DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_NUMBER:
            return "DEVICE_TELEMETRY_VALUE_TYPE_NUMBER";
        case DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_BOOLEAN:
            return "DEVICE_TELEMETRY_VALUE_TYPE_BOOLEAN";
        case DeviceTelemetryValueType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.deviceTelemetryValueTypeToJSON = deviceTelemetryValueTypeToJSON;
function deviceTelemetryValueTypeToNumber(object) {
    switch (object) {
        case DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_STRING:
            return 0;
        case DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_NUMBER:
            return 1;
        case DeviceTelemetryValueType.DEVICE_TELEMETRY_VALUE_TYPE_BOOLEAN:
            return 2;
        case DeviceTelemetryValueType.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.deviceTelemetryValueTypeToNumber = deviceTelemetryValueTypeToNumber;
function createBaseGetDeviceTelemetryRequest() {
    return {
        deviceId: "",
        type: [],
        startTime: undefined,
        endTime: undefined,
        interval: undefined,
        aggregate: undefined,
        limit: undefined,
    };
}
exports.GetDeviceTelemetryRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.deviceId !== "") {
            writer.uint32(10).string(message.deviceId);
        }
        writer.uint32(18).fork();
        for (const v of message.type) {
            writer.int32(deviceTelemetryTypeToNumber(v));
        }
        writer.join();
        if (message.startTime !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startTime), writer.uint32(26).fork()).join();
        }
        if (message.endTime !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endTime), writer.uint32(34).fork()).join();
        }
        if (message.interval !== undefined) {
            writer.uint32(40).int64(message.interval);
        }
        if (message.aggregate !== undefined) {
            writer.uint32(48).int32(deviceTelemetryAggregateToNumber(message.aggregate));
        }
        if (message.limit !== undefined) {
            writer.uint32(56).int64(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetDeviceTelemetryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.deviceId = reader.string();
                    continue;
                }
                case 2: {
                    if (tag === 16) {
                        message.type.push(deviceTelemetryTypeFromJSON(reader.int32()));
                        continue;
                    }
                    if (tag === 18) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.type.push(deviceTelemetryTypeFromJSON(reader.int32()));
                        }
                        continue;
                    }
                    break;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.startTime = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 4: {
                    if (tag !== 34) {
                        break;
                    }
                    message.endTime = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 5: {
                    if (tag !== 40) {
                        break;
                    }
                    message.interval = longToNumber(reader.int64());
                    continue;
                }
                case 6: {
                    if (tag !== 48) {
                        break;
                    }
                    message.aggregate = deviceTelemetryAggregateFromJSON(reader.int32());
                    continue;
                }
                case 7: {
                    if (tag !== 56) {
                        break;
                    }
                    message.limit = longToNumber(reader.int64());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
            type: globalThis.Array.isArray(object?.type) ? object.type.map((e) => deviceTelemetryTypeFromJSON(e)) : [],
            startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
            endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
            interval: isSet(object.interval) ? globalThis.Number(object.interval) : undefined,
            aggregate: isSet(object.aggregate) ? deviceTelemetryAggregateFromJSON(object.aggregate) : undefined,
            limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.deviceId !== "") {
            obj.deviceId = message.deviceId;
        }
        if (message.type?.length) {
            obj.type = message.type.map((e) => deviceTelemetryTypeToJSON(e));
        }
        if (message.startTime !== undefined) {
            obj.startTime = message.startTime.toISOString();
        }
        if (message.endTime !== undefined) {
            obj.endTime = message.endTime.toISOString();
        }
        if (message.interval !== undefined) {
            obj.interval = Math.round(message.interval);
        }
        if (message.aggregate !== undefined) {
            obj.aggregate = deviceTelemetryAggregateToJSON(message.aggregate);
        }
        if (message.limit !== undefined) {
            obj.limit = Math.round(message.limit);
        }
        return obj;
    },
    create(base) {
        return exports.GetDeviceTelemetryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetDeviceTelemetryRequest();
        message.deviceId = object.deviceId ?? "";
        message.type = object.type?.map((e) => e) || [];
        message.startTime = object.startTime ?? undefined;
        message.endTime = object.endTime ?? undefined;
        message.interval = object.interval ?? undefined;
        message.aggregate = object.aggregate ?? undefined;
        message.limit = object.limit ?? undefined;
        return message;
    },
};
function createBaseGetDeviceTelemetryResponse() {
    return { telemetry: [] };
}
exports.GetDeviceTelemetryResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        for (const v of message.telemetry) {
            exports.DeviceTelemetryResponse.encode(v, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetDeviceTelemetryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.telemetry.push(exports.DeviceTelemetryResponse.decode(reader, reader.uint32()));
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            telemetry: globalThis.Array.isArray(object?.telemetry)
                ? object.telemetry.map((e) => exports.DeviceTelemetryResponse.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.telemetry?.length) {
            obj.telemetry = message.telemetry.map((e) => exports.DeviceTelemetryResponse.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return exports.GetDeviceTelemetryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetDeviceTelemetryResponse();
        message.telemetry = object.telemetry?.map((e) => exports.DeviceTelemetryResponse.fromPartial(e)) || [];
        return message;
    },
};
function createBaseDeviceTelemetryResponse() {
    return { type: DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED, telemetry: [] };
}
exports.DeviceTelemetryResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.type !== DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED) {
            writer.uint32(8).int32(deviceTelemetryTypeToNumber(message.type));
        }
        for (const v of message.telemetry) {
            exports.DeviceTelemetry.encode(v, writer.uint32(18).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeviceTelemetryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 8) {
                        break;
                    }
                    message.type = deviceTelemetryTypeFromJSON(reader.int32());
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.telemetry.push(exports.DeviceTelemetry.decode(reader, reader.uint32()));
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? deviceTelemetryTypeFromJSON(object.type)
                : DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED,
            telemetry: globalThis.Array.isArray(object?.telemetry)
                ? object.telemetry.map((e) => exports.DeviceTelemetry.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.type !== DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED) {
            obj.type = deviceTelemetryTypeToJSON(message.type);
        }
        if (message.telemetry?.length) {
            obj.telemetry = message.telemetry.map((e) => exports.DeviceTelemetry.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return exports.DeviceTelemetryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDeviceTelemetryResponse();
        message.type = object.type ?? DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED;
        message.telemetry = object.telemetry?.map((e) => exports.DeviceTelemetry.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetLatestDeviceTelemetryRequest() {
    return { deviceId: "", type: [] };
}
exports.GetLatestDeviceTelemetryRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.deviceId !== "") {
            writer.uint32(10).string(message.deviceId);
        }
        writer.uint32(18).fork();
        for (const v of message.type) {
            writer.int32(deviceTelemetryTypeToNumber(v));
        }
        writer.join();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetLatestDeviceTelemetryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.deviceId = reader.string();
                    continue;
                }
                case 2: {
                    if (tag === 16) {
                        message.type.push(deviceTelemetryTypeFromJSON(reader.int32()));
                        continue;
                    }
                    if (tag === 18) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.type.push(deviceTelemetryTypeFromJSON(reader.int32()));
                        }
                        continue;
                    }
                    break;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
            type: globalThis.Array.isArray(object?.type) ? object.type.map((e) => deviceTelemetryTypeFromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.deviceId !== "") {
            obj.deviceId = message.deviceId;
        }
        if (message.type?.length) {
            obj.type = message.type.map((e) => deviceTelemetryTypeToJSON(e));
        }
        return obj;
    },
    create(base) {
        return exports.GetLatestDeviceTelemetryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetLatestDeviceTelemetryRequest();
        message.deviceId = object.deviceId ?? "";
        message.type = object.type?.map((e) => e) || [];
        return message;
    },
};
function createBaseGetLatestDeviceTelemetryResponse() {
    return { telemetry: [] };
}
exports.GetLatestDeviceTelemetryResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        for (const v of message.telemetry) {
            exports.SingleDeviceTelemetryResponse.encode(v, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetLatestDeviceTelemetryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.telemetry.push(exports.SingleDeviceTelemetryResponse.decode(reader, reader.uint32()));
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            telemetry: globalThis.Array.isArray(object?.telemetry)
                ? object.telemetry.map((e) => exports.SingleDeviceTelemetryResponse.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.telemetry?.length) {
            obj.telemetry = message.telemetry.map((e) => exports.SingleDeviceTelemetryResponse.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return exports.GetLatestDeviceTelemetryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetLatestDeviceTelemetryResponse();
        message.telemetry = object.telemetry?.map((e) => exports.SingleDeviceTelemetryResponse.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSingleDeviceTelemetryResponse() {
    return { type: DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED, telemetry: undefined };
}
exports.SingleDeviceTelemetryResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.type !== DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED) {
            writer.uint32(8).int32(deviceTelemetryTypeToNumber(message.type));
        }
        if (message.telemetry !== undefined) {
            exports.DeviceTelemetry.encode(message.telemetry, writer.uint32(18).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSingleDeviceTelemetryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 8) {
                        break;
                    }
                    message.type = deviceTelemetryTypeFromJSON(reader.int32());
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.telemetry = exports.DeviceTelemetry.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type)
                ? deviceTelemetryTypeFromJSON(object.type)
                : DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED,
            telemetry: isSet(object.telemetry) ? exports.DeviceTelemetry.fromJSON(object.telemetry) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.type !== DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED) {
            obj.type = deviceTelemetryTypeToJSON(message.type);
        }
        if (message.telemetry !== undefined) {
            obj.telemetry = exports.DeviceTelemetry.toJSON(message.telemetry);
        }
        return obj;
    },
    create(base) {
        return exports.SingleDeviceTelemetryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSingleDeviceTelemetryResponse();
        message.type = object.type ?? DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_UNSPECIFIED;
        message.telemetry = (object.telemetry !== undefined && object.telemetry !== null)
            ? exports.DeviceTelemetry.fromPartial(object.telemetry)
            : undefined;
        return message;
    },
};
function createBaseDeviceTelemetry() {
    return { timestamp: undefined, value: "" };
}
exports.DeviceTelemetry = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).join();
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeviceTelemetry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.value = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            value: isSet(object.value) ? globalThis.String(object.value) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.value !== "") {
            obj.value = message.value;
        }
        return obj;
    },
    create(base) {
        return exports.DeviceTelemetry.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDeviceTelemetry();
        message.timestamp = object.timestamp ?? undefined;
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseStoreDeviceTelemetryRequest() {
    return { deviceId: "", timestamp: undefined, message: undefined };
}
exports.StoreDeviceTelemetryRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.deviceId !== "") {
            writer.uint32(10).string(message.deviceId);
        }
        if (message.timestamp !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(18).fork()).join();
        }
        if (message.message !== undefined) {
            struct_1.Struct.encode(struct_1.Struct.wrap(message.message), writer.uint32(26).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStoreDeviceTelemetryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.deviceId = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.timestamp = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.message = struct_1.Struct.unwrap(struct_1.Struct.decode(reader, reader.uint32()));
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            message: isObject(object.message) ? object.message : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.deviceId !== "") {
            obj.deviceId = message.deviceId;
        }
        if (message.timestamp !== undefined) {
            obj.timestamp = message.timestamp.toISOString();
        }
        if (message.message !== undefined) {
            obj.message = message.message;
        }
        return obj;
    },
    create(base) {
        return exports.StoreDeviceTelemetryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStoreDeviceTelemetryRequest();
        message.deviceId = object.deviceId ?? "";
        message.timestamp = object.timestamp ?? undefined;
        message.message = object.message ?? undefined;
        return message;
    },
};
function createBaseStoreDeviceTelemetryResponse() {
    return {};
}
exports.StoreDeviceTelemetryResponse = {
    encode(_, writer = new wire_1.BinaryWriter()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStoreDeviceTelemetryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return exports.StoreDeviceTelemetryResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseStoreDeviceTelemetryResponse();
        return message;
    },
};
function toTimestamp(date) {
    const seconds = Math.trunc(date.getTime() / 1000);
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function longToNumber(int64) {
    const num = globalThis.Number(int64.toString());
    if (num > globalThis.Number.MAX_SAFE_INTEGER) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    if (num < globalThis.Number.MIN_SAFE_INTEGER) {
        throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
    }
    return num;
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
