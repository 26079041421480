import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const rotate = keyframes`
    0.0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
`;
export const Rotating = styled.div`
  display: inline-flex;
  animation: ${rotate} 2s ease-in-out 0s infinite normal none;
`;
