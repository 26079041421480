import { Alert, Snackbar } from '@mui/material';
import React, { useState } from 'react';

export const useErrorMessage = (): [() => JSX.Element, (message: string) => void] => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const Element = (
    <Snackbar
      open={visible}
      autoHideDuration={6000}
      onClose={() => setVisible(false)}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <Alert severity="error">{message}</Alert>
    </Snackbar>
  );

  return [
    () => Element,
    (message: string) => {
      setMessage(message);
      setVisible(true);
    },
  ];
};
