import { CartesianScaleOptions, ChartOptions } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { components } from '../../../../../gen/backoffice-service';
import { colors } from '../../../../theming/colors';
import { TelemetryData } from './TelemetryChart';

export const makeScale = (
  color?: string,
  position?: 'left' | 'right',
  grid?: boolean,
  max?: number,
  min?: number,
  title?: string,
): _DeepPartialObject<CartesianScaleOptions> => {
  return {
    position,
    ...{ max, min },
    grid: {
      display: grid,
    },
    ticks: {
      color: color ?? colors.grey05,
      font: {
        family: 'DM Sans',
        weight: 700,
        size: 12,
      },
    },
    title: {
      display: !!title,
      text: title,
    }
  };
};

export const makeOptions = (yScales: { [index: string]: _DeepPartialObject<CartesianScaleOptions> }): ChartOptions => {
  return {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    animation: false,
    scales: {
      x: {
        type: 'linear',
        grid: {
          display: false,
        },
        ticks: {
          color: colors.grey05,
          maxRotation: 0,
          minRotation: 0,
          font: {
            family: 'DM Sans',
            weight: 700,
            size: 12,
          },
          maxTicksLimit: 20,
          callback: () => {
            return '';
          },
        },
      },
      ...yScales,
    },
  };
};

export const decodeTelemetry = (telemetry: components['schemas']['DeviceTelemetry']): TelemetryData => ({
  timestamp: new Date(telemetry.timestamp!),
  value: Number(telemetry.value),
});
