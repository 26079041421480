import * as React from 'react';
import { l10n } from '../../../l10n';
import { useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import { Users } from 'phosphor-react';

const TenantsItem: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();
  return (
    <div className={'e2e-go-to-tenants-page'}>
      <SidebarItem Icon={Users} title={l10n.naviTenants} route="/tenants" selected={path.startsWith('/tenant')} />
    </div>
  );
};

export default TenantsItem;
