import { Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { StorageUnit } from '../../../../model';
import { unassignDeviceFromStorageUnit, assignDeviceToStorageUnit } from '../../../../gateway/deviceGateway';
import { LockSimple, Power, Thermometer } from 'phosphor-react';
import { colors } from '../../../../theming/colors';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import StorageUnitDetailContext from '../../../../context/StorageUnitDetailContext';
import { Device, DeviceType } from '@ivy/proto/dist/devices/v2/device';

const DeviceRow: React.FC<{
  device: Device;
}> = ({ device }) => {
  const { toaster } = useToaster();

  const [loading, setLoading] = React.useState(false);
  const { storageUnit, forceRefreshDevices } = React.useContext(StorageUnitDetailContext);
  const [deviceStorageUnit, setDeviceStorageUnit] = React.useState<StorageUnit | undefined>();

  React.useEffect(() => {
    if (device.storageUnitId === storageUnit.id) {
      setDeviceStorageUnit(storageUnit);
    }
  }, [device.storageUnitId, storageUnit]);

  /**
   * Assign device
   */
  const assignDevice = React.useCallback(
    (deviceId, storageUnitId) => {
      setLoading(true);
      (async function () {
        try {
          await assignDeviceToStorageUnit(deviceId, storageUnitId);
          forceRefreshDevices();
          toaster(
            'Assignment successful!',
            `Device ${deviceId} was successfully assigned to storage unit ${storageUnit.visualId}`,
            ToastMessageTypes.SUCCESS,
          );
        } catch (error) {
          toaster(
            'Assignment failed',
            `It was not possible to assign device ${deviceId} to storage unit ${storageUnit.visualId}`,
            ToastMessageTypes.ERROR,
          );
        } finally {
          setLoading(false);
          setDeviceStorageUnit(storageUnit);
        }
      })();
    },
    [forceRefreshDevices, storageUnit, toaster],
  );

  /**
   * Unassign device
   */
  const unassignDevice = React.useCallback(
    (deviceId: string, storageUnitId?: string) => {
      if (!storageUnitId) {
        toaster(
          'Device already unassigned',
          'This device is not currently linked to a storage unit',
          ToastMessageTypes.WARNING,
        );
        return;
      }

      setLoading(true);
      (async function () {
        try {
          await unassignDeviceFromStorageUnit(deviceId, storageUnitId);
          setDeviceStorageUnit(undefined);
          forceRefreshDevices();
          toaster('Device unassigned', `Device ${deviceId} was successfully unassigned`, ToastMessageTypes.SUCCESS);
        } catch (error) {
          console.error(error);
          toaster('Assignment failed', `It was not possible to unassign device ${deviceId}`, ToastMessageTypes.ERROR);
        } finally {
          setLoading(false);
        }
      })();
    },
    [forceRefreshDevices, toaster],
  );

  return (
    <TableRow key={device.deviceId}>
      <TableCell>
        <Stack data-cy="device-row" direction="row" alignItems="center">
          {(device.type === DeviceType.DEVICE_TYPE_SENSOR || device.type === DeviceType.DEVICE_TYPE_LEGACY_SENSOR) && (
            <Thermometer size={32} color={colors.primary} />
          )}
          {(device.type === DeviceType.DEVICE_TYPE_LOCK || device.type === DeviceType.DEVICE_TYPE_LEGACY_LOCK) && (
            <LockSimple size={32} color={colors.primary} />
          )}
          {device.type === DeviceType.DEVICE_TYPE_SMART_METER && <Power size={32} color={colors.primary} />}
          <Typography sx={{ pl: 2 }}>{device.name}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {!deviceStorageUnit ? (
          device.storageUnitId || '-'
        ) : deviceStorageUnit.id === storageUnit.id ? (
          <>this Storage Unit</>
        ) : (
          deviceStorageUnit.visualId
        )}
      </TableCell>
      <TableCell>
        {deviceStorageUnit?.id !== storageUnit.id && (
          <Button
            className={'e2e-add-device-' + device.deviceId}
            onClick={() => assignDevice(device.deviceId, storageUnit.id)}
            disabled={loading}
          >
            {device.storageUnitId ? `reassign to this unit` : `add`}
          </Button>
        )}
      </TableCell>
      <TableCell>
        {deviceStorageUnit?.id === storageUnit.id && (
          <Button disabled={loading} onClick={() => unassignDevice(device.deviceId, storageUnit.id)}>
            remove
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DeviceRow;
