import { Backdrop } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Park } from '../../model';
import ParkForm from './ParkForm';
import { usePark } from '../../gateway/park/get';
import { useUpdatePark } from '../../gateway/park/put';
import ParksContext from '../../context/ParksContext';

interface IParkEditProps {}

const ParkEdit: React.FunctionComponent<IParkEditProps> = () => {
  const id = useParams().id;
  const navigate = useNavigate();
  const park = usePark(id!);
  const savePark = useUpdatePark();
  const { forceRefreshParks } = React.useContext(ParksContext);

  const onCancel = () => {
    navigate(`/park/${id}`);
  };

  const onSave = async (values: Park) => {
    await savePark(values);
    forceRefreshParks();
    navigate(`/park/${id}`);
  };

  if (!park) {
    return <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={true} />;
  }
  return <ParkForm park={park} isEditForm={true} {...{ onCancel, onSave }} />;
};

export default ParkEdit;
