import * as React from 'react';
import Tile from './card/Tile';
import ValueContainer from './card/ValueContainer';
import Unit from './card/Unit';
import Label from './card/Label';
import Value from './card/Value';
import { ActiveStateIndicator } from './ActiveStateIndicator';
import IconContainer from './card/IconContainer';
import { Lightning, LightningSlash, Warning } from 'phosphor-react';
import { Box } from '@mui/material';
import { colors } from '../../../theming/colors';
import { SmartMeterData, SmartMeterPowerState } from '@ivy/proto/dist/devices/v2/device';
import dayjs from 'dayjs';

interface SmartMeterModuleProps {
  className?: string;
  unit?: string;
  label: string;
  active: boolean;
  onClick: () => void;
  telemetry?: SmartMeterData;
}

const SmartMeterIcon: React.FunctionComponent<{ telemetry?: SmartMeterData }> = ({ telemetry }) => {
  if (!telemetry) {
    return <Lightning size={20} />;
  } else if (telemetry.errors?.value.length) {
    return <Warning color={colors.signalWarning} size={20} />;
  } else if (telemetry.powerState?.value === SmartMeterPowerState.SMART_METER_POWER_STATE_OFF) {
    return <LightningSlash size={20} />;
  } else {
    return <Lightning size={20} />;
  }
};

export const SmartMeterComponent: React.FunctionComponent<SmartMeterModuleProps> = ({
  className,
  unit,
  label,
  onClick,
  active,
  telemetry,
}) => {
  // Only show the consumption if it was received within the last hour. Prevents stale data from being shown.
  const hasRecentConsumption = React.useMemo(() => {
    // If the consumption is 0 it's likely the value didn't change - still show a 0.
    if (telemetry?.powerConsumption?.value === 0) {
      return true;
    } else {
      // If the consumption has a value but it didn't change in many hours, it's likely the SM was disconnected
      return (
        telemetry?.powerConsumption?.timestamp &&
        dayjs().subtract(12, 'hours').isBefore(telemetry?.powerConsumption?.timestamp)
      );
    }
  }, [telemetry?.powerConsumption?.timestamp, telemetry?.powerConsumption?.value]);

  return (
    <>
      <Tile className={className} onClick={() => onClick()}>
        <Box position="absolute" sx={{ right: 10, top: '4px' }}>
          <ActiveStateIndicator active={active} />
        </Box>
        <IconContainer>{<SmartMeterIcon telemetry={telemetry} />}</IconContainer>
        <ValueContainer>
          <Value>{hasRecentConsumption ? telemetry?.powerConsumption?.value.toFixed(1) : '-'}</Value>
          <Unit>{unit}</Unit>
        </ValueContainer>
        <Label>{label}</Label>
      </Tile>
    </>
  );
};
