import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { Control } from 'react-hook-form';
import { ParkListPark } from '../../../gateway/park/get';
import { CompanyStorageUnitListPayloadStorageUnit } from '../../../gateway/storageUnit/get';
import { StorageUnitKey } from '../../../model';
import TenantFormKeysRow from './TenantFormKeysRow';
import { TenantFormData, TenantKeyFormData } from './TenantFormTypes';
import { colors } from '../../../theming/colors';
import InfoIcon from '@mui/icons-material/Info';

type TenantFormKeysProps = {
  formControl: Control<TenantFormData>;
  storageUnitKeys: TenantKeyFormData[];
  storageUnits: CompanyStorageUnitListPayloadStorageUnit[] | undefined;
  parks: ParkListPark[] | undefined;
  setKeys: (k: TenantKeyFormData[]) => void;
};

const TenantFormKeys: React.FC<TenantFormKeysProps> = ({
  formControl,
  storageUnitKeys,
  storageUnits,
  parks,
  setKeys,
}) => {
  const remove = React.useCallback(
    (keyIndex: number) => {
      storageUnitKeys[keyIndex].removed = true;
      setKeys(storageUnitKeys);
    },
    [setKeys, storageUnitKeys],
  );

  return (
    <>
      <Typography
        sx={{
          color: colors.white,
          backgroundColor: colors.primary,
          mb: 2,
          p: 2,
        }}
      >
        <InfoIcon
          sx={{
            display: 'inline-block',
            mr: 1,
            verticalAlign: 'middle',
          }}
        />
        Key creation has moved, and can now be found on the storage unit detail page.
      </Typography>

      <Grid container spacing={1}>
        {storageUnits && parks ? (
          <>
            {storageUnitKeys.map((storageUnitKey: StorageUnitKey, index: number) => {
              return (
                <TenantFormKeysRow
                  formControl={formControl}
                  key={`keys[${index}]`}
                  index={index}
                  storageUnits={storageUnits}
                  storageUnitKey={storageUnitKey}
                  parks={parks}
                  remove={remove}
                />
              );
            })}
          </>
        ) : (
          <Box justifyContent="center" display="flex" width="100%">
            <CircularProgress color="inherit" size={20} />
          </Box>
        )}
      </Grid>
    </>
  );
};

export default TenantFormKeys;
