import { Box, Divider } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import UnitModulesSection from './UnitModulesSection';
import UnitInfoSection from './UnitInfoSection';
import { StorageUnit } from '../../model';
import UnitRentalManagementSection from './rentals/UnitRentalManagementSection';
import { RentalManagementContextProvider } from '../../context/RentalManagementContext';

interface Props {
  storageUnit: StorageUnit;
}

const UnitData: React.FC<Props> = ({ storageUnit }) => {
  const params = useParams();
  const parkId = params.parkId!;
  const storageUnitId = storageUnit.id;
  if (storageUnit === undefined || storageUnit === null) {
    return null;
  }

  return (
    <>
      <Box sx={{ px: 4, pb: 2 }}>
        <UnitInfoSection {...{ parkId, storageUnit }} />
        <Divider />
        <UnitModulesSection {...{ parkId, storageUnitId }} />
        <Divider />
        <RentalManagementContextProvider storageUnit={storageUnit}>
          <UnitRentalManagementSection {...{ storageUnit }} />
        </RentalManagementContextProvider>
      </Box>
    </>
  );
};

export default UnitData;
