"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               unknown
// source: pagination/v1/pagination.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = exports.sortDirectionToNumber = exports.sortDirectionToJSON = exports.sortDirectionFromJSON = exports.SortDirection = exports.protobufPackage = void 0;
/* eslint-disable */
const wire_1 = require("@bufbuild/protobuf/wire");
exports.protobufPackage = "pagination.v1";
var SortDirection;
(function (SortDirection) {
    SortDirection["SORT_DIRECTION_UNSPECIFIED"] = "SORT_DIRECTION_UNSPECIFIED";
    SortDirection["SORT_DIRECTION_ASC"] = "SORT_DIRECTION_ASC";
    SortDirection["SORT_DIRECTION_DESC"] = "SORT_DIRECTION_DESC";
    SortDirection["UNRECOGNIZED"] = "UNRECOGNIZED";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
function sortDirectionFromJSON(object) {
    switch (object) {
        case 0:
        case "SORT_DIRECTION_UNSPECIFIED":
            return SortDirection.SORT_DIRECTION_UNSPECIFIED;
        case 1:
        case "SORT_DIRECTION_ASC":
            return SortDirection.SORT_DIRECTION_ASC;
        case 2:
        case "SORT_DIRECTION_DESC":
            return SortDirection.SORT_DIRECTION_DESC;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SortDirection.UNRECOGNIZED;
    }
}
exports.sortDirectionFromJSON = sortDirectionFromJSON;
function sortDirectionToJSON(object) {
    switch (object) {
        case SortDirection.SORT_DIRECTION_UNSPECIFIED:
            return "SORT_DIRECTION_UNSPECIFIED";
        case SortDirection.SORT_DIRECTION_ASC:
            return "SORT_DIRECTION_ASC";
        case SortDirection.SORT_DIRECTION_DESC:
            return "SORT_DIRECTION_DESC";
        case SortDirection.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.sortDirectionToJSON = sortDirectionToJSON;
function sortDirectionToNumber(object) {
    switch (object) {
        case SortDirection.SORT_DIRECTION_UNSPECIFIED:
            return 0;
        case SortDirection.SORT_DIRECTION_ASC:
            return 1;
        case SortDirection.SORT_DIRECTION_DESC:
            return 2;
        case SortDirection.UNRECOGNIZED:
        default:
            return -1;
    }
}
exports.sortDirectionToNumber = sortDirectionToNumber;
function createBasePagination() {
    return { page: 0, perPage: 0, sortDirection: undefined, sortProperty: undefined };
}
exports.Pagination = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.page !== 0) {
            writer.uint32(8).int32(message.page);
        }
        if (message.perPage !== 0) {
            writer.uint32(16).int32(message.perPage);
        }
        if (message.sortDirection !== undefined) {
            writer.uint32(24).int32(sortDirectionToNumber(message.sortDirection));
        }
        if (message.sortProperty !== undefined) {
            writer.uint32(34).string(message.sortProperty);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePagination();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 8) {
                        break;
                    }
                    message.page = reader.int32();
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.perPage = reader.int32();
                    continue;
                }
                case 3: {
                    if (tag !== 24) {
                        break;
                    }
                    message.sortDirection = sortDirectionFromJSON(reader.int32());
                    continue;
                }
                case 4: {
                    if (tag !== 34) {
                        break;
                    }
                    message.sortProperty = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            page: isSet(object.page) ? globalThis.Number(object.page) : 0,
            perPage: isSet(object.perPage) ? globalThis.Number(object.perPage) : 0,
            sortDirection: isSet(object.sortDirection) ? sortDirectionFromJSON(object.sortDirection) : undefined,
            sortProperty: isSet(object.sortProperty) ? globalThis.String(object.sortProperty) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.page !== 0) {
            obj.page = Math.round(message.page);
        }
        if (message.perPage !== 0) {
            obj.perPage = Math.round(message.perPage);
        }
        if (message.sortDirection !== undefined) {
            obj.sortDirection = sortDirectionToJSON(message.sortDirection);
        }
        if (message.sortProperty !== undefined) {
            obj.sortProperty = message.sortProperty;
        }
        return obj;
    },
    create(base) {
        return exports.Pagination.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePagination();
        message.page = object.page ?? 0;
        message.perPage = object.perPage ?? 0;
        message.sortDirection = object.sortDirection ?? undefined;
        message.sortProperty = object.sortProperty ?? undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
