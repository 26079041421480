// --- external imports
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// --- components
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import TenantForm from './Form/TenantForm';

// --- providers
import { EmailTemplatesProvider } from '../../context/EmailTemplateContext';

/// --- api
import { useTenant, useTenantRentals } from '../../gateway/tenantGateway';

// --- styles
import { Backdrop, CircularProgress } from '@mui/material';

const TenantEdit: React.FC = () => {
  const id = useParams().id;
  const tenant = useTenant(id);
  const rentals = useTenantRentals(id!);
  const navigate = useNavigate();
  const toaster = useToaster();
  const keys = tenant.data?.storageUnitKeys.filter(key => key.status === true) ?? [];

  React.useEffect(() => {
    if (!tenant.isLoading && tenant.error) {
      toaster.toaster('Error loading tenant', tenant.error, ToastMessageTypes.ERROR);
      navigate('/tenants');
    }
  }, [rentals, navigate, toaster, tenant.isLoading, tenant.error]);

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1299 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {tenant && rentals.data && !tenant.isLoading && !rentals.isLoading ? (
        <EmailTemplatesProvider>
          <TenantForm {...{ title: 'Edit Tenant', tenant: tenant.data, rentals: rentals.data, keys }} />
        </EmailTemplatesProvider>
      ) : null}
    </>
  );
};

export default TenantEdit;
