import axios from 'axios';
import { config } from '../config';
import { Address } from '../model';

const geocodeBase = 'https://maps.googleapis.com/maps/api/geocode';

export const getCoordinates = async (address: Address) => {
  const addressString = `${address.line1}, ${address.postalCode}, ${address.city}, ${address.state}, ${address.countryCode}`;

  const results = await axios
    .get(`${geocodeBase}/json?address=${addressString}&key=${config.geocodingApiKey}`)
    .then(response => {
      return response.data.results;
    })
    .catch(error => {
      throw Error(error.message);
    });

  if (results && results.length) {
    const result = results.shift();
    return {
      latitude: result.geometry.location.lat,
      longitude: result.geometry.location.lng,
    };
  }

  throw Error('Error fetching coordinates');
};
