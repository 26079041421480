import { components } from '../gen/backoffice-service';

export interface CompanyFeature {
  betaFeatures: boolean;
}
export interface SensorOffset {
  value: number;
}

export interface DevicePowerStatus {
  value: boolean;
}

export interface Energy {
  value: number;
  timestamp: string;
  history: { [index: string]: number };
  monthlyBudget?: null | {
    limit: number;
  };
}

export interface SensorData {
  value: number;
  timestamp?: string;
  history: { [index: string]: SensorIntervalData | undefined };
}

export interface SensorIntervalData {
  min?: number;
  max?: number;
  minAt?: string;
  maxAt?: string;
}

export interface Power {
  value: number;
}

export interface Money {
  currency: 'CURRENCY_UNSPECIFIED' | 'CURRENCY_EUR' | 'UNRECOGNIZED';
  decimalPos: number;
  value: number;
}

export interface Park {
  id: string;
  visualId: string;
  underConstruction: boolean;
  name: string;
  address: Address;
  manager?: ParkManager;
  storageUnits: StorageUnit[];
  unitIdCount: number;
  rentals: Rental[];
  iotRegistry: string;
  thingsboardId?: string;
  draft?: boolean;
}

export interface ParkManager {
  name: string;
  contactChannels: ContactEntry[];
}

export interface ContactEntry {
  type: string;
  value: string;
}

export interface CustomerAttribute {
  key: string;
  value: string;
}

export interface AddressLocation {
  lat?: number;
  long?: number;
}

export interface Address {
  line1?: string;
  line2?: string;
  city: string;
  postalCode: string;
  state?: string;
  countryCode: string;
  location?: AddressLocation;
}

export interface CustomerAddress {
  line1: string;
  line2?: string;
  city: string;
  postalCode: string;
  state?: string;
  countryCode: string;
}

export interface StorageUnit {
  id: string;
  visualId: string;
  description: string;
  parkId?: string;
  rentalRate?: Money;
  thingsboardId: string;
  activeDeviceRatio?: number;
  rental?: Rental;
  isRented?: boolean;
}

export type Company = components['schemas']['AdminGetCompanyPayload'];

export interface CompanyFeatureFlags {
  betaFeatures?: boolean;
}

export interface AdminUser {
  id: string;
  name: string;
  email: string;
}

export interface Operator {
  id: string;
  name: string;
  email: string;
}

export interface InternalCompanySettings {
  parkIdCount?: number;
}

export type Tenant = components['schemas']['TenantPayload'];

export type LanguagePreference = components['schemas']['LanguagePreference'];

export type Rental = components['schemas']['Rental'];

export enum ProductType {
  SUBSCRIPTION,
  ADD_ON,
}

export interface Product {
  id?: string;
  name: string;
  description: string;
  longDescription?: string;
  price: number;
  details: {
    key: string;
    value: string;
  }[];
}

export interface StorageUnitKey {
  id?: string;
  customerId: string;
  storageUnitId: string;
  status: boolean;
  parkId: string;
  isRentalPrimaryKey: boolean;
}

export const countries = ['de', 'fr', 'at', 'nl'];

export const countryNames: { [index: string]: string } = {
  de: 'Germany',
  fr: 'France',
  at: 'Austria',
  nl: 'Netherlands',
};

export interface Notification {
  parkId: string;
  unitId: string;
  level: 'ALERT' | 'WARNING';
  timestamp: Date;
  type: 'energy-limit';
}

export const currencyString = (money?: Money): string => {
  if (!money) {
    return '';
  }

  let currencySymbol;

  switch (money.currency) {
    case 'CURRENCY_EUR':
      currencySymbol = '€';
      break;
    default:
      currencySymbol = '€';
      break;
  }

  const value = (money.value / Math.pow(10, money.decimalPos)).toFixed(money.decimalPos);

  return `${currencySymbol}${value}`;
};

export const notificationDescription = (notification: Notification): string => {
  switch (notification.type) {
    case 'energy-limit':
      if (notification.level === 'ALERT') {
        return 'Monthly energy buget exausted';
      } else {
        return '80% of monthly energy buget exausted';
      }
  }
};
