import * as React from 'react';
import Sidebar from './Sidebar';
import List from '@mui/material/List';
import { drawerWidth } from '../../App';
import DashboardItem from './items/DashboardItem';
import ProfileItem from './items/ProfileItem';
import SidebarItem from './items/SidebarItem';
import { MapPin } from 'phosphor-react';
import { l10n } from '../../l10n';
import { useLocation } from 'react-router-dom';

const TenantSidebar: React.FC = () => {
  const path = useLocation().pathname.toLocaleLowerCase();

  return (
    <Sidebar width={drawerWidth}>
      <List>
        <DashboardItem />
        <div className={'e2e-tenant-units'}>
          <SidebarItem Icon={MapPin} title={l10n.naviParks} route="/units" selected={path.startsWith('/units')} />
        </div>
      </List>
      <div style={{ flex: 1 }} />
      <List>
        <ProfileItem />
      </List>
    </Sidebar>
  );
};

export default TenantSidebar;
