import styled from '@emotion/styled';

const Tile = styled.div`
  display: inline-block;
  width: 148px;
  height: 137px;
  margin-right: 40px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 14px rgba(0, 0, 0, 0.12));
  background: #ffffff;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
`;

export default Tile;
