import { isAxiosError } from 'axios';

import { useEffect, useState } from 'react';
import { components } from '../../../gen/backoffice-service';
import { get, post, put } from '../requestUtils';
import { getAuthToken } from '../../auth/auth';

type StorageUnitListRentalPayload = components['schemas']['StorageUnitListRentalPayload'];
export type StorageUnitListRentalPayloadRental = components['schemas']['StorageUnitListRentalPayloadRental'];
type RentalWithKeys = components['schemas']['RentalWithKeysResponse'];
type CreateRentalBody = components['schemas']['CreateRentalBody'];
type PutRentalBody = components['schemas']['PutRentalBody'];
export type UseStorageUnitRentalsResponse = {
  data?: RentalWithKeys[];
  refresh: () => void;
}

const useStorageUnitRentals = (storageUnitId: String): UseStorageUnitRentalsResponse => {
  const [data, setData] = useState<RentalWithKeys[] | undefined>(undefined);
  const [refreshConst, setRefreshConst] = useState<boolean>(false);
  const refresh = () => {
    setRefreshConst(!refreshConst);
  };
  useEffect(() => {
    getStorageUnitRentals(storageUnitId).then(data => {
      setData(data.rentals);
    });
  }, [storageUnitId, refreshConst]);
  return { data, refresh };
};

export const getStorageUnitRentals = async (storageUnitId: String): Promise<StorageUnitListRentalPayload> => {
  const token = await getAuthToken();
  const response = await get(`/units/${storageUnitId}/rentals`, token);
  return response.data;
};

const useStorageUnitCurrentRental = (storageUnitId: String) => {
  const [data, setData] = useState<StorageUnitListRentalPayloadRental | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshConst, setRefreshConst] = useState<boolean>(false);
  const refresh = () => {
    setRefreshConst(!refreshConst);
  };
  useEffect(() => {
    setIsLoading(true);
    getStorageUnitCurrentRental(storageUnitId)
      .then(data => {
        setData(data);
      })
      .finally(() => setIsLoading(false));
  }, [storageUnitId, refreshConst]);
  return { data, isLoading, refresh };
};

export const getStorageUnitCurrentRental = async (
  storageUnitId: String,
): Promise<StorageUnitListRentalPayloadRental | undefined> => {
  const token = await getAuthToken();
  try {
    return (await get(`/units/${storageUnitId}/currentRental`, token)).data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) return undefined;
    throw e;
  }
};

export const createRental = async (rental: CreateRentalBody) => {
  const token = await getAuthToken();
  const response = await post(`/units/${rental.storageUnitId}/rentals`, rental, token);
  if (response.status !== 200) {
    console.error(response);
  }
};

export const endRental = async (storageUnitId: string, rentalId: string) => {
  const token = await getAuthToken();
  if (token) {
    const response = await put(`/units/${storageUnitId}/rentals/${rentalId}/end`, {}, token);
    return response;
  }
  return;
};


export const updateRental = async (rental: { id: string; storageUnitId: string } & PutRentalBody) => {
  const token = await getAuthToken();
  const response = await put(
    `/units/${rental.storageUnitId}/rentals/${rental.id}`,
    { accessLogsActive: rental.accessLogsActive },
    token,
  );
  if (response.status !== 200) {
    console.error(response);
  }
};

export { useStorageUnitCurrentRental, useStorageUnitRentals };
