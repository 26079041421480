import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDevicesByType, searchDevice } from '../../../../gateway/deviceGateway';
import DeviceRow from './DeviceRow';
import Pagination from '../../../../components/Pagination';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import axios from 'axios';
import { Device, DeviceType } from '@ivy/proto/dist/devices/v2/device';

const DeviceTableRow = ({ devices }: { devices: Device[] }) => {
  return (
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Component</b>
          </TableCell>
          <TableCell>
            <b>Assigned to</b>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {devices.map(device => {
          return <DeviceRow key={device.deviceId} device={device} />;
        })}
      </TableBody>
    </Table>
  );
};

const EditDevicesDialog: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const toaster = useToaster().toaster;
  const parkId = params.parkId || '';

  const [devices, setDevices] = React.useState<Device[]>([]);
  const [devicesLoading, setDevicesLoading] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState<DeviceType>(DeviceType.DEVICE_TYPE_LOCK);
  const [page, setPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(50);
  const [tabValue, setTabValue] = React.useState(0);
  const [deviceName, setDeviceName] = React.useState('');

  const filterDevices = React.useCallback(async () => {
    if (parkId && itemsPerPage > 0) {
      setDevicesLoading(true);
      try {
        const devices = await getDevicesByType(parkId, deviceType, page, itemsPerPage);
        setDevices(devices);
      } catch (error) {
        console.error(`${error.response?.status} Error: ${error.response?.data?.message}`);
        toaster('Something went wrong when fetching the devices', '', ToastMessageTypes.ERROR);
      } finally {
        setDevicesLoading(false);
      }
    }
  }, [parkId, itemsPerPage, deviceType, page, setDevices, setDevicesLoading, toaster]);

  const handleSearchChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDeviceName(e.target.value);
    },
    [setDeviceName],
  );

  const handleTabChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue],
  );

  React.useEffect(() => {
    if (tabValue) {
      filterDevices();
    } else {
      const cancelToken = axios.CancelToken.source();

      const searchDevices = async () => {
        try {
          setDevicesLoading(true);
          const response = await searchDevice(deviceName, page, itemsPerPage, cancelToken);
          setDevices(response);
          setDevicesLoading(false);
        } catch (error) {
          if (axios.isCancel(error)) {
            return;
          }
          setDevicesLoading(false);
          console.error(`${error.response?.status} Error: ${error.response?.data?.message}`);
          toaster(
            'Something went wrong when fetching the devices',
            `${error.response?.data?.message}`,
            ToastMessageTypes.ERROR,
          );
        }
      };

      searchDevices();

      return () => {
        cancelToken.cancel();
      };
    }
  }, [deviceName, tabValue, filterDevices, itemsPerPage, page, toaster]);

  if (parkId === undefined) {
    return <Typography>Park ID could not be found in URL!</Typography>;
  }

  const onClose = () => {
    //remove devices/edit
    const parts = location.pathname.split('/');
    const prevPath = parts.slice(0, parts.length - 2).join('/');
    navigate(prevPath, {
      replace: true,
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth="xl">
      <DialogContent>
        <Box>
          <Typography variant="h2">Manage Devices</Typography>
        </Box>
        <Tabs sx={{ m: 3 }} value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab sx={{ mr: 2 }} label="Device Search" value={0} />
          <Tab label="Device List" value={1} />
        </Tabs>

        {tabValue ? (
          <Box display={'flex'} m={-1} mt={2}>
            <Box p={1}>
              <Button
                variant={deviceType === DeviceType.DEVICE_TYPE_LOCK ? 'contained' : 'text'}
                onClick={() => setDeviceType(DeviceType.DEVICE_TYPE_LOCK)}
                sx={{ p: 2 }}
              >
                &nbsp;<span>lock</span>
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant={deviceType === DeviceType.DEVICE_TYPE_SENSOR ? 'contained' : 'text'}
                onClick={() => setDeviceType(DeviceType.DEVICE_TYPE_SENSOR)}
                sx={{ p: 2 }}
              >
                &nbsp;<span>sensor</span>
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant={deviceType === DeviceType.DEVICE_TYPE_LOCK_V3 ? 'contained' : 'text'}
                onClick={() => setDeviceType(DeviceType.DEVICE_TYPE_LOCK_V3)}
                sx={{ p: 2 }}
              >
                &nbsp;<span>V3 lock</span>
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant={deviceType === DeviceType.DEVICE_TYPE_SENSOR_V3 ? 'contained' : 'text'}
                onClick={() => setDeviceType(DeviceType.DEVICE_TYPE_SENSOR_V3)}
                sx={{ p: 2 }}
              >
                &nbsp;<span>V3 sensor</span>
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant={deviceType === DeviceType.DEVICE_TYPE_SMART_METER ? 'contained' : 'text'}
                onClick={() => setDeviceType(DeviceType.DEVICE_TYPE_SMART_METER)}
                sx={{ p: 2 }}
              >
                &nbsp;<span>power</span>
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant={deviceType === DeviceType.DEVICE_TYPE_LEGACY_LOCK ? 'contained' : 'text'}
                onClick={() => setDeviceType(DeviceType.DEVICE_TYPE_LEGACY_LOCK)}
                sx={{ p: 2 }}
              >
                &nbsp;<span>legacy lock</span>
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant={deviceType === DeviceType.DEVICE_TYPE_LEGACY_SENSOR ? 'contained' : 'text'}
                onClick={() => setDeviceType(DeviceType.DEVICE_TYPE_LEGACY_SENSOR)}
                sx={{ p: 2 }}
              >
                &nbsp;<span>legacy sensor</span>
              </Button>
            </Box>
          </Box>
        ) : (
          <Box>
            <Input placeholder="Device Mac Address" onChange={handleSearchChange} className="e2e-device-mac-input" />
          </Box>
        )}

        {devicesLoading ? <CircularProgress sx={{ margin: 3 }} /> : <DeviceTableRow devices={devices} />}
        <Box>
          <Pagination page={page} itemsPerPage={itemsPerPage} setPage={setPage} setItemsPerPage={setItemsPerPage} />
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: 24 }}>
        <Button variant="outlined" size="medium" onClick={onClose}>
          Close
        </Button>
        <div style={{ flex: 1 }} />
      </DialogActions>
    </Dialog>
  );
};

export default EditDevicesDialog;
