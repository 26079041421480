import * as React from 'react';
import { Check, Warning, WarningOctagon, Pencil } from 'phosphor-react';
import { colors } from '../theming/colors';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

type StatusCellProps = {
  activeRatio: number;
  draft?: boolean;
  type: 'park' | 'unit';
};

export const calculateStatus = (activeRatio: number, type: 'park' | 'unit') => {
  const alert = (type === 'unit' && activeRatio === 0) || (type === 'park' && activeRatio <= 0.6);
  const degraded =
    (type === 'unit' && activeRatio > 0 && activeRatio < 1) ||
    (type === 'park' && activeRatio > 0.6 && activeRatio < 1);
  const healthy = activeRatio === 1;
  return { alert, degraded, healthy };
}

const StatusCell: React.FC<StatusCellProps> = ({ activeRatio, draft, type }) => {
  const { alert, degraded, healthy } = calculateStatus(activeRatio, type);

  if (draft) {
    return (
      <Cell title="Draft">
        <Pencil size={24} />
        <Box m={0.5} />
        Draft
      </Cell>
    );
  }

  return (
    <Cell title={`${Math.round(activeRatio * 100)}% ${type === 'park' ? 'unit' : 'device'}s healthy`}>
      {alert && (
        <>
          <WarningOctagon size={24} color={colors.signalAlert} />
          <Box m={0.5} />
          Alert
        </>
      )}
      {degraded && (
        <>
          <Warning size={24} color={colors.signalWarning} />
          <Box m={0.5} />
          Degraded
        </>
      )}
      {healthy && (
        <>
          <Check size={24} color={colors.signalGreen} />
          <Box m={0.5} />
          OK
        </>
      )}
    </Cell>
  );
};

export default StatusCell;
