import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { colors } from '../../../../theming/colors';

export const Label = styled(Typography)`
  position: absolute;
  left: 20px;
  right: 20px;
  top: 94px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.grey05};
`;

export default Label;
