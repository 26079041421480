import React, { useCallback } from 'react';
import { Box, Button, Input } from '@mui/material';
import { CaretLeft, CaretRight } from 'phosphor-react';

const Pagination: React.FC<{
  page: number;
  itemsPerPage: number;
  setPage: (p: number) => void;
  setItemsPerPage: (i: number) => void;
}> = ({ page, itemsPerPage, setPage, setItemsPerPage }) => {
  // TODO: When we know how many pages then this can be improved
  const incrementPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const decrementPage = useCallback(() => {
    if (page === 1) return;
    setPage(page - 1);
  }, [page, setPage]);

  return (
    <Box display={'flex'} alignItems={'center'}>
      {/* TODO: When we add an endpoint to count devices we can do this: <Pagination count={number of pages} /> */}
      <Box>
        <Button sx={{ py: 2 }} onClick={() => decrementPage()} endIcon={<CaretLeft />} />
      </Box>
      <Box px={2}>{page}</Box>
      <Box>
        <Button sx={{ py: 2 }} onClick={() => incrementPage()} startIcon={<CaretRight />} />
      </Box>
      <Box>
        Items per page:{' '}
        <Input
          onChange={e => setItemsPerPage(Number(e.target.value))}
          type="number"
          inputProps={{ min: 0 }}
          value={itemsPerPage}
        />
      </Box>
    </Box>
  );
};

export default Pagination;
