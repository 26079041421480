import * as React from 'react';
import List from '@mui/material/List';
import DashboardItem from './items/DashboardItem';
import EmailsItem from './items/EmailsItem';
import NotificationsItem from './items/NotificationsItem';
import ParksItem from './items/ParksItem';
import ProfileItem from './items/ProfileItem';
import TasksItem from './items/TasksItem';
import TenantsItem from './items/TenantsItem';
import Sidebar from './Sidebar';
import CompanyContext from '../../context/CompanyContext';
import { drawerWidth } from '../../App';
import ParkMonitoringDashboardItem from './items/ParkMonitoringDashboardItem';

const OperatorSidebar: React.FC = () => {
  const company = React.useContext(CompanyContext);
  const features = company?.flags;

  return (
    <Sidebar width={drawerWidth}>
      <List>
        {features?.betaFeatures && <DashboardItem />}
        <ParksItem />
        <TenantsItem />
        {features?.betaFeatures && <TasksItem />}
        {features?.betaFeatures && <NotificationsItem />}
        {features?.hubspotIntegration && <EmailsItem />}
        {features?.parkMonitoringDashboard && <ParkMonitoringDashboardItem />}
      </List>
      <div style={{ flex: 1 }} />
      <List>
        <ProfileItem />
      </List>
    </Sidebar>
  );
};

export default OperatorSidebar;
