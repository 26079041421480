import * as React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useStorageUnit } from '../../gateway/storageUnit/get';
import UnitDetailHeader from './UnitDetailHeader';
import UnitData from './UnitData';
import { usePark } from '../../gateway/park/get';
import { Backdrop, CircularProgress } from '@mui/material';
import { StorageUnitDetailProvider } from '../../context/StorageUnitDetailContext';

const UnitDetail: React.FC = () => {
  const { storageUnitId, parkId } = useParams();
  const park = usePark(parkId!);
  const storageUnit = useStorageUnit(storageUnitId!);

  if (park === undefined || park === null || storageUnit === undefined || storageUnit === null) {
    return (
      <Backdrop
        sx={{ color: '#fff', background: 'transparent', width: 'calc(100vw - 240px)', left: 'auto' }}
        open={true}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <StorageUnitDetailProvider storageUnit={storageUnit}>
      <UnitDetailHeader park={park} storageUnit={storageUnit} />
      <UnitData storageUnit={storageUnit} />
      <Outlet />
    </StorageUnitDetailProvider>
  );
};

export default UnitDetail;
