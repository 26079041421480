import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import * as React from 'react';
import { colors } from '../../../../theming/colors';

const Label = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`;

const Value = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: baseline;
`;

const Unit = styled(Typography)`
  margin-left: 2px;
  font-weight: 700;
`;

interface Props {
  label: string;
  value: string;
  unit: string;
  color?: string;
}

const DataInfoCell: React.FunctionComponent<Props> = ({ label, value, unit, color }) => {
  return (
    <div>
      <Label style={{ color: color ?? colors.grey05 }}>{label}</Label>
      <Value style={{ color }}>
        <Typography variant="h4">{value}</Typography>
        <Unit variant="body2">{unit}</Unit>
      </Value>
    </div>
  );
};

export default DataInfoCell;
