export const colors = {
  white: '#FFFFFF',
  primary: '#2376FF',
  secondary: '#EC4A54',
  signalGreen: '#1CC14A',
  signalAlert: '#FF3E3E',
  signalWarning: '#FDBC46',
  grey02: '#F2F2F2',
  grey05: '#969696',
  grey06: '#5A5A5A',
};
