import * as React from 'react';
import Value from './card/Value';
import Label from './card/Label';
import Tile from './card/Tile';
import { LockSimple, LockSimpleOpen } from 'phosphor-react';
import IconContainer from './card/IconContainer';
import { colors } from '../../../theming/colors';
import { ActiveStateIndicator } from './ActiveStateIndicator';
import { Box } from '@mui/material';

interface LockUnitModuleProps {
  active: boolean;
  status: boolean;
  label: string;
  onClick: () => void;
}

export const LockComponent: React.FunctionComponent<LockUnitModuleProps> = ({ status, label, onClick, active }) => {
  return (
    <Tile className={'e2e-shows-lock'} onClick={() => onClick()}>
      <Box position="absolute" sx={{ right: 10, top: '4px' }}>
        <ActiveStateIndicator active={active} />
      </Box>
      <IconContainer className={'e2e-shows-lock-status-' + (status ? 'unlocked' : 'locked')}>
        {status ? (
          <Value>
            <LockSimpleOpen size={80} color={colors.primary} />
          </Value>
        ) : (
          <Value>
            <LockSimple size={80} color={colors.primary} />
          </Value>
        )}
      </IconContainer>
      <Label>{label}</Label>
    </Tile>
  );
};
