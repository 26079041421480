import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import SiteHeader from '../../components/SiteHeader';
import { colors } from '../../theming/colors';

const Card = styled.div`
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
`;

const Container = styled.div`
  height: 100%;
  background: ${colors.grey02};
`;

const Dashboard: React.FC = () => {
  return (
    <Container>
      <SiteHeader title="Management Desk" />

      <Grid container spacing={2} sx={{ px: 4, pb: 2 }}>
        <Grid item sm={12} md={6}>
          <Card>
            <Typography variant="h4">
              <b>Notifications</b>
            </Typography>
            <Box m={2} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
