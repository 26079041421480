import * as React from 'react';
import { useEffect } from 'react';
import AuthContext from '../../context/AuthContext';
import CompanyParks from './CompanyParks';
import { useNavigate } from 'react-router-dom';

const Parks: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && !auth.user?.isAdmin && !auth.user?.isOperator) {
      navigate('/');
    }
  }, [auth, navigate]);

  if (!auth) {
    return null;
  }

  return <CompanyParks />;
};

export default Parks;
