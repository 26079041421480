import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface SearchBarProps {
  options: any[];
  inputAttribute: string;
  onInputChange: (event: React.SyntheticEvent, value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = props => {
  const [inputValue, setInputValue] = React.useState('');
  return (
    <>
      {props.options ? (
        <Autocomplete
          sx={{ paddingTop: 2 }}
          inputValue={inputValue}
          PopperComponent={() => <></>}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
            props.onInputChange(_, newInputValue);
          }}
          clearOnBlur={false}
          forcePopupIcon={false}
          disableClearable
          options={props.options.map(option => option[props.inputAttribute])}
          renderInput={params => (
            <TextField
              {...params}
              label="Search"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      ) : null}
    </>
  );
};

export default SearchBar;
