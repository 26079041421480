import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Wrap = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Expanded = styled(Box)`
  flex: 1;
`;
