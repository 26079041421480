import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Warning, XCircle } from 'phosphor-react';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { colors } from '../../theming/colors';
import SiteHeader from '../../components/SiteHeader';

const headCells: { id: keyof NotificationEntry; label: string }[] = [
  {
    id: 'level',
    label: 'Type',
  },
  {
    id: 'location',
    label: 'Location',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'timestamp',
    label: 'Date/Time',
  },
];

interface NotificationEntry {
  level: 'ALERT' | 'WARNING';
  location: string;
  description: string;
  timestamp: Date;
  parkId: string;
  unitId: string;
}

const Notifications: React.FC = () => {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof NotificationEntry>('timestamp');

  const onSort = (property: keyof NotificationEntry) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const rows: NotificationEntry[] = [];

  return (
    <>
      <SiteHeader title="Notifications" />

      <Table>
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell key={headCell.id}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => onSort(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody style={{ background: 'white' }}>
          {rows.sort(getComparator(order, orderBy)).map((row, i) => (
            <TableRow
              hover
              onClick={() => navigate(`/park/${row.parkId}/unit/${row.unitId}`)}
              tabIndex={-1}
              key={i}
              style={{ cursor: 'pointer' }}
            >
              <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                {row.level === 'ALERT' && (
                  <>
                    <XCircle size={24} color={colors.signalAlert} />
                    <Box m={0.5} />
                    Alert
                  </>
                )}
                {row.level === 'WARNING' && (
                  <>
                    <Warning size={24} color={colors.signalWarning} />
                    <Box m={0.5} />
                    Warning
                  </>
                )}
              </TableCell>
              <TableCell>{row.location}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{`${row.timestamp.toLocaleDateString()} - ${row.timestamp.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default Notifications;
