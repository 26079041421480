import { Drawer } from '@mui/material';
import * as React from 'react';

interface Props {
  width: number;
}

const Sidebar: React.FunctionComponent<Props> = ({ width, children }) => {
  return (
    <Drawer
      sx={{
        width: width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
          background: 'linear-gradient(0.18deg, #000000 57.96%, #000000 57.96%, #1F2234 99.84%)',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <img src="/images/ivy.svg" alt="logo" style={{ width: 108, marginLeft: 32, marginTop: 40 }} />
      <>{children}</>
    </Drawer>
  );
};

export default Sidebar;
