import { Box, Typography } from '@mui/material';
import { MapPin, User } from 'phosphor-react';
import * as React from 'react';
import { colors } from '../../theming/colors';
import { Row, Wrap } from '../../components/Layout';
import { Park } from '../../model';
import { addressString } from '../../util/util';

interface Props {
  park: Park;
}

const UnitDetailSubHeader: React.FC<Props> = ({ park }) => {
  return (
    <Wrap sx={{ pb: 1, px: 4, background: colors.grey02 }}>
      {/* ADDRESS */}
      <Row>
        <MapPin size={20} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          {addressString(park.address)}
        </Typography>
      </Row>
      <Box m={2} />

      {/* MANAGER */}
      <Row>
        <Typography
          variant="body2"
          sx={{
            mr: 1,
            color: colors.grey05,
          }}
        >
          Facility Manager:
        </Typography>
        <User size={20} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          {park.manager?.name}
        </Typography>
      </Row>
    </Wrap>
  );
};

export default UnitDetailSubHeader;
