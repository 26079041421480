import styled from '@emotion/styled';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { CaretRight, MapPin } from 'phosphor-react';
import * as React from 'react';
import { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import SiteHeader from '../../components/SiteHeader';
import StatusCell from '../../components/StatusCell';
import TableLoading from '../../components/TableLoading';
import AuthContext from '../../context/AuthContext';
import ParksContext from '../../context/ParksContext';
import { ParkListPark } from '../../gateway/park/get';
import { colors } from '../../theming/colors';
import { Order, getUniqueActiveRentalCount, sortByEntry } from '../../util/util';
import SearchBar from '../../components/SearchBar';

export interface ParkEntry {
  id: string;
  name: string;
  region: string;
  rented: string;
  status?: string;
  thingsboardId?: string;
  activeUnitRatio?: number;
  draft?: boolean;
}

const headCells: { id: keyof ParkEntry; label: string }[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'region',
    label: 'Region',
  },
  {
    id: 'rented',
    label: 'Rented',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyParks: React.FC = () => {
  const auth = useContext(AuthContext);
  const { parks, draftParksVisible, setDraftParksVisible, forceRefreshParks } = React.useContext(ParksContext);
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ParkEntry>('name');
  const [sortedParks, setSortedParks] = React.useState<ParkEntry[]>([]);
  const openPark = (parkId: String) => {
    navigate(`/park/${parkId}`);
  };

  const onSortChange = (property: keyof ParkEntry) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const parseParks = (parks: ParkListPark[]): ParkEntry[] =>
    parks.map(park => {
      return {
        id: park.id,
        name: park.name,
        region: `${park.address.countryCode.toUpperCase()}-${park.address.postalCode} ${park.address.city}`,
        rented: `${getUniqueActiveRentalCount(park)} / ${park.numberOfStorageUnits}`,
        thingsboardId: park.thingsboardId,
        activeUnitRatio: park.activeUnitRatio,
        draft: park.draft,
      };
    });

  const parseParkRows = React.useCallback(() => {
    if (parks === undefined) {
      return;
    }
    const parsedParks: ParkEntry[] = parseParks(parks);
    setSortedParks(sortByEntry(parsedParks, orderBy, order));
  }, [parks, orderBy, order]);

  const handleDraftVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftParksVisible(event.target.checked);
    forceRefreshParks();
  };

  React.useEffect(() => {
    parseParkRows();
  }, [parseParkRows]);

  return (
    <>
      <SiteHeader title="Parks">
        <FormControlLabel
          control={<Checkbox checked={draftParksVisible} onChange={handleDraftVisibilityChange} />}
          label={'Show draft parks'}
          componentsProps={{
            typography: {
              fontSize: '0.8em',
            },
          }}
        />
        {auth.user && auth.user.isAdmin ? (
          <Button variant="outlined" size="medium" onClick={() => navigate('/parks/add')}>
            <span className={'e2e-add-park'}>Add Park</span>
          </Button>
        ) : (
          ''
        )}
      </SiteHeader>
      <Box sx={{ px: 4, pb: 2, background: colors.white }}>
        {parks ? (
          <SearchBar
            options={parseParks(parks)}
            inputAttribute={'name'}
            onInputChange={(_, newValue) => {
              if (newValue.trim() === '') {
                setSortedParks(sortByEntry(parseParks(parks), orderBy, order));
                return;
              }
              const standardisedName = (name: string) => name.toLowerCase().trim();
              const results = parks.filter(p => standardisedName(p.name).includes(standardisedName(newValue)));
              setSortedParks(sortByEntry(parseParks(results), orderBy, order));
            }}
          />
        ) : null}
        {parks === undefined && <TableLoading />}
        {parks !== undefined && (
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => onSortChange(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedParks.map(park => {
                return (
                  <TableRow
                    hover
                    onClick={() => openPark(park.id)}
                    key={park.id}
                    sx={{
                      cursor: 'pointer',
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Cell>
                        <img src="/images/ivy-park.svg" alt="logo" style={{ width: 24, height: 24 }} />
                        <Box m={0.5} />
                        <b className={'e2e-shows-park-name'} data-e2e-park-id={park.id}>
                          {park.name}
                        </b>
                      </Cell>
                    </TableCell>

                    <TableCell>
                      <Cell>
                        <MapPin size={24} />
                        <Box m={0.5} />
                        {park.region}
                      </Cell>
                    </TableCell>
                    <TableCell>{park.rented}</TableCell>
                    <TableCell>
                      <StatusCell activeRatio={park.activeUnitRatio ?? 1} draft={park.draft} type="park" />
                    </TableCell>
                    <TableCell align="right">
                      <CaretRight size={24} weight="regular" />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        <Outlet />
      </Box>
    </>
  );
};

export default CompanyParks;
