import { Rotating } from './Rotating';
import { CircleNotch } from 'phosphor-react';
import { colors } from '../theming/colors';
import * as React from 'react';

const LoadingIcon: React.FC<{ size?: number}> = ({ size }) => {
  return (
    <Rotating>
      <CircleNotch size={size ?? 24} color={colors.grey05} />
    </Rotating>
  );
};

export default LoadingIcon;
