import React, { useContext, useEffect, useState, useCallback, ReactNode } from 'react';
import AuthContext from './AuthContext';
import { components } from '../../gen/backoffice-service';
import { getTenants } from '../gateway/tenantGateway';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { Order } from '../util/util';

type TenantListItem = components['schemas']['TenantListItem'];

export interface TenantFilters {
  firstName?: string;
  lastName?: string;
  parkId?: string;
  email?: string;
}

export interface TenantEntry {
  id: string;
  name: string;
  rentals: number;
  parks: string[];
  units: number;
  language: string;
  created: string | number;
  updated: string | number;
  trust: string;
  email: string;
}

type ForceRefreshFunction = () => void;
type TenantsContextApi = {
  tenants: TenantListItem[] | undefined;
  loading: boolean;
  forceRefreshTenants: ForceRefreshFunction;
  page: number;
  itemsPerPage: number;
  handleChangePage: any;
  handleChangeItemsPerPage: any;
  tenantCount: number | undefined;
  order: Order;
  orderBy: keyof TenantEntry;
  handleOrderChange: (orderBy: keyof TenantEntry, order: Order) => void;
  filters: TenantFilters;
  handleFilterChange: (filters: TenantFilters) => void;
};
export const TenantsContext = React.createContext<TenantsContextApi>({
  tenants: undefined,
  loading: false,
  page: 0,
  itemsPerPage: 20,
  tenantCount: 0,
  handleChangePage: () => {},
  handleChangeItemsPerPage: () => {},
  forceRefreshTenants: () => {},
  order: 'asc',
  orderBy: 'name',
  handleOrderChange: () => {},
  filters: {},
  handleFilterChange: () => {},
});

const TenantsProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const auth = useContext(AuthContext);
  const [tenants, setTenants] = useState<TenantListItem[] | undefined>();
  const [tenantCount, setTenantCount] = useState<number | undefined>(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TenantEntry>('name');
  const [filters, setFilters] = React.useState<TenantFilters>({});
  const [loading, setLoading] = useState(false);
  const tenantsExist = !!tenants;
  const { toaster } = useToaster();

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(20);

  const forceRefresh = () => {
    setTenants(undefined);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setTenants(undefined);
  };

  const handleChangeItemsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTenants(undefined);
  };

  const handleOrderChange = (orderBy: keyof TenantEntry, order: Order) => {
    setOrder(order);
    setOrderBy(orderBy);
    setTenants(undefined);
  };

  const handleFilterChange = (filters: TenantFilters) => {
    setFilters({ firstName: filters.firstName, lastName: filters.lastName, parkId: filters.parkId, email: encodeURIComponent(filters.email ?? '') });
    setTenants(undefined);
  };

  const fetchTenants = useCallback(() => {
    if (!auth) return;
    if (!auth.user?.company) return;
    if (tenantsExist) return;
    setLoading(true);
    getTenants({ page, itemsPerPage, sortBy: orderBy, sortDirection: order, filters, includeRentalInfo: true })
      .then(r => {
        setTenants(r.customers);
        setTenantCount(r.count);
      })
      .catch(e => {
        console.error(e);
        toaster('Sorry!', 'There is something wrong, unable to load tenants.', ToastMessageTypes.ERROR);
      })
      .finally(() => setLoading(false));
  }, [auth, tenantsExist, page, itemsPerPage, orderBy, order, filters, toaster]);

  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  return (
    <TenantsContext.Provider
      value={{
        tenants,
        loading,
        forceRefreshTenants: forceRefresh,
        page,
        itemsPerPage,
        handleChangePage,
        handleChangeItemsPerPage,
        tenantCount,
        order,
        orderBy,
        handleOrderChange,
        filters,
        handleFilterChange,
      }}
    >
      {children}
    </TenantsContext.Provider>
  );
};

export default TenantsContext;
export { TenantsProvider };
