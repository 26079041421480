import { FormControl, FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const FormikSwitch: React.FC<SwitchProps> = props => {
  const [field] = useField(props.name!);

  return (
    <FormControl variant="standard" fullWidth>
      <FormControlLabel
        control={<Switch {...props} {...field} checked={field.value} />}
        label={props.children + (props.required ? ' *' : '')}
      />
    </FormControl>
  );
};

export default FormikSwitch;
