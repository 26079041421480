import { components } from '../../../gen/backoffice-service';
import { Park } from '../../model';
import { put } from '../requestUtils';
import { getAuthToken } from '../../auth/auth';

type PutParkBody = components['schemas']['ParkBody'];

export const useUpdatePark = (): ((park: Park) => Promise<void>) => {
  return (park: Park) =>
    updatePark(park.id, {
      name: park.name,
      underConstruction: park.underConstruction,
      address: {
        line1: park.address.line1 || '',
        city: park.address.city,
        postalCode: park.address.postalCode,
        state: park.address.state || '',
        countryCode: park.address.countryCode,
        location:
          park.address.location && park.address.location.lat && park.address.location.long
            ? {
                lat: park.address.location.lat,
                long: park.address.location.long,
              }
            : undefined,
      },
      manager: park.manager
        ? {
            name: park.manager.name,
            contactChannels: (park.manager?.contactChannels ?? []).map(it => {
              return { type: it.type, value: it.value };
            }),
          }
        : undefined,
      storageUnits: park.storageUnits.map(it => {
        return {
          visualId: it.visualId,
          description: it.description,
          id: it.id,
          thingsboardId: it.thingsboardId,
        };
      }),
      iotRegistry: park.iotRegistry || undefined,
      draft: park.draft ?? false,
    });
};

export const updatePark = async (id: string, body: PutParkBody) => {
  const token = await getAuthToken();
  const response = await put('/parks/' + id, body, token);
  if (response.status !== 200) {
    console.error(response);
  }
};
